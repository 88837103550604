import { wsSlice } from '../../app/wsSlice'
import { receivedAppMessage } from './appMessageSlice'

wsSlice.injectHandlers([
    (m, dispatch) => {
        if (!m.error) {
            return
        }

        dispatch(
            receivedAppMessage({
                type: 'error',
                message: m.error,
            }),
        )
    },
])

export const getBaseHttpURL = (region: string): string => {
    switch (import.meta.env.VITE_PLATFORM) {
        case 'dnanexus':
            return window.location.protocol + '//' + window.location.host
        case 'aws':
            switch (region) {
                case 'US':
                    return import.meta.env.VITE_US_SERVER_HTTP_ENDPOINT
                case 'EU':
                    return import.meta.env.VITE_EU_SERVER_HTTP_ENDPOINT
                default:
                    throw 'unsupported region'
            }
        default:
            throw 'unsupported platform'
    }
}

export const getBaseWebsocketURL = (region: string): string => {
    switch (import.meta.env.VITE_PLATFORM) {
        case 'dnanexus':
            return 'wss://' + window.location.host
        case 'aws':
            switch (region) {
                case 'US':
                    return import.meta.env.VITE_US_SERVER_WS_ENDPOINT
                case 'EU':
                    return import.meta.env.VITE_EU_SERVER_WS_ENDPOINT
                default:
                    throw 'unsupported region'
            }
        default:
            throw 'unsupported platform'
    }
}

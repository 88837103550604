import { useCallback } from 'react'

export const useDeliberateDialogClose = (handleCloseDialog: () => void) => {
    return useCallback(
        (event?: object, reason?: string) => {
            if (reason !== 'backdropClick') {
                handleCloseDialog()
            }
        },
        [handleCloseDialog],
    )
}

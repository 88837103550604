import { Checkbox, MenuItem, Select } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import { SelectChangeEvent } from '@mui/material/Select'
import { MenuProps } from '@mui/material/Menu'

const CHECKBOX_SELECT_CONST = {
    ITEM_HEIGHT: 48,
    ITEM_PADDING_TOP: 8,
}

type CheckboxSelectProps = {
    label: string
    values: string[]
    options: string[]
    onChange?: (event: SelectChangeEvent<string[]>) => void | undefined
    error?: boolean | undefined
    menuProps?: MenuProps | undefined
}

export default function CheckboxSelect(props: CheckboxSelectProps) {
    return (
        <Select
            label={props.label}
            value={props.values}
            onChange={props.onChange}
            error={props.error}
            multiple
            required
            fullWidth
            MenuProps={
                props.menuProps ?? {
                    PaperProps: {
                        style: {
                            maxHeight: CHECKBOX_SELECT_CONST.ITEM_HEIGHT * 4.5 + CHECKBOX_SELECT_CONST.ITEM_PADDING_TOP,
                            width: 250,
                        },
                    },
                }
            }
            renderValue={(selected) => selected.join(', ')}
        >
            {props.options?.map((o, idx) => {
                return (
                    <MenuItem key={`field-${idx}`} value={o}>
                        <Checkbox checked={props.values.indexOf(o) > -1} />
                        <ListItemText primary={o} />
                    </MenuItem>
                )
            })}
        </Select>
    )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 6
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

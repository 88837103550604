import { Box, Fade, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LockPersonIcon from '@mui/icons-material/LockPerson'

export default function NoAccount() {
    const { t } = useTranslation()

    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ width: 1, height: '90vh', minHeight: '400px' }}
        >
            <Fade in={true}>
                <Stack spacing={2} alignItems='center'>
                    <LockPersonIcon sx={{ fontSize: '4em' }} />
                    <Typography variant='h4'>{t('noAccountError')}</Typography>
                </Stack>
            </Fade>
        </Box>
    )
}

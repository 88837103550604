import { Avatar } from '@mui/material'
import { useAppSelector } from '../../app/hooks'
import { selectInitials } from '../auth/authSlice'

const UserAvatar = () => {
    const initials = useAppSelector(selectInitials) ?? '  '
    return <Avatar sx={{ width: 32, height: 32 }}>{initials}</Avatar>
}

export default UserAvatar

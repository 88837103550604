import { Delete } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, Divider, Tooltip } from '@mui/material'
import { GridToolbarContainer, GridToolbarFilterButton, ToolbarPropsOverrides } from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'

function RefreshButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Reload the ontology list.'} arrow>
            <span>
                <Button startIcon={<RefreshIcon />} onClick={onClick} size={'small'}>
                    {t('refresh')}
                </Button>
            </span>
        </Tooltip>
    )
}

function CreateButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Upload an ontology.'} arrow>
            <span>
                <Button startIcon={<AddCircleOutlineIcon />} onClick={onClick} size={'small'}>
                    {t('create')}
                </Button>
            </span>
        </Tooltip>
    )
}

function UpdateButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Edit the selected ontology.'} arrow>
            <span>
                <Button startIcon={<EditIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('edit')}
                </Button>
            </span>
        </Tooltip>
    )
}

function DownloadButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Download the selected ontology as a CSV file.'} arrow>
            <span>
                <Button startIcon={<DownloadIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('download')}
                </Button>
            </span>
        </Tooltip>
    )
}

function DeleteButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Permanently delete the selected ontologies.'} arrow>
            <span>
                <Button startIcon={<Delete />} onClick={onClick} size={'small'} disabled={disabled} color={'error'}>
                    {t('delete')}
                </Button>
            </span>
        </Tooltip>
    )
}

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        refreshCallback: () => void
        createCallback: () => void
        updateCallback: () => void
        downloadCallback: () => void
        deleteCallback: () => void
        disableUpdate: boolean
        disableDownload: boolean
        disableDelete: boolean
    }
}

function OntologyGridToolbar({
    refreshCallback,
    createCallback,
    updateCallback,
    downloadCallback,
    deleteCallback,
    disableUpdate,
    disableDownload,
    disableDelete,
}: ToolbarPropsOverrides) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton slotProps={{ tooltip: { arrow: true } }} />
            <RefreshButton onClick={refreshCallback} />
            <Divider orientation='vertical' variant='middle' flexItem />
            <CreateButton onClick={createCallback} />
            <UpdateButton onClick={updateCallback} disabled={disableUpdate} />
            <DownloadButton onClick={downloadCallback} disabled={disableDownload} />
            <Divider orientation='vertical' variant='middle' flexItem />
            <DeleteButton onClick={deleteCallback} disabled={disableDelete} />
        </GridToolbarContainer>
    )
}

export default OntologyGridToolbar

import { Box, CircularProgress, Fade, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const StartupLoading = () => {
    const { t } = useTranslation()

    return (
        <Box display='flex' justifyContent='center' alignItems='center' sx={{ width: 1, minHeight: '100vh' }}>
            <Fade in={true}>
                <Stack spacing={2} alignItems='center'>
                    <CircularProgress size={50} />
                    <Typography variant='h3'>{t('startupLoading')}</Typography>
                </Stack>
            </Fade>
        </Box>
    )
}

export default StartupLoading

import { AppDispatch } from '../../../../app/store'
import { wsSlice } from '../../../../app/wsSlice'
import { RnaSeqAnalysisResultData, ScRnaSeqAnalysisResultData } from '../../../../model/analysisCommands'
import { AddFilter, Component, RemoveObservations, SegmentData } from '../../../../model/analysisComponents'
import { AnalysisWorkflow, DoneCommand, FailedCommand, TerminatedCommand } from '../../../../model/model'
import {
    AnalysisCommandFinished,
    getAnalysisCommandFinishedResultData,
    getAnalysisWorkflow,
} from '../../../../model/webSocketCommands'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'
import { reloadObsDataFrame } from '../transcriptomics/common/observations/reloadObservations'

wsSlice.injectSelectHandlers<AnalysisCommandFinished>(
    (m) => m.analysisCommandFinished,
    [
        (payload, dispatch) =>
            payloadHelper(
                payload,
                dispatch,
                AddFilter,
                (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                'Filter added successfully.',
                'Adding filter failed. Please try again or contact support.',
                'Adding filter terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
            ),

        (payload, dispatch) =>
            payloadHelper(
                payload,
                dispatch,
                SegmentData,
                (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                'Data segmentation completed successfully.',
                'Segmenting data failed. Please try again or contact support.',
                'Segmenting data terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
            ),

        (payload, dispatch) =>
            payloadHelper(
                payload,
                dispatch,
                RemoveObservations,
                (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                'Observation removal completed successfully.',
                'Observation removal failed. Please try again or contact support.',
                'Observation removal terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
            ),
    ],
)

function payloadHelper(
    payload: AnalysisCommandFinished,
    dispatch: AppDispatch,
    component: Component,
    onSuccess: (
        analysisId: number,
        data: ScRnaSeqAnalysisResultData | RnaSeqAnalysisResultData | undefined,
        workflow: AnalysisWorkflow | undefined,
    ) => void,
    successMsg: string,
    failMsg: string,
    terminatedMsg: string,
): void {
    if (payload.component !== component) {
        return
    }

    if (payload.status === FailedCommand) {
        dispatch(
            receivedAppMessage({
                type: 'error',
                message: failMsg,
            }),
        )
        return
    }
    if (payload.status === TerminatedCommand) {
        dispatch(
            receivedAppMessage({
                type: 'warning',
                message: terminatedMsg,
            }),
        )
        return
    }

    if (payload.status !== DoneCommand) {
        return
    }
    dispatch(
        receivedAppMessage({
            type: 'success',
            message: successMsg,
        }),
    )

    const resultData = getAnalysisCommandFinishedResultData(payload)
    const workflow = getAnalysisWorkflow(payload)

    onSuccess(payload.analysisId, resultData, workflow)
}

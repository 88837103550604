import { Analysis, DoneAnalysis } from '../../../model/model'
import Box from '@mui/material/Box'
import Logo from '../../../assets/images/logo-panomics-dark.png'
import { Link, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import RuntimeControl from '../../runtime/RuntimeControl'
import AccountMenu from '../../dashboard/AccountMenu'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Notifications from '../../notification/Notifications'
import Chip from '@mui/material/Chip'

export default function AnalysisNavBar({ analysis }: { analysis: Analysis }) {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: '#264653',
                p: 1,
                pr: 2,
                pl: 2,
                display: 'flex',
                borderBottom: '1px solid #DDD',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiLink-root, & .MuiTypography-root, & .MuiSvgIcon-root': {
                        color: '#FFFFFF',
                        textDecoration: 'none',
                    },
                    '& .MuiLink-root:hover': {
                        textDecoration: 'underline',
                    },
                    flexGrow: 1,
                    '.MuiLink-root': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Link
                    onClick={() => {
                        navigate('/project/list')
                    }}
                    sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
                >
                    <Box component='img' sx={{ width: 90, mr: 4 }} alt='' src={Logo} />
                </Link>
                <Link
                    onClick={() => {
                        navigate('/project/list')
                    }}
                >
                    Projects
                </Link>
                <ArrowRightIcon />
                <Link
                    onClick={() => {
                        navigate(`/app/workbench/${analysis.project.id}/analyses`)
                    }}
                >
                    {analysis?.project?.title?.truncate(100)}
                </Link>
                <ArrowRightIcon />
                <Typography>
                    <b>{analysis?.title?.truncate(30)}</b>
                </Typography>
                {analysis?.status == DoneAnalysis && (
                    <Chip
                        label={'immutable'}
                        size='small'
                        sx={{
                            ml: 1,
                            fontWeight: 'bold',
                            backgroundColor: '#e76f51',
                            color: '#FFF',
                        }}
                    />
                )}
            </Box>
            <RuntimeControl />
            <Notifications sx={{ ml: 1 }} />
            <AccountMenu sx={{ ml: 1 }} />
        </Box>
    )
}

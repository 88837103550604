import { apiSlice } from '../../app/apiSlice'
import {
    DashboardFilter,
    GetGeneExpressionPlotDataRequest,
    GetPlotDataResponse,
    LogicalOperator
} from '../../model/model'


type OmicsBrowserSignatureQueryRequest = {
    filters?: DashboardFilter[] | null
    logicalOperator?: LogicalOperator
    genes: string[]
    logFoldChangeFilter?: DashboardFilter
    maxPvalue: number
    analysisId?: number
}

export type OmicsBrowserQueryResponse = {
    url: string
    type: string
}

export const queryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSignatureData: builder.query<OmicsBrowserQueryResponse, OmicsBrowserSignatureQueryRequest>({
            query: (req) => ({
                url: '/private/query-signature',
                method: 'POST',
                body: req,
            }),
        }),
        getGeneExpressionPlotData: builder.query<GetPlotDataResponse, GetGeneExpressionPlotDataRequest>({
            query: (req) => ({
                url: '/private/query/gex',
                method: 'POST',
                body: req,
            }),
        }),
        getSampleZScoreData: builder.query<GetPlotDataResponse, GetGeneExpressionPlotDataRequest>({
            query: (req) => ({
                url: '/private/query/zscore',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useLazyGetSignatureDataQuery,
    useLazyGetGeneExpressionPlotDataQuery,
    useLazyGetSampleZScoreDataQuery,
} = queryApiSlice

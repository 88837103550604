import { Box } from '@mui/material'
import { DataGridPremium, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import useStoredFilterModel from '../../../hooks/useStoredFilterModel'
import CreateOrUpdateRoleDialog from './CreateOrUpdateRoleDialog'
import DeleteRolesDialog from './DeleteRolesDialog'
import RoleGridToolbar from './RoleGridToolbar'
import { receivedRolesList, selectRoleRows, selectTotalRoles } from './adminRoleSlice'
import { useLazyListRolesWithUserCountQuery } from './roleApiSlice'

export default function RoleList() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const roleRows = useAppSelector(selectRoleRows)
    const totalCount = useAppSelector(selectTotalRoles)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [openCreateOrUpdateRoleDialog, setOpenCreateOrUpdateRoleDialog] = useState(false)
    const [openDeleteRolesDialog, setOpenDeleteRolesDialog] = useState(false)

    const [filterModel, setFilterModel] = useStoredFilterModel('role_list', {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const [getRoleListApi, { isLoading: isFetching }] = useLazyListRolesWithUserCountQuery()
    const { width = 0 } = useWindowSize()

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            groupable: false,
            width: 200,
        },
        {
            field: 'permissions',
            headerName: t('permissions'),
            groupable: false,
            filterable: true,
            sortable: false,
            width: Math.max(width - 950, 300),
        },
        {
            field: 'inUse',
            headerName: t('inUse'),
            groupable: false,
            filterable: false,
            width: 150,
        },
        {
            field: 'standard',
            headerName: t('standard'),
            groupable: false,
            filterable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams): string | JSX.Element => {
                if (params.row['standard']) {
                    return 'Yes'
                }
                return 'No'
            },
        },
        {
            field: 'createdAt',
            headerName: t('createdAt'),
            groupable: false,
            filterable: false,
            width: 200,
        },
        {
            field: 'updatedAt',
            headerName: t('updatedAt'),
            groupable: false,
            filterable: false,
            width: 200,
        },
    ]

    const fetchRoles = async () => {
        const result = await getRoleListApi().unwrap()
        dispatch(
            receivedRolesList({
                roleList: result,
            }),
        )
    }

    useEffect(() => {
        void fetchRoles()
    }, [])

    const deletable = useMemo(() => {
        return (
            selectionModel.length > 0 &&
            roleRows.filter((o) => selectionModel.includes(o.id) && (o.inUse == 'Yes' || o.standard)).length == 0
        )
    }, [selectionModel])

    const editable = useMemo(() => {
        return selectionModel.length == 1 && !roleRows.find((r) => r.id == selectionModel[0])?.standard
    }, [selectionModel])

    return (
        <Box sx={{ width: '100%' }}>
            <DataGridPremium
                sx={{
                    border: 0,
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                rows={roleRows}
                rowCount={totalCount}
                columns={columns}
                disableAggregation
                disableColumnSelector
                loading={isFetching}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                checkboxSelection
                keepNonExistentRowsSelected
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                disableMultipleColumnsSorting
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
                slots={{
                    toolbar: RoleGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        refreshCallback: () => {
                            void fetchRoles()
                        },
                        createCallback: () => {
                            setSelectionModel([])
                            setOpenCreateOrUpdateRoleDialog(true)
                        },
                        updateCallback: () => {
                            if (selectionModel.length == 1) {
                                setOpenCreateOrUpdateRoleDialog(true)
                            }
                        },
                        deleteCallback: () => {
                            setOpenDeleteRolesDialog(true)
                        },
                        disableUpdate: !editable,
                        disableDelete: !deletable,
                    },
                }}
            />
            <CreateOrUpdateRoleDialog
                openDialog={openCreateOrUpdateRoleDialog}
                handleCloseDialog={() => {
                    setOpenCreateOrUpdateRoleDialog(false)
                }}
                roleId={selectionModel.length == 1 ? (selectionModel[0] as number) : undefined}
            />
            <DeleteRolesDialog
                openDialog={openDeleteRolesDialog}
                handleCloseDialog={() => {
                    setOpenDeleteRolesDialog(false)
                }}
                selectionModel={selectionModel}
                onDone={() => {
                    setSelectionModel([])
                }}
            />
        </Box>
    )
}

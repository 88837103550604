import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { SxProps } from '@mui/system'

export default function LoadingComponentData({ sx }: { sx?: SxProps }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 'calc(100vh - 60px)',
                ...sx,
            }}
        >
            <Stack
                sx={{
                    '& .MuiBox-root': {
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                    },
                    '& .MuiBox-root .MuiTypography-root': {
                        ml: 1,
                    },
                }}
            >
                <Box>
                    <CircularProgress size={20} />
                    <Typography>Loading data</Typography>
                </Box>
            </Stack>
        </Box>
    )
}

import { Box } from '@mui/material'
import { DataGridPremium, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import useStoredFilterModel from '../../../hooks/useStoredFilterModel'
import { useLazyGetOntologyDownloadURLQuery, useLazyGetOntologyListQuery } from '../../common-api/ontologyApiSlice'
import CreateOntologyDialog from './CreateOntologyDialog'
import DeleteOntologiesDialog from './DeleteOntologiesDialog'
import EditOntologyDialog from './EditOntologyDialog'
import OntologyGridToolbar from './OntologyGridToolbar'
import { receivedOntologiesList, selectOntologyRows, selectTotalOntologies } from './adminOntologySlice'

export default function OntologyList() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const ontologyRows = useAppSelector(selectOntologyRows)
    const totalCount = useAppSelector(selectTotalOntologies)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [openCreateOntologyDialog, setOpenCreateOntologyDialog] = useState(false)
    const [openEditOntologyDialog, setOpenEditOntologyDialog] = useState(false)
    const [openDeleteOntologiesDialog, setOpenDeleteOntologiesDialog] = useState(false)
    const [selectedOntologyId, setSelectedOntologyId] = useState<number | null>(null)

    const [filterModel, setFilterModel] = useStoredFilterModel('ontology_list', {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const [getOntologyListApi, { isLoading: isFetching }] = useLazyGetOntologyListQuery()
    const [getOntologyDownloadURLApi] = useLazyGetOntologyDownloadURLQuery()
    const { width = 0 } = useWindowSize()

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            groupable: false,
            width: 200,
        },
        {
            field: 'description',
            headerName: t('description'),
            groupable: false,
            filterable: true,
            sortable: false,
            width: Math.max(width - 1050, 300),
        },
        {
            field: 'fields',
            headerName: t('usedBy'),
            groupable: false,
            filterable: true,
            width: 200,
        },
        {
            field: 'standard',
            headerName: t('standard'),
            groupable: false,
            filterable: true,
            width: 100,
        },
        {
            field: 'createdBy',
            headerName: t('createdBy'),
            groupable: false,
            filterable: false,
            width: 200,
        },
        {
            field: 'createdAt',
            headerName: t('createdAt'),
            groupable: false,
            filterable: false,
            width: 150,
        },
        {
            field: 'updatedAt',
            headerName: t('updatedAt'),
            groupable: false,
            filterable: false,
            width: 150,
        },
    ]

    const fetchOntologies = async () => {
        const result = await getOntologyListApi().unwrap()
        dispatch(
            receivedOntologiesList({
                ontologyList: result,
            }),
        )
    }

    useEffect(() => {
        void fetchOntologies()
    }, [])

    const deletable = useMemo(() => {
        return (
            selectionModel.length > 0 &&
            ontologyRows.filter((o) => selectionModel.includes(o.id) && (o.standard == 'Yes' || o.fields !== ''))
                .length == 0
        )
    }, [selectionModel])

    return (
        <Box sx={{ width: '100%' }}>
            <DataGridPremium
                sx={{
                    border: 0,
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                rows={ontologyRows}
                rowCount={totalCount}
                columns={columns}
                disableAggregation
                disableColumnSelector
                loading={isFetching}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                checkboxSelection
                keepNonExistentRowsSelected
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                disableMultipleColumnsSorting
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
                slots={{
                    toolbar: OntologyGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        refreshCallback: () => {
                            void fetchOntologies()
                        },
                        createCallback: () => {
                            setSelectionModel([])
                            setOpenCreateOntologyDialog(true)
                        },
                        updateCallback: () => {
                            if (selectionModel.length == 1) {
                                setSelectedOntologyId(selectionModel[0] as number)
                                setOpenEditOntologyDialog(true)
                            }
                        },
                        downloadCallback: () => {
                            if (selectionModel.length == 1) {
                                getOntologyDownloadURLApi(selectionModel[0] as number)
                                    .unwrap()
                                    .then((url) => {
                                        const anchor = document.createElement('a')
                                        anchor.href = url
                                        document.body.appendChild(anchor)
                                        anchor.click()
                                        document.body.removeChild(anchor)
                                    })
                            }
                        },
                        deleteCallback: () => {
                            if (deletable) {
                                setOpenDeleteOntologiesDialog(true)
                            }
                        },
                        disableUpdate: selectionModel.length != 1,
                        disableDownload: selectionModel.length != 1,
                        disableDelete: !deletable,
                    },
                }}
            />
            <CreateOntologyDialog
                openDialog={openCreateOntologyDialog}
                handleCloseDialog={() => {
                    setOpenCreateOntologyDialog(false)
                }}
            />
            {selectedOntologyId && (
                <EditOntologyDialog
                    openDialog={openEditOntologyDialog}
                    handleCloseDialog={() => {
                        setOpenEditOntologyDialog(false)
                    }}
                    ontologyId={selectedOntologyId}
                />
            )}
            <DeleteOntologiesDialog
                openDialog={openDeleteOntologiesDialog}
                handleCloseDialog={() => {
                    setOpenDeleteOntologiesDialog(false)
                }}
                selectionModel={selectionModel}
                onDone={() => {
                    setSelectionModel([])
                }}
            />
        </Box>
    )
}

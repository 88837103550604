import { apiSlice } from '../../app/apiSlice'
import { Collection, CollectionWrapper, MetadataField } from '../../model/model'

type SaveCollectionRequest = {
    id?: number
    name: string
    groupings: MetadataField[]
}

export const collectionApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCollections: builder.query<Collection[], void>({
            query: () => `/private/collection`,
        }),
        getDetailedCollections: builder.query<CollectionWrapper[], void>({
            query: () => `/private/collection/detailed`,
        }),
        saveCollection: builder.mutation<Collection, SaveCollectionRequest>({
            query: (req) => ({
                url: `/private/collection`,
                method: 'POST',
                body: req,
            }),
        }),
        deleteCollections: builder.mutation<number[], number[]>({
            query: (ids) => ({
                url: '/private/collection',
                method: 'DELETE',
                body: {
                    idList: ids,
                },
            }),
        }),
    }),
})

export const {
    useGetCollectionsQuery,
    useLazyGetDetailedCollectionsQuery,
    useDeleteCollectionsMutation,
    useSaveCollectionMutation,
} = collectionApiSlice

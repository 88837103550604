import { useState } from 'react'

export const getStoredRegion = (): string => {
    const storageKey = 'panomics-data-region'
    const storedRegion = localStorage.getItem(storageKey)
    return storedRegion ?? 'US'
}

export default function useStoredRegion(): [string, (r: string) => void] {
    const storageKey = 'panomics-data-region'

    const [region, setRegion] = useState<string>(getStoredRegion)

    const updateRegion = (newRegion: string) => {
        localStorage.setItem(storageKey, newRegion)
        setRegion(newRegion)
    }

    return [region, updateRegion]
}

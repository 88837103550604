import { apiSlice } from '../../app/apiSlice'
import { Dashboard } from '../../model/model'

type CreateDashboardRequest = {
    name: string
}
type CreateDashboardResponse = {
    dashboard: Dashboard
}
type DeleteDashboardRequest = {
    id: number
}
type UpdateDashboardNameRequest = {
    id: number
    name: string
}
type ListDashboardsResponse = {
    dashboardList: Dashboard[]
}
type SetDashboardGeneRequest = {
    dashboardId: number
    genes?: string[]
    geneListId?: number
}
type SetDashboardTabState = {
    dashboardId: number
    state: object
}

export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createDashboard: builder.mutation<CreateDashboardResponse, CreateDashboardRequest>({
            query: (req) => ({
                url: '/private/dashboard/create',
                method: 'POST',
                body: req,
            }),
        }),
        deleteDashboard: builder.mutation<void, DeleteDashboardRequest>({
            query: (req) => ({
                url: '/private/dashboard/delete',
                method: 'POST',
                body: req,
            }),
        }),
        updateDashboardName: builder.mutation<void, UpdateDashboardNameRequest>({
            query: (req) => ({
                url: '/private/dashboard/update-name',
                method: 'POST',
                body: req,
            }),
        }),
        listDashboards: builder.query<ListDashboardsResponse, void>({
            query: () => '/private/dashboard/list',
        }),
        setSignatureGenes: builder.mutation<void, SetDashboardGeneRequest>({
            query: (req) => ({
                url: '/private/dashboard/signature-genes',
                method: 'POST',
                body: req,
            }),
        }),
        setSignatureTabState: builder.mutation<void, SetDashboardTabState>({
            query: (req) => ({
                url: '/private/dashboard/signature-state',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useCreateDashboardMutation,
    useDeleteDashboardMutation,
    useUpdateDashboardNameMutation,
    useListDashboardsQuery,
    useSetSignatureGenesMutation,
    useSetSignatureTabStateMutation,
    endpoints: {
        createDashboard,
        deleteDashboard,
        updateDashboardName,
        listDashboards,
    },
} = dashboardApiSlice

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ProjectReadmeVersion } from '../../../model/model'

type ReadmeState = {
    readmeVersion: ProjectReadmeVersion | null
}

const initialState = {
    readmeVersion: null,
} as ReadmeState

const readmeSlice = createSlice({
    name: 'readmeHolder',
    initialState: initialState,
    reducers: {
        receivedReadmeVersion: (
            state,
            {
                payload: { readmeVersion },
            }: PayloadAction<{
                readmeVersion: ProjectReadmeVersion | null
            }>,
        ) => {
            state.readmeVersion = readmeVersion
        },
    },
})

export const { receivedReadmeVersion } = readmeSlice.actions

export const selectReadme = (state: RootState) => state.readmeHolder.readmeVersion

export default readmeSlice.reducer

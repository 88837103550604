import JoinLeftIcon from '@mui/icons-material/JoinLeft'
import { Button, Tooltip } from '@mui/material'
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    ToolbarPropsOverrides,
} from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'
import AppsIcon from '@mui/icons-material/Apps'
import ViewModuleIcon from '@mui/icons-material/ViewModule'

export function OverlapButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Show gene overlap between the selected signatures. Max 100 signatures.'} arrow>
            <span>
                <Button startIcon={<JoinLeftIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('viewOverlap')}
                </Button>
            </span>
        </Tooltip>
    )
}

function SimilarityMatrixButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Show the similarity matrix for the selected signatures. Max 100 signatures.'} arrow>
            <span>
                <Button startIcon={<AppsIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('similarityMatrix')}
                </Button>
            </span>
        </Tooltip>
    )
}

function GeneSignatureModuleButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Visualize gene modules across the selected signatures. Max 100 signatures.'} arrow>
            <span>
                <Button startIcon={<ViewModuleIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('geneSignatureModule')}
                </Button>
            </span>
        </Tooltip>
    )
}

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        overlapCallback: () => void
        disableOverlap: boolean
        similarityCallback: () => void
        disableSimilarity: boolean
        geneModulesCallback: () => void
        disableGeneModules: boolean
    }
}

function GeneSignatureSimilarityGridToolbar({
    overlapCallback,
    disableOverlap,
    similarityCallback,
    disableSimilarity,
    geneModulesCallback,
    disableGeneModules,
}: ToolbarPropsOverrides) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton slotProps={{ tooltip: { arrow: true } }} />
            <GridToolbarExport />
            <OverlapButton onClick={overlapCallback} disabled={disableOverlap} />
            <SimilarityMatrixButton onClick={similarityCallback} disabled={disableSimilarity} />
            <GeneSignatureModuleButton onClick={geneModulesCallback} disabled={disableGeneModules} />
        </GridToolbarContainer>
    )
}

export default GeneSignatureSimilarityGridToolbar

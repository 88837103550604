import Typography from '@mui/material/Typography'
import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import React from 'react'

interface MarkerSizeControlParams {
    markerSize: number
    setMarkerSize: React.Dispatch<React.SetStateAction<number>>
}

export default function MarkerSizeControl({ markerSize, setMarkerSize }: MarkerSizeControlParams) {
    const defaultMarkerStepSize = 0.5

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                '& .MuiIconButton-root': {
                    border: '1px solid #AAA',
                    width: '20px',
                    height: '20px',
                    borderRadius: 1,
                    ml: 1,
                },
            }}
        >
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                Marker size
            </Typography>
            <IconButton
                onClick={() => {
                    setMarkerSize(markerSize + defaultMarkerStepSize)
                }}
            >
                <AddIcon />
            </IconButton>
            <IconButton
                onClick={() => {
                    if (markerSize > 1) {
                        setMarkerSize(markerSize - defaultMarkerStepSize)
                    }
                }}
            >
                <RemoveIcon />
            </IconButton>
        </Box>
    )
}

import React, { useState, useEffect } from 'react'
import { Alert, Box, CircularProgress, InputAdornment, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'
import { useDeliberateDialogClose } from '../../../../../../hooks/useDeliberateDialogClose'
import { DialogProps } from '../../../../../../model/model'
import { useLazyGetGeneModuleQuery } from './geneSignatureApiSlice'
import { SlideUpTransition } from '../../../../../../utils/transitions'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import LoadingComponentData from '../../../common/LoadingComponentData'
import { DataFrame } from 'danfojs'
import GeneListSelector from '../../../common/GeneListSelector'
import { useAppSelector } from '../../../../../../app/hooks'
import { selectSelectedDashboard } from '../../../../../omicsbrowser/dashboardSlice'
import { HeatmapPlot } from '../../../common/HeatmapPlot'

export interface GeneSignatureModuleMatrixDialogParams extends DialogProps {
    geneSignatureIds: number[]
}

export default function GeneSignatureModuleDialog({
    geneSignatureIds,
    openDialog,
    handleCloseDialog,
}: GeneSignatureModuleMatrixDialogParams) {
    const { t } = useTranslation()
    const dashboard = useAppSelector(selectSelectedDashboard)
    const [selectedGenes, setSelectedGenes] = useState<string[]>([])

    const [getGeneModuleApi, { isLoading }] = useLazyGetGeneModuleQuery()
    const [geneModulesDataFrame, setGeneModulesDataFrame] = useState<DataFrame | null>(null)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        setGeneModulesDataFrame(null)
        setSelectedGenes([])
    })

    useEffect(() => {
        setGeneModulesDataFrame(null)
        if (!geneSignatureIds || geneSignatureIds.length < 2 || !openDialog || selectedGenes.length === 0) {
            return
        }

        getGeneModuleApi({ geneSignatureIds, genes: selectedGenes })
            .unwrap()
            .then(({ xNames, yNames, data }) => {
                setGeneModulesDataFrame(
                    new DataFrame(data, {
                        index: xNames.map((n) => `${n}\0`), // Force plotly to not cast to numbers as we can have IDs
                        columns: yNames,
                    }).T,
                )
            })
    }, [geneSignatureIds, openDialog, selectedGenes])

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            Gene Modules across Gene Signatures
                        </Typography>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            {geneSignatureIds.length} selected
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', p: 2, width: '100%', flexDirection: 'column' }}>
                <Box sx={{ mt: 2, mb: 2, display: 'flex', gap: 2, width: '100%' }}>
                    <GeneListSelector
                        sx={{ width: 'calc(100vw - 710px)' }}
                        genes={dashboard?.signatureGenes}
                        geneList={dashboard?.signatureGeneList}
                        labelManual={'Search for genes using the approved symbols'}
                        labelGenelist={'Search for a gene list'}
                        handleGenesAndListChange={(genes) => {
                            if (genes.length > 0) {
                                console.debug(genes)
                                setSelectedGenes(genes)
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <CircularProgress size={20} sx={{ display: isLoading ? 'block' : 'none' }} />
                                </InputAdornment>
                            ),
                            style: {
                                background: '#FFF',
                            },
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {selectedGenes.length > 0 ? (
                        geneModulesDataFrame ? (
                            <HeatmapPlot
                                plotDataFrame={geneModulesDataFrame}
                                width={window.innerWidth - 100}
                                height={window.innerHeight - 180}
                                xlabel={'Gene Signature'}
                                ylabel={'Gene'}
                                title={'Gene modules across gene signatures'}
                                colorbarTitle='<b>signed (-log<sub>10</sub>(FDR))</b>'
                                mapPlotColorValues={(row) => row.map((col) => col[1])}
                                mapPlotLabelValues={(cell) => (cell[0] as number).toFixed(2)}
                            />
                        ) : (
                            <LoadingComponentData
                                sx={{
                                    height: 'calc(100vh - 165px)',
                                }}
                            />
                        )
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100vh - 165px)',
                            }}
                        >
                            <Alert severity='info'>{t('missingGenes')}</Alert>
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}

import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import collectionListReducer from '../features/admin/collection/adminCollectionSlice'
import geneSetCollectionListReducer from '../features/admin/genesetcollection/adminGeneSetCollectionSlice'
import ontologyListReducer from '../features/admin/ontology/adminOntologySlice'
import roleListReducer from '../features/admin/role/adminRoleSlice'
import userListReducer from '../features/admin/user/adminUserSlice'
import authReducer from '../features/auth/authSlice'
import cellAtlasListReducer from '../features/cellatlas/cellAtlasSlice'
import appMessageReducer, { errorListenerMiddleware } from '../features/dashboard/appMessageSlice'
import dataSliceListReducer from '../features/dataslice/dataSliceSlice'
import geneListsReducer from '../features/genelist/geneListSlice'
import notificationsReducer from '../features/notification/notificationSlice'
import dashboardListReducer from '../features/omicsbrowser/dashboardSlice'
import projectListReducer from '../features/project/projectListSlice'
import runtimeReducer from '../features/runtime/runtimeSlice'
import sampleDetailsReducer from '../features/sample/sampleDetailsSlice'
import sampleListReducer from '../features/sample/sampleListSlice'
import apiKeyListReducer from '../features/user/apiKeySlice'
import externalApiKeyListReducer from '../features/user/externalApiKeySlice'
import analysisDataHubReducer from '../features/workbench/analysis/analysisDataHubSlice'
import analysisDetailsReducer from '../features/workbench/analysis/analysisDetailsSlice'
import analysisReducer from '../features/workbench/analysis/analysisSlice'
import analysisResultsReducer from '../features/workbench/analysis/results/analysisResultsSlice'
import assetUploadReducer from '../features/workbench/asset/assetUploadSlice'
import readmeReducer from '../features/workbench/readme/readmeSlice'
import workbenchReducer from '../features/workbench/workbenchSlice'
import { apiSlice } from './apiSlice'
import { resetStore } from './customActions'

const combinedReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    sampleListHolder: sampleListReducer,
    sampleDetailsHolder: sampleDetailsReducer,
    projectListHolder: projectListReducer,
    analysisDetailsHolder: analysisDetailsReducer,
    workbenchStateHolder: workbenchReducer,
    assetUpload: assetUploadReducer,
    appMessageHolder: appMessageReducer,
    runtime: runtimeReducer,
    geneListsHolder: geneListsReducer,
    analysisHolder: analysisReducer,
    userListHolder: userListReducer,
    apiKeyListHolder: apiKeyListReducer,
    externalApiKeyListHolder: externalApiKeyListReducer,
    dashboardListHolder: dashboardListReducer,
    ontologyListHolder: ontologyListReducer,
    collectionListHolder: collectionListReducer,
    roleListHolder: roleListReducer,
    geneSetCollectionListHolder: geneSetCollectionListReducer,
    dataSliceListHolder: dataSliceListReducer,
    readmeHolder: readmeReducer,
    notificationsHolder: notificationsReducer,
    analysisDataHubHolder: analysisDataHubReducer,
    analysisResultsHolder: analysisResultsReducer,
    cellAtlasListHolder: cellAtlasListReducer,
})


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: Action) => {
    if (action.type === resetStore.type) {
        state = undefined
    }
    return combinedReducer(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware).prepend(errorListenerMiddleware.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

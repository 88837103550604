import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import {
    AND,
    Dashboard,
    DashboardFilter,
    GeneListBase,
    LogicalOperator
} from '../../model/model'

const dashboards = createEntityAdapter<Dashboard, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

const filters = createEntityAdapter<DashboardFilter, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() < a.createdAt.toNumber() ? 1 : -1),
})

type DashboardListState = {
    dashboards: EntityState<Dashboard, number>
    selectedDashboard: Dashboard | null
    filters: EntityState<DashboardFilter, number>
    logicalOperator: LogicalOperator
    state: 'idle' | 'loading' | 'finished'
}

const initialState = {
    dashboards: dashboards.getInitialState(),
    filters: filters.getInitialState(),
    logicalOperator: AND,
} as DashboardListState

const dashboardSlice = createSlice({
    name: 'dashboardListHolder',
    initialState: initialState,
    reducers: {
        receivedDashboardsList: (
            state,
            { payload: { dashboardList } }: PayloadAction<{ dashboardList: Dashboard[] }>,
        ) => {
            dashboards.setAll(state.dashboards, dashboardList)
        },
        receivedNewDashboard: (state, { payload: { dashboard } }: PayloadAction<{ dashboard: Dashboard }>) => {
            dashboards.addOne(state.dashboards, dashboard)
            state.selectedDashboard = dashboard
            filters.removeAll(state.filters)
            state.state = 'finished'
        },
        receivedDeletedDashboardId: (state, { payload: { id } }: PayloadAction<{ id: number }>) => {
            dashboards.removeOne(state.dashboards, id)
            if (state.selectedDashboard?.id == id) {
                state.selectedDashboard = null
            }
            filters.removeAll(state.filters)
        },
        receivedUpdatedDashboardName: (
            state,
            { payload: { id, name } }: PayloadAction<{ id: number; name: string }>,
        ) => {
            dashboards.updateOne(state.dashboards, {
                id: id,
                changes: {
                    name: name,
                },
            })
            if (state.selectedDashboard?.id == id) {
                state.selectedDashboard.name = name
            }
        },
        receivedSelectDashboard: (state, { payload: { id } }: PayloadAction<{ id: number }>) => {
            const selectedDashboard = dashboards.getSelectors().selectById(state.dashboards, id)
            if (selectedDashboard) {
                state.selectedDashboard = selectedDashboard
                state.logicalOperator = selectedDashboard.logicalOperator
                if (selectedDashboard.filters) {
                    filters.setAll(state.filters, selectedDashboard.filters)
                } else {
                    filters.removeAll(state.filters)
                }
            }
        },
        receivedDashboardFilter: (
            state,
            { payload: { dashboardId, filter } }: PayloadAction<{ dashboardId: number; filter: DashboardFilter }>,
        ) => {
            if (state.selectedDashboard && state.selectedDashboard.id == dashboardId) {
                filters.upsertOne(state.filters, filter)
                dashboards.updateOne(state.dashboards, {
                    id: state.selectedDashboard.id,
                    changes: {
                        filters: filters.getSelectors().selectAll(state.filters),
                    },
                })
            }
        },
        receivedClearAllDashboardFilters: (state, { payload: { id } }: PayloadAction<{ id: number }>) => {
            if (state.selectedDashboard && state.selectedDashboard.id == id) {
                filters.removeAll(state.filters)
                dashboards.updateOne(state.dashboards, {
                    id: state.selectedDashboard.id,
                    changes: {
                        filters: [],
                    },
                })
            }
        },
        receivedDeleteDashboardFilter: (
            state,
            { payload: { dashboardId, filterId } }: PayloadAction<{ dashboardId: number; filterId: number }>,
        ) => {
            if (state.selectedDashboard && state.selectedDashboard.id == dashboardId) {
                filters.removeOne(state.filters, filterId)
                dashboards.updateOne(state.dashboards, {
                    id: state.selectedDashboard.id,
                    changes: {
                        filters: filters.getSelectors().selectAll(state.filters),
                    },
                })
            }
        },
        receivedNewDashboardSignatureGene: (
            state,
            {
                payload: { dashboardId, genes, geneList },
            }: PayloadAction<{ dashboardId: number; genes: string[]; geneList: GeneListBase | undefined }>,
        ) => {
            if (state.selectedDashboard && state.selectedDashboard.id == dashboardId) {
                dashboards.updateOne(state.dashboards, {
                    id: state.selectedDashboard.id,
                    changes: {
                        signatureGenes: genes,
                        signatureGeneList: geneList,
                    },
                })
                state.selectedDashboard.signatureGenes = genes
                state.selectedDashboard.signatureGeneList = geneList
            }
        },

        receivedSignatureTabState: (
            state,
            { payload: { dashboardId, tabState } }: PayloadAction<{ dashboardId: number; tabState: object }>,
        ) => {
            if (state.selectedDashboard && state.selectedDashboard.id == dashboardId) {
                dashboards.updateOne(state.dashboards, {
                    id: state.selectedDashboard.id,
                    changes: {
                        signatureTabState: tabState,
                    },
                })
                state.selectedDashboard.signatureTabState = tabState
            }
        },
        receivedLogicalOperator: (
            state,
            {
                payload: { dashboardId, logicalOperator },
            }: PayloadAction<{
                dashboardId: number
                logicalOperator: LogicalOperator
            }>,
        ) => {
            if (state.selectedDashboard && state.selectedDashboard.id == dashboardId) {
                dashboards.updateOne(state.dashboards, {
                    id: state.selectedDashboard.id,
                    changes: {
                        logicalOperator: logicalOperator,
                    },
                })
                state.logicalOperator = logicalOperator
            }
        },
    },
})

export const {
    receivedDashboardsList,
    receivedNewDashboard,
    receivedDeletedDashboardId,
    receivedUpdatedDashboardName,
    receivedSelectDashboard,
    receivedDashboardFilter,
    receivedClearAllDashboardFilters,
    receivedDeleteDashboardFilter,
    receivedNewDashboardSignatureGene,
    receivedSignatureTabState,
    receivedLogicalOperator,
} = dashboardSlice.actions

export const selectSelectedDashboard = (state: RootState) => state.dashboardListHolder.selectedDashboard

export const selectSelectedDashboardId = (state: RootState) => state.dashboardListHolder.selectedDashboard?.id

export const selectSignatureGenes = (state: RootState) => state.dashboardListHolder.selectedDashboard?.signatureGenes

export const { selectAll: selectAllDashboards, selectById: selectDashboardById } = dashboards.getSelectors<RootState>(
    (state) => state.dashboardListHolder.dashboards,
)

export const { selectAll: selectAllFilters, selectById: selectFilterById } = filters.getSelectors<RootState>(
    (state) => state.dashboardListHolder.filters,
)

export const selectLogicalOperator = (state: RootState) => state.dashboardListHolder.logicalOperator


export default dashboardSlice.reducer

import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { GeneSetCollection, ReferenceGeneSetSource } from '../../../model/model'

const geneSetCollections = createEntityAdapter<GeneSetCollection, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type GeneSetCollectionsState = {
    geneSetCollections: EntityState<GeneSetCollection, number>
}

const initialState = {
    geneSetCollections: geneSetCollections.getInitialState(),
} as GeneSetCollectionsState

const geneSetCollectionListSlice = createSlice({
    name: 'geneSetListHolder',
    initialState: initialState,
    reducers: {
        receivedGeneSetCollectionList: (
            state,
            { payload: { geneSetCollectionList } }: PayloadAction<{ geneSetCollectionList: GeneSetCollection[] }>,
        ) => {
            geneSetCollections.setAll(state.geneSetCollections, geneSetCollectionList)
        },
        receivedNewGeneSetCollection: (
            state,
            { payload: { geneSetCollection } }: PayloadAction<{ geneSetCollection: GeneSetCollection }>,
        ) => {
            geneSetCollections.setOne(state.geneSetCollections, geneSetCollection)
        },
        receivedUpdatedGeneSetCollectionValues: (
            state,
            {
                payload: { id, description, custom, active, referenceSource },
            }: PayloadAction<{
                id: number
                description: string
                custom: boolean
                active: boolean
                referenceSource: ReferenceGeneSetSource | null
            }>,
        ) => {
            geneSetCollections.updateOne(state.geneSetCollections, {
                id: id,
                changes: {
                    description: description,
                    custom: custom,
                    active: active,
                    referenceSource: referenceSource,
                },
            })
        },
        receivedDeletedGeneSetCollections: (
            state,
            { payload: { geneSetCollectionIdList } }: PayloadAction<{ geneSetCollectionIdList: number[] }>,
        ) => {
            geneSetCollections.removeMany(state.geneSetCollections, geneSetCollectionIdList)
        },
    },
})

export const {
    receivedGeneSetCollectionList,
    receivedDeletedGeneSetCollections,
    receivedNewGeneSetCollection,
    receivedUpdatedGeneSetCollectionValues,
} = geneSetCollectionListSlice.actions

export const {
    selectAll: selectAllGeneSetCollections,
    selectById: selectGeneSetCollectionById,
    selectIds: selectGeneSetCollectionIds,
    selectTotal: selectTotalGeneSetCollections,
    selectEntities: selectGeneSetCollectionEntities,
} = geneSetCollections.getSelectors<RootState>((state) => state.geneSetCollectionListHolder.geneSetCollections)

export const selectGeneSetCollectionRows = createSelector([selectAllGeneSetCollections], (geneSetCollections) => {
    return geneSetCollections.map((geneSet: GeneSetCollection) => {
        return {
            id: geneSet.id,
            name: geneSet.name,
            description: geneSet.description.truncate(100),
            organism: geneSet.organism,
            referenceSource: geneSet.referenceSource?.name ?? '',
            custom: geneSet.custom ? 'Yes' : 'No',
            active: geneSet.active ? 'Yes' : 'No',
            createdAt: geneSet.createdAt.parseAndFormatDate(),
            updatedAt: geneSet.updatedAt.parseAndFormatDate(),
            createdBy: `${geneSet.user.firstName} ${geneSet.user.lastName}`,
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export default geneSetCollectionListSlice.reducer

import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ApiKey } from '../../model/model'

const apiKeys = createEntityAdapter<ApiKey, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type ApiKeyListState = {
    apiKeys: EntityState<ApiKey, number>
}

const initialState = {
    apiKeys: apiKeys.getInitialState(),
    state: 'idle',
} as ApiKeyListState

const apiKeyListSlice = createSlice({
    name: 'apiKeyListHolder',
    initialState: initialState,
    reducers: {
        receivedApiKeyList: (state, { payload: { apiKeyList } }: PayloadAction<{ apiKeyList: ApiKey[] }>) => {
            apiKeys.setAll(state.apiKeys, apiKeyList)
        },
        receivedNewApiKey: (state, { payload: { apiKey } }: PayloadAction<{ apiKey: ApiKey }>) => {
            apiKeys.addOne(state.apiKeys, apiKey)
        },
        receivedDeletedApiKeys: (state, { payload: { idList } }: PayloadAction<{ idList: number[] }>) => {
            apiKeys.removeMany(state.apiKeys, idList)
        },
    },
})

export const { receivedApiKeyList, receivedNewApiKey, receivedDeletedApiKeys } = apiKeyListSlice.actions

export const {
    selectAll: selectAllApiKeys,
    selectById: selectApiKeyById,
    selectIds: selectApiKeyIds,
    selectTotal: selectTotalApiKeys,
    selectEntities: selectApiKeyEntities,
} = apiKeys.getSelectors<RootState>((state) => state.apiKeyListHolder.apiKeys)

export const selectApiKeyRows = createSelector([selectAllApiKeys], (apiKeys) => {
    return apiKeys.map((apiKey: ApiKey) => {
        return {
            id: apiKey.id,
            label: apiKey.label,
            createdAt: apiKey.createdAt.parseAndFormatDate(),
            expiresAt: apiKey.expiresAt.parseAndFormatDate(),
            key: apiKey.clearTextKey != '' ? apiKey.clearTextKey : apiKey.viewablePartialKey,
        }
    })
})

export default apiKeyListSlice.reducer

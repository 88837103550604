import { GridFilterModel } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'

export default function useStoredFilterModel(
    tableId: string,
    defaultFilterModel: GridFilterModel,
): [GridFilterModel, (item: GridFilterModel) => void] {
    const storageKey = useMemo(() => `${tableId}_filter_model`, [tableId, defaultFilterModel])

    const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>(() => {
        const storedFilterModel = localStorage.getItem(storageKey)
        if (storedFilterModel === null) {
            localStorage.setItem(storageKey, JSON.stringify(defaultFilterModel))
            return defaultFilterModel
        }

        try {
            return JSON.parse(storedFilterModel)
        } catch {
            localStorage.setItem(storageKey, JSON.stringify(defaultFilterModel))
            return defaultFilterModel
        }
    })

    const updateGridFilterModel = useCallback(
        (item: GridFilterModel) => {
            localStorage.setItem(storageKey, JSON.stringify(item))
            setGridFilterModel(item)
        },
        [storageKey, defaultFilterModel],
    )

    return [gridFilterModel, updateGridFilterModel]
}

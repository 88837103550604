import { wsSlice } from '../../../app/wsSlice'
import { receivedCommand } from './analysisDetailsSlice'

wsSlice.injectSelectHandlers(
    (m) => m.execCommand,
    [
        (p, dispatch) => {
            dispatch(receivedCommand({ cmd: p }))
        },
    ],
)

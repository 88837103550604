import { debounce } from 'lodash'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'

export default function usePersistState<S>(
    defaultState: S,
    initialState: S | null | undefined,
    storeCallback: (state: S) => void,
): [S, Dispatch<SetStateAction<S>>] {
    const [state, setState] = useState<S>(() => {
        return {
            ...defaultState,
            ...initialState,
        }
    })

    const store = useCallback(
        debounce((state: S) => storeCallback(state), 2000, {
            maxWait: 5000,
            trailing: true,
        }),
        [storeCallback],
    )

    function dispatchSetState(setStateAction: SetStateAction<S>): void {
        let newState: S
        if (typeof setStateAction === 'function') {
            newState = (setStateAction as (prevState: S) => S)(state)
        } else {
            newState = setStateAction as S
        }

        store(newState)
        setState(newState)
    }

    return [state, dispatchSetState]
}

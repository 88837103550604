import {
    GridFilterInputValue,
    GridFilterItem,
    GridFilterOperator,
    GridRowModel,
    GridValidRowModel
} from '@mui/x-data-grid-premium'
import { Set } from 'immutable'
import { difference, intersection } from 'lodash'

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export const absGreaterThanOrEqualFilterOperator = {
    label: '|x| >= N',
    value: 'absGreaterThanOrEqual',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.field || !filterItem.value) {
            return null
        }
        return (value: GridRowModel) => {
            const threshold = filterItem.value
            return Math.abs(Number(value)) >= threshold
        }
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: 'number' },
} as GridFilterOperator

export const absLowerThanFilterOperator = {
    label: '|x| < N',
    value: 'absLowerThan',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.field || !filterItem.value) {
            return null
        }
        return (value: GridRowModel) => {
            const threshold = filterItem.value
            return Math.abs(Number(value)) < threshold
        }
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: 'number' },
} as GridFilterOperator

export function gridCheckboxClickedSelection(pageIds: number[], selectedIds: number[]): number[] {
    const selectedFromPage = intersection(pageIds, selectedIds)

    // No rows from the page selected, clicking the checkbox should append all page rows to selection.
    if (selectedFromPage.length === 0) {
        return [...selectedIds, ...pageIds]
    }

    // Some or all the page rows selected, clicking the checkbox should remove all page rows from selection.
    return difference(selectedIds, pageIds)
}

export function SingleGeneExtractorFactory(column: string): (models: GridValidRowModel[]) => string[] {
    return function (models: GridValidRowModel[]): string[] {
        const genes = models.map((m) => {
            return m[column] as string
        })
        return getUniqueGenesWithOrder(genes)
    }
}

export function MultipleGeneExtractorFactory(column: string, sep: string): (models: GridValidRowModel[]) => string[] {
    return function (models: GridValidRowModel[]): string[] {
        const genes = models
            .map((m) => {
                return (m[column] as string).split(sep)
            })
            .flat()
        return getUniqueGenesWithOrder(genes)
    }
}

function getUniqueGenesWithOrder(genes: string[]): string[] {
    const geneSet = Set()
    const uniqueGenesArray: string[] = []
    genes.forEach((g) => {
        if (!geneSet.contains(g)) {
            geneSet.add(g)
            uniqueGenesArray.push(g)
        }
    })
    return uniqueGenesArray
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Sample } from '../../model/model'

type SampleDetailsState = {
    sample: Sample | null
}

const initialState = { sample: null } as SampleDetailsState

const sampleDetailsSlice = createSlice({
    name: 'sampleDetailsHolder',
    initialState: initialState,
    reducers: {
        setSample: (state, { payload: { sample } }: PayloadAction<{ sample: Sample }>) => {
            state.sample = sample
        },
        resetState: (state) => {
            state.sample = null
        },
    },
})

export const { setSample, resetState } = sampleDetailsSlice.actions

export const selectSample = (state: RootState) => state.sampleDetailsHolder.sample

export default sampleDetailsSlice.reducer

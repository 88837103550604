import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import i18n from '../../../i18n'
import { emptyPermissionSet, RoleWithUserCount } from '../../../model/model'

const roles = createEntityAdapter<RoleWithUserCount, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type RoleListState = {
    roles: EntityState<RoleWithUserCount, number>
}

const initialState = {
    roles: roles.getInitialState(),
} as RoleListState

const roleListSlice = createSlice({
    name: 'roleListHolder',
    initialState: initialState,
    reducers: {
        receivedRolesList: (state, { payload: { roleList } }: PayloadAction<{ roleList: RoleWithUserCount[] }>) => {
            roles.setAll(state.roles, roleList)
        },
        receivedRole: (state, { payload: { role } }: PayloadAction<{ role: RoleWithUserCount }>) => {
            roles.setOne(state.roles, role)
        },
        receivedDeletedRoles: (state, { payload: { roleIdList } }: PayloadAction<{ roleIdList: number[] }>) => {
            roles.removeMany(state.roles, roleIdList)
        },
    },
})

export const { receivedRolesList, receivedRole, receivedDeletedRoles } = roleListSlice.actions

export const {
    selectAll: selectAllRoles,
    selectById: selectRoleById,
    selectIds: selectRoleIds,
    selectTotal: selectTotalRoles,
    selectEntities: selectRoleEntities,
} = roles.getSelectors<RootState>((state) => state.roleListHolder.roles)

export const selectRoleRows = createSelector([selectAllRoles], (roles) => {
    const permissionKeys = Object.keys(emptyPermissionSet)
    return roles.map((role: RoleWithUserCount) => {
        return {
            id: role.id,
            name: role.name,
            permissions: Object.entries(role)
                .filter(([k, value]) => typeof value === 'boolean' && value && permissionKeys.includes(k))
                .map(([key]) => i18n.t(key))
                .join(', '),
            inUse: role.userCount > 0 ? 'Yes' : 'No',
            createdAt: role.createdAt.parseAndFormatDate(),
            updatedAt: role.updatedAt.parseAndFormatDate(),
            standard: role.standard,
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export const selectRolesByIds = createSelector([selectAllRoles, selectIds], (roles, ids) => {
    return roles.filter((role: RoleWithUserCount) => {
        return ids.includes(role.id)
    })
})

export default roleListSlice.reducer

import { UpSetModelEntry } from '../../../../model/model'
import { extractCombinations, ISetLike, UpSetJS } from '@upsetjs/react'
import React, { useMemo } from 'react'

export interface UpSetPlotParams {
    model: UpSetModelEntry[]
    width: number
    height: number
    onClick: (v: ISetLike<UpSetModelEntry> | null) => void
}

export function UpSetPlot({ model, width, height, onClick }: UpSetPlotParams) {
    const [selection, setSelection] = React.useState<ISetLike<UpSetModelEntry> | null>(null)
    const { sets, combinations } = useMemo(() => {
        return extractCombinations(model)
    }, [model])

    return (
        // @ts-expect-error External component is causing the error, but it works
        <UpSetJS
            sets={sets}
            combinations={combinations}
            width={width}
            height={height}
            selection={selection}
            onHover={setSelection}
            onClick={onClick}
            exportButtons={{
                share: false,
                vega: false,
                dump: false,
            }}
        />
    )
}

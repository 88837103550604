import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import '../../extensions/string.ext'
import { GeneList } from '../../model/model'
import { selectUserId } from '../auth/authSlice'

const geneLists = createEntityAdapter<GeneList, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type GeneListsState = {
    geneLists: EntityState<GeneList, number>
    totalCount: number
}

const initialState = {
    geneLists: geneLists.getInitialState(),
    totalCount: 0,
} as GeneListsState

const geneListsSlice = createSlice({
    name: 'geneListsHolder',
    initialState: initialState,
    reducers: {
        receivedGeneLists: (
            state,
            { payload: { gl, totalCount } }: PayloadAction<{ gl: GeneList[]; totalCount: number }>,
        ) => {
            geneLists.setAll(state.geneLists, gl)
            state.totalCount = totalCount
        },
    },
})

export const { receivedGeneLists } = geneListsSlice.actions

export const {
    selectAll: selectAllGeneLists,
    selectById: selectGeneListById,
    selectIds: selectGeneListIds,
    selectTotal: selectTotalGeneLists,
    selectEntities: selectGeneListEntities,
} = geneLists.getSelectors<RootState>((state) => state.geneListsHolder.geneLists)

export const selectGeneListRows = createSelector([selectAllGeneLists], (geneLists) => {
    return geneLists.map((geneList: GeneList) => {
        return {
            id: geneList.id,
            name: geneList.name,
            description: geneList.description,
            createdAt: geneList.createdAt.parseAndFormatDate(),
            updatedAt: geneList.updatedAt.parseAndFormatDate(),
            createdBy: `${geneList.user.firstName} ${geneList.user.lastName}`,
            genesCount: geneList.symbols.length,
        }
    })
})

export const selectOwned = (state: RootState, ids: number[]) => {
    const all = selectGeneListEntities(state)
    const userId = selectUserId(state)
    for (const id of ids) {
        if (all[id]?.user.id != userId) {
            return false
        }
    }

    return true
}

export const selectTotalCount = (state: RootState) => state.geneListsHolder.totalCount

export default geneListsSlice.reducer

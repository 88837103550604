import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { User } from '../../../model/model'

const users = createEntityAdapter<User, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type UserListState = {
    users: EntityState<User, number>
    state: 'idle' | 'loading' | 'finished'
}

const initialState = {
    users: users.getInitialState(),
} as UserListState

const userListSlice = createSlice({
    name: 'userListHolder',
    initialState: initialState,
    reducers: {
        receivedUsersList: (state, { payload: { userList } }: PayloadAction<{ userList: User[] }>) => {
            users.setAll(state.users, userList)
        },
        receivedUsersActivationStatusUpdate: (
            state,
            { payload: { userIdList, active } }: PayloadAction<{ userIdList: number[]; active: boolean }>,
        ) => {
            users.updateMany(
                state.users,
                userIdList.map((id) => {
                    return {
                        id: id,
                        changes: {
                            active: active,
                        },
                    }
                }),
            )
        },
        receivedNewOrUpdatedUser: (state, { payload: { user } }: PayloadAction<{ user: User }>) => {
            users.setOne(state.users, user)
        },
    },
})

export const { receivedUsersList, receivedUsersActivationStatusUpdate, receivedNewOrUpdatedUser } =
    userListSlice.actions

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
    selectTotal: selectTotalUsers,
    selectEntities: selectUserEntities,
} = users.getSelectors<RootState>((state) => state.userListHolder.users)

export const selectUserRows = createSelector([selectAllUsers], (users) => {
    return users.map((user: User) => {
        return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            createdAt: user.createdAt.parseAndFormatDate(),
            updatedAt: user.updatedAt.parseAndFormatDate(),
            role: user.role.name,
            active: user.active,
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export const selectUsersByIds = createSelector([selectAllUsers, selectIds], (users, ids) => {
    return users.filter((user: User) => {
        return ids.includes(user.id)
    })
})

export default userListSlice.reducer

import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { CellAtlas, CellAtlasStatus } from '../../model/model'

const cellAtlases = createEntityAdapter<CellAtlas, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type CellAtlasesState = {
    cellAtlases: EntityState<CellAtlas, number>
}

const initialState = {
    cellAtlases: cellAtlases.getInitialState(),
} as CellAtlasesState

const cellAtlasListSlice = createSlice({
    name: 'geneSetListHolder',
    initialState: initialState,
    reducers: {
        receivedCellAtlasList: (
            state,
            { payload: { cellAtlasList } }: PayloadAction<{ cellAtlasList: CellAtlas[] }>,
        ) => {
            cellAtlases.setAll(state.cellAtlases, cellAtlasList)
        },
        receivedDeletedCellAtlases: (
            state,
            { payload: { cellAtlasIdList } }: PayloadAction<{ cellAtlasIdList: number[] }>,
        ) => {
            cellAtlases.removeMany(state.cellAtlases, cellAtlasIdList)
        },
        receivedRetractedCellAtlases: (
            state,
            { payload: { cellAtlasIdList } }: PayloadAction<{ cellAtlasIdList: number[] }>,
        ) => {
            cellAtlases.updateMany(
                state.cellAtlases,
                cellAtlasIdList.map((id) => {
                    return {
                        id: id,
                        changes: {
                            status: CellAtlasStatus.Retracted,
                        },
                    }
                }),
            )
        },
        receivedReinstatedCellAtlases: (
            state,
            { payload: { cellAtlasIdList } }: PayloadAction<{ cellAtlasIdList: number[] }>,
        ) => {
            cellAtlases.updateMany(
                state.cellAtlases,
                cellAtlasIdList.map((id) => {
                    return {
                        id: id,
                        changes: {
                            status: CellAtlasStatus.Ready,
                        },
                    }
                }),
            )
        },
    },
})

export const {
    receivedCellAtlasList,
    receivedRetractedCellAtlases,
    receivedDeletedCellAtlases,
    receivedReinstatedCellAtlases,
} = cellAtlasListSlice.actions

export const {
    selectAll: selectAllCellAtlases,
    selectById: selectCellAtlasById,
    selectIds: selectCellAtlasIds,
    selectTotal: selectTotalCellAtlases,
    selectEntities: selectCellAtlasEntities,
} = cellAtlases.getSelectors<RootState>((state) => state.cellAtlasListHolder.cellAtlases)

export const selectCellAtlasRows = createSelector([selectAllCellAtlases], (cellAtlases) => {
    return cellAtlases.map((cellAtlas: CellAtlas) => {
        return {
            id: cellAtlas.id,
            createdAt: cellAtlas.createdAt.parseAndFormatDate(),
            createdBy: `${cellAtlas.user.firstName} ${cellAtlas.user.lastName}`,
            name: cellAtlas.name,
            organism: cellAtlas.organism,
            tissues: cellAtlas.tissues?.join(', '),
            conditions: cellAtlas.conditions?.join(', '),
            description: cellAtlas.description,
            status: cellAtlas.status,
            error: cellAtlas.error,
            cells: cellAtlas.cells,
            genes: cellAtlas.genes,
            analysisId: cellAtlas.analysisId,
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export default cellAtlasListSlice.reducer

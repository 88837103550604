import { apiSlice } from '../../app/apiSlice'

export interface GetDegsDataFrameUrlResponse {
    url: string
}

export const geneSignatureApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDegsDataFrameUrl: builder.query<GetDegsDataFrameUrlResponse, number>({
            query: (id) => ({
                url: '/private/gene-signature/degs',
                params: { geneSigId: id },
            }),
        }),
    }),
})

export const {
    useGetDegsDataFrameUrlQuery,
    endpoints: { getDegsDataFrameUrl },
} = geneSignatureApiSlice

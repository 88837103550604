import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

type AnalysisDataHubState = {
    lassoSelections: (string | number)[][]
}

const initialState = {
    lassoSelections: [],
} as AnalysisDataHubState

const analysisDataHubSlice = createSlice({
    name: 'analysisDataHubHolder',
    initialState: initialState,
    reducers: {
        receivedLassoSelections: (
            state,
            { payload: { lassoSelections } }: PayloadAction<{ lassoSelections: (string | number)[][] }>,
        ) => {
            state.lassoSelections = lassoSelections
        },
        receivedNewLassoSelection: (
            state,
            { payload: { lassoSelection } }: PayloadAction<{ lassoSelection: (string | number)[] }>,
        ) => {
            state.lassoSelections.push(lassoSelection)
        },
        clearLassoSelections: (state) => {
            state.lassoSelections = initialState.lassoSelections
        },
    },
})

export const { receivedLassoSelections, receivedNewLassoSelection, clearLassoSelections } = analysisDataHubSlice.actions

export const selectLassoSelections = (state: RootState) => state.analysisDataHubHolder.lassoSelections

export default analysisDataHubSlice.reducer

import { wsSlice } from '../../../app/wsSlice'
import { receivedReadmeVersion } from './readmeSlice'

wsSlice.injectSelectHandlers(
    (m) => m.projectReadmeVersion,
    [
        (p, dispatch) => {
            dispatch(
                receivedReadmeVersion({
                    readmeVersion: p,
                }),
            )
        },
    ],
)

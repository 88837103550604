import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'

export default function useStoredColumnModel(
    tableId: string,
    defaultHidden: string[] | undefined,
): [GridColumnVisibilityModel, (newModel: GridColumnVisibilityModel) => void] {
    const storageKey = tableId + '_column_model'

    const [columnModel, setColumnModel] = useState<GridColumnVisibilityModel>(() => {
        const storedModel = localStorage.getItem(storageKey)
        return storedModel ? JSON.parse(storedModel) : {}
    })

    useEffect(() => {
        if (!defaultHidden) {
            return
        }

        defaultHidden.forEach((c) => {
            if (c in columnModel) {
                return
            }

            columnModel[c] = false
        })
    }, [columnModel, defaultHidden])

    const updateColumnModel = (newModel: GridColumnVisibilityModel) => {
        localStorage.setItem(storageKey, JSON.stringify(newModel))
        setColumnModel(newModel)
    }

    return [columnModel, updateColumnModel]
}

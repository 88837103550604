import { GridFilterModel } from '@mui/x-data-grid-premium'
import { GeneList, GeneListAutocompleteResult } from '../../model/model'
import { apiSlice } from '../../app/apiSlice'

export type ListGeneListsRequest = {
    gridFilterModel?: GridFilterModel
    pageNumber: number
    pageSize: number
    sortBy: string | null
    order: 'asc' | 'desc' | null | undefined
}

export type ListGeneListsResponse = {
    geneLists: GeneList[]
    totalCount: number
}

export type CreateOrUpdateGeneListRequest = {
    id?: number
    name: string
    description?: string
    genes: string[]
}

export type CreateOrUpdateGeneListResponse = {
    geneList: GeneList
}

export type DeleteGeneListsRequest = {
    geneListIdList: number[]
}

type GetGeneListsRequest = {
    keyword: string
}

export const geneListApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGeneLists: builder.query<GeneListAutocompleteResult[], GetGeneListsRequest>({
            query: (req) => ({
                url: '/private/gene-list/autocomplete',
                params: { k: req.keyword },
            }),
        }),
        listGeneLists: builder.query<ListGeneListsResponse, ListGeneListsRequest>({
            query: (req) => ({
                url: '/private/gene-list/list',
                method: 'POST',
                body: req,
            }),
        }),
        createOrUpdateGeneList: builder.mutation<CreateOrUpdateGeneListResponse, CreateOrUpdateGeneListRequest>({
            query: (req) => ({
                url: '/private/gene-list/create-or-update',
                method: 'POST',
                body: req,
            }),
        }),
        deleteGeneLists: builder.mutation<void, DeleteGeneListsRequest>({
            query: (req) => ({
                url: '/private/gene-list/delete',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useLazyGetGeneListsQuery,
    useLazyListGeneListsQuery,
    useCreateOrUpdateGeneListMutation,
    useDeleteGeneListsMutation,
    endpoints: { getGeneLists, listGeneLists, createOrUpdateGeneList, deleteGeneLists },
} = geneListApiSlice

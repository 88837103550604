import { SlideUpTransition } from '../../../utils/transitions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Link, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { DialogProps, GeneCoPerturbationResultNotificationProps } from '../../../model/model'
import Dialog from '@mui/material/Dialog'
import { useLazyGetResultUrlQuery } from '../../common-api/asyncJobApiSlice'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { DataFrame, readCSV, toJSON } from 'danfojs'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import { useAppDispatch } from '../../../app/hooks'
import GridToolbarWithSaveGenes from '../../workbench/analysis/common/GridToolbarWithSaveGenes'
import { SingleGeneExtractorFactory } from '../../../utils/grid'
import DataFrameGrid, { Render } from '../../workbench/analysis/common/DataFrameGrid'
import CreateOrUpdateGeneListDialog from '../../genelist/CreateOrUpdateGeneListDialog'
import ResultsLoadingOrMissing from '../../workbench/analysis/common/ResultsLoadingOrMissing'
import useWindowSize from '../../../hooks/useWindowResize'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useGetSignaturesForCoPerturbedGenesMutation } from '../../common-api/coperturbationApiSlice'
import { OmicsBrowserQueryResponse } from '../queryApiSlice'
import { GeneSignatureGrid } from '../gene_signature/SignatureTable'
import { WithIdName } from '../../sample/import/model'
import Divider from '@mui/material/Divider'

export interface GeneCoPerturbationDialogParams extends DialogProps {
    result: GeneCoPerturbationResultNotificationProps
}

export default function GeneCoPerturbationResultDialog({
    openDialog,
    handleCloseDialog,
    result,
}: GeneCoPerturbationDialogParams) {
    const dispatch = useAppDispatch()
    const [resultDataFrameLoading, setResultDataFrameLoading] = useState(false)
    const [resultDataFrame, setResultDataFrame] = useState<DataFrame | null>(null)
    const [openCreateGeneListDialog, setOpenCreateGeneListDialog] = useState(false)
    const [filteredGenes, setFilteredGenes] = useState<string[]>([])
    const { height = 0, width = 0 } = useWindowSize()
    const [getResultUrl, { isFetching }] = useLazyGetResultUrlQuery()
    const [getSignaturesForCoPerturbedGenesApi, { isLoading }] = useGetSignaturesForCoPerturbedGenesMutation()
    const [geneSignaturesDataFrame, setGeneSignaturesDataFrame] = useState<DataFrame | null>(null)
    const [geneSignaturesDataFrameLoading, setGeneSignaturesDataFrameLoading] = useState(false)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        setResultDataFrame(null)
        setGeneSignaturesDataFrame(null)
    })

    useEffect(() => {
        if (openDialog) {
            setResultDataFrameLoading(true)
            getResultUrl(result.coPerturbedGenesCsv)
                .unwrap()
                .then((resp) => {
                    readCSV(resp.url, { skipEmptyLines: 'greedy' })
                        .then((df) => {
                            setResultDataFrame(df)
                        })
                        .catch(() => {
                            dispatch(
                                receivedAppMessage({
                                    type: 'error',
                                    message: 'Error loading co-perturbed genes data.',
                                }),
                            )
                        })
                        .finally(() => {
                            setResultDataFrameLoading(true)
                        })
                })
                .finally(() => {
                    setResultDataFrameLoading(true)
                })
        }
    }, [openDialog, getResultUrl, result])

    const updateGeneSignaturesTable = (res: OmicsBrowserQueryResponse) => {
        readCSV(res.url, { skipEmptyLines: 'greedy' })
            .then((df) => {
                setGeneSignaturesDataFrame(df)
            })
            .catch(() => {
                dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: 'Error loading gene signatures for the selected co-perturbed genes.',
                    }),
                )
            })
            .finally(() => {
                setGeneSignaturesDataFrameLoading(false)
            })
    }

    const customRenderDataFrame = useMemo(() => {
        const countRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    onClick={(event) => {
                        setGeneSignaturesDataFrameLoading(true)
                        getSignaturesForCoPerturbedGenesApi({
                            geneSignatureIds: result.geneSignatureIds,
                            gene1: result.targetGene,
                            gene2: params.row['gene'],
                            direction:
                                params.field == 'up_count' ? 'up' : params.field == 'down_count' ? 'down' : 'opposing',
                            pValThreshold: result.pval,
                            minAbsLogFoldChange: result.logFoldChange,
                            raw: result.raw,
                        })
                            .unwrap()
                            .then((res) => {
                                updateGeneSignaturesTable(res)
                            })
                        event.stopPropagation()
                    }}
                >
                    {params.value}
                </Link>
            )
        }

        return (field: string): Render | undefined => {
            if (['up_count', 'down_count', 'opposing_count'].includes(field)) {
                return countRenderer
            }

            return undefined
        }
    }, [])

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            Gene Co-Perturbation Report for <b>{result.targetGene}</b>
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', width: '100%', gap: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '300px',
                        margin: '16px 0 16px 16px',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        p: 2,
                    }}
                >
                    <Typography variant={'h6'} sx={{ mb: 1 }}>
                        Report params
                    </Typography>
                    <Divider />
                    <Stack
                        sx={{
                            mt: 2,
                            '& .MuiBox-root': {
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 0.5,
                            },
                        }}
                    >
                        <Box>
                            <Typography variant={'body2'}>Target gene:</Typography>
                            <Typography>
                                <strong>{result?.targetGene}</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={'body2'}>Gene signatures selected:</Typography>
                            <Typography>
                                <strong>{result?.geneSignatureIds?.length}</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={'body2'}>
                                {result?.raw ? 'Un-adjusted p-value threshold:' : 'p-value threshold:'}
                            </Typography>
                            <Typography>
                                <strong>{result?.pval}</strong>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={'body2'}>Log fold change threshold</Typography>
                            <Typography>
                                <strong>{result?.logFoldChange}</strong>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ display: 'flex', p: 2, flexGrow: 1, flexDirection: 'column' }}>
                    {resultDataFrame ? (
                        <>
                            <DataFrameGrid
                                df={resultDataFrame}
                                decimals={2}
                                customRenderer={customRenderDataFrame}
                                slots={{
                                    toolbar: GridToolbarWithSaveGenes,
                                }}
                                slotProps={{
                                    toolbar: {
                                        saveGenesCallback: (filteredGenes: string[]) => {
                                            setFilteredGenes(filteredGenes)
                                            setOpenCreateGeneListDialog(true)
                                        },
                                        extractGenesCallback: SingleGeneExtractorFactory('gene'),
                                        exportFileName: `${result.targetGene}_${result.pval}_${result.logFoldChange}_coperturbation`,
                                    },
                                }}
                                width={Math.max(width - 332, 550)}
                                height={
                                    geneSignaturesDataFrameLoading || geneSignaturesDataFrame || isLoading
                                        ? Math.max(height / 2 - 50, 300)
                                        : Math.max(height - 100, 300)
                                }
                                showBorder
                            />
                            <CreateOrUpdateGeneListDialog
                                openDialog={openCreateGeneListDialog}
                                handleCloseDialog={() => {
                                    setOpenCreateGeneListDialog(false)
                                }}
                                externalGenes={filteredGenes}
                            />
                            {(geneSignaturesDataFrame || isLoading || geneSignaturesDataFrameLoading) && (
                                <Box sx={{ height: `${Math.max(height / 2 - 50, 300)}px`, mt: 2 }}>
                                    <GeneSignatureGrid
                                        filteredDatasets={
                                            geneSignaturesDataFrame
                                                ? (toJSON(geneSignaturesDataFrame) as WithIdName[])
                                                : []
                                        }
                                        resultDataFrame={geneSignaturesDataFrame}
                                        isFetching={isLoading}
                                        showBorder={true}
                                        width={Math.max(width - 332, 550)}
                                        tableModelKey={'coperturbation_signatures'}
                                    />
                                </Box>
                            )}
                        </>
                    ) : (
                        <ResultsLoadingOrMissing
                            loading={isFetching || resultDataFrameLoading}
                            loadingMessage={'Loading data table...'}
                            missingMessage={'There are no differentially expressed genes between your selected groups.'}
                        />
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}

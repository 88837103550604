import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { SxProps } from '@mui/system'

export interface ResultsLoadingOrMissing {
    loading: boolean
    loadingMessage: string
    missingMessage?: string
    sx?: SxProps
}

export default function ResultsLoadingOrMissing({
    loading,
    loadingMessage,
    missingMessage,
    sx,
}: ResultsLoadingOrMissing) {
    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', gap: 2, ...sx }}>
                    <CircularProgress size={20} />
                    <Typography>{loadingMessage}</Typography>
                </Box>
            ) : (
                <>
                    {missingMessage ? (
                        <Alert severity={'info'} sx={sx}>
                            {missingMessage}
                        </Alert>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { store } from './app/store'
import './i18n'

import { setupListeners } from '@reduxjs/toolkit/query'
import { WebSocketProvider } from './app/WebSocketContext'

import * as Sentry from '@sentry/react'

Sentry.init({
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: 'https://e50f59bbe7bfb417eac988805102e848@o4506807552049152.ingest.sentry.io/4506807555391488',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://.*.panomics.bio/.*'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// RTK query listeners to enable re-fetching.
setupListeners(store.dispatch)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <WebSocketProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </WebSocketProvider>
        </Provider>
    </React.StrictMode>,
)

import { apiSlice } from '../../app/apiSlice'
import { Notification } from '../../model/model'

export type GetLatestNotificationsRequest = {
    offset?: number
    limit?: number
}

export type GetLatestNotificationsResponse = {
    notifications: Notification[]
    totalCount: number
    totalUnreadCount: number
}

export const notificationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLatest: builder.query<GetLatestNotificationsResponse, GetLatestNotificationsRequest>({
            query: (req) => ({
                url: '/private/notification',
                params: {
                    ...req,
                },
                method: 'GET',
            }),
        }),
        markAsRead: builder.mutation<void, number>({
            query: (id) => ({
                url: '/private/notification/read',
                method: 'POST',
                params: {
                    notificationId: id,
                },
            }),
        }),
        markAllAsRead: builder.mutation<void, void>({
            query: () => ({
                url: '/private/notification/read/all',
                method: 'POST',
            }),
        }),
    }),
})

export const { useLazyGetLatestQuery, useMarkAsReadMutation, useMarkAllAsReadMutation } = notificationApiSlice

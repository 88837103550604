import { Box } from '@mui/material'
import { DataGridPremium, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import useStoredFilterModel from '../../../hooks/useStoredFilterModel'
import { MetadataField } from '../../../model/model'
import { useLazyGetDetailedCollectionsQuery } from '../../common-api/collectionApiSlice'
import CollectionsGridToolbar from './CollectionGridToolbar'
import CreateOrUpdateCollectionDialog from './CreateOrUpdateCollectionDialog'
import DeleteCollectionsDialog from './DeleteCollectionDialog'
import { receivedCollectionList, selectCollectionRows, selectTotalCollections } from './adminCollectionSlice'

export default function CollectionList() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const collectionRows = useAppSelector(selectCollectionRows)
    const totalCount = useAppSelector(selectTotalCollections)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [openCreateOrUpdateCollectionDialog, setOpenCreateOrUpdateCollectionDialog] = useState(false)
    const [openDeleteCollectionDialog, setOpenDeleteCollectionDialog] = useState(false)
    const [selectedCollectionId, setSelectedCollectionId] = useState<number | null>(null)
    const { width = 0 } = useWindowSize()

    const [filterModel, setFilterModel] = useStoredFilterModel('collection_list', {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const [listCollectionsApi, { isLoading: isFetching }] = useLazyGetDetailedCollectionsQuery()

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            groupable: false,
            width: Math.max(width - 1400, 300),
        },
        {
            field: 'sampleCount',
            headerName: t('sampleCount'),
            groupable: false,
            width: 150,
        },
        {
            field: 'sampleTypes',
            headerName: t('sampleTypes'),
            groupable: false,
            width: 300,
        },
        {
            field: 'groupings',
            headerName: t('groupings'),
            groupable: false,
            width: 500,
            renderCell: (params: GridRenderCellParams) => {
                return (params.row['groupings'] as MetadataField[])?.map((x) => x.name).join(', ') ?? ''
            },
        },
        {
            field: 'createdAt',
            headerName: t('createdAt'),
            groupable: false,
            filterable: false,
            width: 200,
        },
        {
            field: 'updatedAt',
            headerName: t('updatedAt'),
            groupable: false,
            filterable: false,
            width: 200,
        },
    ]

    const fetchCollections = async () => {
        const result = await listCollectionsApi().unwrap()
        dispatch(
            receivedCollectionList({
                collectionList: result,
            }),
        )
    }

    useEffect(() => {
        void fetchCollections()
    }, [])

    const deletable = useMemo(() => {
        return (
            selectionModel.length > 0 &&
            collectionRows.filter((o) => selectionModel.includes(o.id) && o.sampleCount !== 0).length == 0
        )
    }, [selectionModel])

    return (
        <Box sx={{ width: '100%' }}>
            <DataGridPremium
                sx={{
                    border: 0,
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                rows={collectionRows}
                rowCount={totalCount}
                density={'standard'}
                columns={columns}
                disableAggregation
                disableColumnSelector
                loading={isFetching}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                checkboxSelection
                keepNonExistentRowsSelected
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                disableMultipleColumnsSorting
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
                slots={{
                    toolbar: CollectionsGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        refreshCallback: () => {
                            void fetchCollections()
                        },
                        createCallback: () => {
                            setSelectionModel([])
                            setSelectedCollectionId(null)
                            setOpenCreateOrUpdateCollectionDialog(true)
                        },
                        updateCallback: () => {
                            if (selectionModel.length == 1) {
                                setSelectedCollectionId(selectionModel[0] as number)
                                setOpenCreateOrUpdateCollectionDialog(true)
                            }
                        },
                        deleteCallback: () => {
                            if (deletable) {
                                setOpenDeleteCollectionDialog(true)
                            }
                        },
                        disableUpdate: selectionModel.length != 1,
                        disableDelete: !deletable,
                    },
                }}
            />
            <CreateOrUpdateCollectionDialog
                openDialog={openCreateOrUpdateCollectionDialog}
                handleCloseDialog={() => {
                    setOpenCreateOrUpdateCollectionDialog(false)
                }}
                collectionId={selectedCollectionId}
            />
            <DeleteCollectionsDialog
                openDialog={openDeleteCollectionDialog}
                handleCloseDialog={() => {
                    setOpenDeleteCollectionDialog(false)
                }}
                selectionModel={selectionModel}
                onDone={() => {
                    setSelectionModel([])
                }}
            />
        </Box>
    )
}

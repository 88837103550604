import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../app/hooks'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { DialogProps } from '../../../model/model'
import { receivedClearAllDashboardFilters } from '../dashboardSlice'
import { useClearAllDashboardFiltersMutation } from './dashboardFiltersApiSlice'

export default function ClearAllDashboardFiltersDialog(
    props: DialogProps & {
        dashboardId: number
    },
) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [clearAllFilters, { isLoading: isClearing }] = useClearAllDashboardFiltersMutation()

    const submit = () => {
        clearAllFilters({ dashboardId: props.dashboardId })
            .unwrap()
            .then(() => {
                dispatch(
                    receivedClearAllDashboardFilters({
                        id: props.dashboardId,
                    }),
                )
                close()
            })
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(props.handleCloseDialog)

    return (
        <Dialog open={props.openDialog} onClose={close}>
            <DialogTitle>Clear All Filters</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    <Typography>Are you sure you want to clear all filters?</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isClearing ? (
                    <Button onClick={submit} autoFocus>
                        {t('delete')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

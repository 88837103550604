import { apiSlice } from '../../app/apiSlice'
import { Ontology, OntologyClass } from '../../model/model'

type GetOntologyOptionsRequest = {
    ontologyId: number
    keyword: string
}

type UpdateOntologyRequest = {
    id: number
    description: string
}

type DeleteOntologiesRequest = {
    ontologyIdList: number[]
}
type DeleteOntologiesResponse = {
    ontologyIdList: number[]
}

export const ontologyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOntologyOptions: builder.query<OntologyClass[], GetOntologyOptionsRequest>({
            query: (req) => ({
                url: '/private/ontology/search',
                params: { o: req.ontologyId, k: req.keyword },
            }),
        }),
        getOntologyClassListByName: builder.query<OntologyClass[], string>({
            query: (name) => ({
                url: '/private/ontology/class/list-by-name',
                params: { o: name },
            }),
        }),
        getOntologyList: builder.query<Ontology[], void>({
            query: () => ({
                url: '/private/ontology/list',
            }),
        }),
        createOntology: builder.mutation<Ontology, FormData>({
            query: (req) => ({
                url: '/private/ontology/create',
                method: 'POST',
                body: req,
            }),
        }),
        updateOntology: builder.mutation<void, UpdateOntologyRequest>({
            query: (req) => ({
                url: '/private/ontology/update',
                method: 'PUT',
                body: req,
            }),
        }),
        getOntologyDownloadURL: builder.query<string, number>({
            query: (id) => ({
                url: '/private/ontology/url',
                params: { id: id },
            }),
        }),
        deleteOntologies: builder.mutation<DeleteOntologiesResponse, DeleteOntologiesRequest>({
            query: (req) => ({
                url: '/private/ontology/delete',
                method: 'DELETE',
                body: req,
            }),
        }),
    }),
})

export const {
    useLazyGetOntologyOptionsQuery,
    useGetOntologyClassListByNameQuery,
    useGetOntologyListQuery,
    useLazyGetOntologyListQuery,
    useCreateOntologyMutation,
    useUpdateOntologyMutation,
    useDeleteOntologiesMutation,
    useLazyGetOntologyDownloadURLQuery,
    endpoints: { getOntologyOptions, getOntologyClassListByName },
} = ontologyApiSlice

import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useMemo } from 'react'
import { DuplicateSampleNamesNotificationProps } from '../../../model/model'
import NotificationContent, { NotificationParams } from './NotificationContent'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography } from '@mui/material'

export default function DuplicateSampleNames({ notification, markAsRead }: NotificationParams) {
    const navigate = useNavigate()

    const properties = useMemo((): DuplicateSampleNamesNotificationProps => {
        return notification.properties as unknown as DuplicateSampleNamesNotificationProps
    }, [notification])

    return (
        <NotificationContent
            notification={notification}
            onClick={() => {
                if (!notification.read) {
                    markAsRead(notification.id)
                }
                navigate(`/project/list`)
            }}
            icon={<ErrorOutlineIcon color={'error'} />}
            title={<>Error importing samples</>}
        >
            <Box>
                <Typography variant={'body2'}>
                    Your sample batch had duplicate sample names: {properties.duplicateSampleNames.join(', ')}.
                </Typography>
            </Box>
        </NotificationContent>
    )
}

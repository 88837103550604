import React, { ChangeEvent, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Box, CircularProgress, TextField } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useCreateDashboardMutation } from './dashboardApiSlice'
import { receivedNewDashboard } from './dashboardSlice'
import { useAppDispatch } from '../../app/hooks'
import { DialogProps } from '../../model/model'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'

export default function CreateDashboardDialog(props: DialogProps) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [createDashboard, { isLoading: isCreating }] = useCreateDashboardMutation()

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const validateForm = () => {
        setNameError(false)
        let valid = true
        if (!name) {
            setNameError(true)
            valid = false
        }
        return valid
    }

    const submit = () => {
        if (!validateForm()) {
            return
        }
        createDashboard({ name: name })
            .unwrap()
            .then((resp) => {
                dispatch(
                    receivedNewDashboard({
                        dashboard: resp.dashboard,
                    }),
                )
                close()
            })
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(() => {
        setName('')
        setNameError(false)
        props.handleCloseDialog()
    })

    return (
        <Dialog open={props.openDialog} onClose={close} maxWidth={'sm'}>
            <DialogTitle>Create Dashboard</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            required
                            fullWidth
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isCreating ? (
                    <Button onClick={submit} autoFocus>
                        {t('create')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, CircularProgress, Link, MenuItem, Select, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { LogicalOperator } from '../../../model/model'
import {
    receivedDeleteDashboardFilter,
    receivedLogicalOperator,
    selectAllFilters,
    selectFilterById,
    selectLogicalOperator,
    selectSelectedDashboard,
    selectSelectedDashboardId,
} from '../dashboardSlice'
import ClearAllDashboardFiltersDialog from './ClearAllDashboardFiltersDialog'
import DashboardFilterDialog from './DashboardFilterDialog'
import { useDeleteDashboardFilterMutation, useUpdateDashboardLogicalOperatorMutation } from './dashboardFiltersApiSlice'

export default function DashboardFilterArea() {
    const selectedDashboard = useAppSelector(selectSelectedDashboard)
    const filters = useAppSelector(selectAllFilters)
    const [openCreateDashboardFilterDialog, setOpenCreateDashboardFilterDialog] = useState(false)
    const [openClearAllDashboardFiltersDialog, setOpenClearAllDashboardFiltersDialog] = useState(false)

    return (
        selectedDashboard && (
            <Paper
                elevation={2}
                sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '20px',
                    p: 2,
                    mb: 1.5,
                    borderRadius: '0px',
                }}
            >
                <Stack>
                    <Box>
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{ mr: 1 }}
                            variant={'outlined'}
                            onClick={() => {
                                setOpenCreateDashboardFilterDialog(true)
                            }}
                        >
                            Add Filter
                        </Button>
                        <Button
                            startIcon={<ClearAllIcon />}
                            variant={'outlined'}
                            disabled={filters?.length == 0}
                            onClick={() => {
                                setOpenClearAllDashboardFiltersDialog(true)
                            }}
                        >
                            Clear All Filters
                        </Button>
                    </Box>
                    <Box sx={{ mt: 2, display: 'flex' }}>
                        {filters && filters.length > 0 ? (
                            <>
                                {filters.map((f, idx) => (
                                    <DashboardFilterWidget id={f.id} key={`filter-${idx}`} idx={idx} />
                                ))}
                            </>
                        ) : (
                            <Typography
                                variant={'body2'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#666',
                                    mt: 1,
                                }}
                            >
                                <FilterAltIcon /> No dataset filters.
                            </Typography>
                        )}
                    </Box>
                </Stack>
                <DashboardFilterDialog
                    openDialog={openCreateDashboardFilterDialog}
                    handleCloseDialog={() => {
                        setOpenCreateDashboardFilterDialog(false)
                    }}
                />
                <ClearAllDashboardFiltersDialog
                    openDialog={openClearAllDashboardFiltersDialog}
                    handleCloseDialog={() => {
                        setOpenClearAllDashboardFiltersDialog(false)
                    }}
                    dashboardId={selectedDashboard.id}
                />
            </Paper>
        )
    )
}

function DashboardFilterWidget(props: { idx: number; id: number }) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const filter = useAppSelector((state) => selectFilterById(state, props.id))
    const dashboardId = useAppSelector(selectSelectedDashboardId)
    const logicalOperator = useAppSelector(selectLogicalOperator)
    const [openEditDashboardFilterDialog, setOpenEditDashboardFilterDialog] = useState(false)
    const [updateLogicalOperator] = useUpdateDashboardLogicalOperatorMutation()
    const [deleteFilter, { isLoading: isDeleting }] = useDeleteDashboardFilterMutation()

    const deleteDashboardFilter = () => {
        if (!dashboardId || !filter) {
            return
        }
        deleteFilter({
            dashboardId: dashboardId,
            filterId: filter.id,
        })
            .unwrap()
            .then(() => {
                dispatch(
                    receivedDeleteDashboardFilter({
                        dashboardId: dashboardId,
                        filterId: filter.id,
                    }),
                )
            })
    }

    const handleLogicalOperatorChange = (event: SelectChangeEvent) => {
        if (!dashboardId) {
            return
        }
        dispatch(
            receivedLogicalOperator({
                dashboardId: dashboardId,
                logicalOperator: event.target.value as LogicalOperator,
            }),
        )
        updateLogicalOperator({
            dashboardId: dashboardId,
            logicalOperator: event.target.value as LogicalOperator,
        })
    }

    if (!filter) {
        return <></>
    }

    return (
        <Box
            sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {props.idx > 0 &&
                (props.idx == 1 ? (
                    <>
                        <Select
                            size='small'
                            value={logicalOperator}
                            onChange={handleLogicalOperatorChange}
                            sx={{ width: '80px', ml: 1, mr: 1, height: '26px' }}
                        >
                            <MenuItem value={'and'}>AND</MenuItem>
                            <MenuItem value={'or'}>OR</MenuItem>
                        </Select>
                    </>
                ) : (
                    <Typography
                        sx={{
                            mr: 1,
                            ml: 1,
                        }}
                    >
                        {logicalOperator}
                    </Typography>
                ))}
            <Box
                sx={{
                    border: '1px solid #BBB',
                    borderRadius: '3px',
                    p: '3px 8px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => setOpenEditDashboardFilterDialog(true)}
                >
                    <Typography
                        variant={'body2'}
                        sx={{
                            fontWeight: 'bold',
                            mr: '3px',
                        }}
                    >
                        {t(filter.field)}
                    </Typography>
                    <Typography variant={'body2'} sx={{ mr: '3px' }}>
                        {filter.operator.toUpperCase()}
                    </Typography>
                    <Typography
                        variant={'body2'}
                        sx={{
                            background: 'rgba(42, 157, 143, 0.2)',
                            borderRadius: '2px',
                            pr: '5px',
                            pl: '5px',
                            mr: '3px',
                        }}
                    >
                        {filter.value}
                    </Typography>
                </Box>
                {isDeleting ? (
                    <CircularProgress size={10} />
                ) : (
                    <Link
                        onClick={deleteDashboardFilter}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <HighlightOffIcon sx={{ fontSize: '15px' }} />
                    </Link>
                )}
            </Box>
            <DashboardFilterDialog
                openDialog={openEditDashboardFilterDialog}
                handleCloseDialog={() => {
                    setOpenEditDashboardFilterDialog(false)
                }}
                filter={filter}
            />
        </Box>
    )
}

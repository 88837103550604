import {
    Autocomplete,
    Box,
    FilledInputProps,
    styled,
    SxProps,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../app/hooks'
import { GeneListAutocompleteResult, GeneListBase } from '../../../../model/model'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'
import { useLazyGetGeneListsQuery } from '../../../genelist/geneListApiSlice'

type GeneListSelectorProps = {
    genes?: string[] | null
    geneList?: GeneListBase | null
    handleGenesChange?: genesHandler
    handleGenesAndListChange?: genesAndListHandler
    labelManual?: string
    labelGenelist?: string
    placeHolderGenes?: string
    sx?: SxProps
    InputProps?: Partial<FilledInputProps>
    small?: boolean
    error?: boolean
    applyOnBlur?: boolean
}

type genesHandler = (genes: string[]) => void
type genesAndListHandler = (genes: string[], geneList: GeneListBase | undefined) => void

export default function GeneListSelector(props: GeneListSelectorProps) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [selectorState, setSelectorState] = useState<'genelist' | 'manual'>('genelist')
    const [selectorInitialised, setSelectorInitialised] = useState(false)
    const [geneList, setGeneList] = useState<GeneListAutocompleteResult | null>(null)
    const [genes, setGenes] = useState<string>('')

    const [innerGetGeneListApi, { data, isLoading }] = useLazyGetGeneListsQuery()

    const getGeneLists = useCallback(
        debounce((keyword: string) => {
            innerGetGeneListApi({ keyword: keyword }).catch(() => {
                dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: 'Error loading gene lists.',
                    }),
                )
            })
        }, 300),
        [innerGetGeneListApi],
    )

    const setGeneArrayToGenes = (geneList: string[]) => {
        setGenes(geneList.join(' '))
    }

    const mapGeneList = (geneListResult: GeneListAutocompleteResult | null) => {
        if (!geneListResult) {
            return undefined
        }

        return {
            ...geneListResult,
            symbols: geneListResult.genes,
        } as GeneListBase
    }

    useEffect(() => {
        if (props.geneList) {
            setGeneList({ ...props.geneList, genes: props.geneList.symbols } as GeneListAutocompleteResult)
            setGeneArrayToGenes([])

            if (!selectorInitialised) {
                setSelectorState('genelist')
                setSelectorInitialised(true)
            }
        } else {
            setGeneList(null)
            setGeneArrayToGenes(props.genes ?? [])

            if (!selectorInitialised) {
                setSelectorState('manual')
                setSelectorInitialised(true)
            }
        }
    }, [props.genes, props.geneList])

    useEffect(() => {
        getGeneLists('')
    }, [])

    const handleSelectorChange = (state: 'manual' | 'genelist') => {
        if (!state || state === selectorState) {
            return
        }

        setSelectorState(state)

        if (genes || geneList) {
            props.handleGenesAndListChange?.([], undefined)
            props.handleGenesChange?.([])
        }
        setGenes('')
        setGeneList(null)
    }

    const parseGenes = (genes: string) => {
        if (genes) {
            const geneList = []
            const parts = genes.split(/[, ;]/)
            for (let idx = 0; idx < parts.length; idx++) {
                if (parts[idx].trim()) {
                    geneList.push(parts[idx])
                }
            }
            return geneList
        }
        return []
    }

    const lift = () => {
        const geneArray = parseGenes(genes)
        props.handleGenesAndListChange?.(geneArray, undefined)
        props.handleGenesChange?.(geneArray)
    }

    return (
        <Box sx={{ display: 'flex', ...props.sx }}>
            <ToggleButtonGroup
                size={'small'}
                sx={{ mr: 1, ...(props.small && { height: 38 }) }}
                value={selectorState}
                exclusive
                onChange={(e, newState) => handleSelectorChange(newState)}
                aria-label='gene input'
            >
                <CustomToggleButton size='small' value='manual' aria-label='manual input' sx={{ minWidth: '80px' }}>
                    {t('manual')}
                </CustomToggleButton>
                <CustomToggleButton size='small' value='genelist' aria-label='genelist input' sx={{ minWidth: '80px' }}>
                    {t('geneList')}
                </CustomToggleButton>
            </ToggleButtonGroup>

            {selectorState === 'genelist' && (
                <Autocomplete
                    loading={isLoading}
                    options={data ?? []}
                    filterOptions={(x) => x}
                    autoComplete
                    fullWidth
                    getOptionLabel={(o: GeneListAutocompleteResult) => {
                        return o.name
                    }}
                    value={geneList}
                    isOptionEqualToValue={(option: GeneListAutocompleteResult, value: GeneListAutocompleteResult) => {
                        return option.id === value.id
                    }}
                    onChange={(_, newValue: GeneListAutocompleteResult | null) => {
                        setGeneList(newValue)
                        props.handleGenesAndListChange?.(newValue?.genes ?? [], mapGeneList(newValue))
                        props.handleGenesChange?.(newValue?.genes ?? [])
                    }}
                    onInputChange={(_, newInputValue) => {
                        getGeneLists(newInputValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={props.small ? 'small' : 'medium'}
                            label={props.labelGenelist ?? 'Filter with an existing gene list'}
                            error={props.error}
                            InputProps={{ ...props.InputProps, ...params.InputProps }}
                        />
                    )}
                />
            )}
            {selectorState === 'manual' && (
                <TextField
                    size={props.small ? 'small' : 'medium'}
                    label={props.labelManual ?? 'Filter by genes'}
                    fullWidth
                    variant='outlined'
                    placeholder={props.placeHolderGenes ? `E.g.: ${props.placeHolderGenes}` : undefined}
                    value={genes}
                    onChange={(e) => setGenes(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            lift()
                        }
                    }}
                    onBlur={() => {
                        if (props.applyOnBlur) {
                            lift()
                        }
                    }}
                    slotProps={{
                        ...props.InputProps,
                        htmlInput: {
                            autoComplete: 'off', // disable autocomplete and autofill
                        },
                    }}
                    error={props.error}
                />
            )}
        </Box>
    )
}

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))

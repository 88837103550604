import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { RuntimeDetails, RuntimeType } from '../../model/model'

export type RuntimeStats = RuntimeDetails & {
    connected: boolean
    loaded: boolean
}

const runtimes = createEntityAdapter<RuntimeStats, RuntimeType>({
    selectId: (x) => x.runtimeType,
})

type RuntimeState = {
    runtimes: EntityState<RuntimeStats, RuntimeType>
}

const initialState: RuntimeState = {
    runtimes: runtimes.getInitialState(undefined, [
        {
            id: 0,
            exists: false,
            proc: null,
            instanceType: null,
            gpus: null,
            vcpus: null,
            memoryGib: null,
            gpuMemoryGib: null,
            createdAt: null,
            connected: false,
            durationHours: null,
            runtimeType: RuntimeType.ComputeRuntime,
            loaded: false,
            volumeSize: null,
        },
        {
            id: 0,
            exists: false,
            proc: null,
            instanceType: null,
            gpus: null,
            vcpus: null,
            memoryGib: null,
            gpuMemoryGib: null,
            createdAt: null,
            connected: false,
            durationHours: null,
            runtimeType: RuntimeType.JupyterLabRuntime,
            loaded: false,
            volumeSize: null,
        },
        {
            id: 0,
            exists: false,
            proc: null,
            instanceType: null,
            gpus: null,
            vcpus: null,
            memoryGib: null,
            gpuMemoryGib: null,
            createdAt: null,
            connected: false,
            durationHours: null,
            runtimeType: RuntimeType.RStudioRuntime,
            loaded: false,
            volumeSize: null,
        },
    ]),
}

const runtimeSlice = createSlice({
    name: 'runtime',
    initialState: initialState,
    reducers: {
        setRuntime: (
            state,
            {
                payload: {
                    id,
                    exists,
                    proc,
                    instanceType,
                    gpus,
                    vcpus,
                    memoryGib,
                    gpuMemoryGib,
                    createdAt,
                    connected,
                    durationHours,
                    runtimeType,
                    volumeSize,
                },
            }: PayloadAction<{
                id: number
                exists: boolean | null
                proc: string | null
                instanceType: string | null
                gpus: number | null
                vcpus: number | null
                memoryGib: number | null
                gpuMemoryGib: number | null
                createdAt: string | null
                connected: boolean | null
                durationHours: number | null
                runtimeType: RuntimeType
                volumeSize: number | null
            }>,
        ) => {
            runtimes.upsertOne(state.runtimes, {
                id,
                exists: exists ?? false,
                proc,
                instanceType,
                gpus,
                vcpus,
                memoryGib,
                gpuMemoryGib,
                createdAt,
                connected: connected ?? false,
                durationHours: durationHours,
                runtimeType,
                loaded: true,
                volumeSize,
            })
        },
        setRuntimes: (
            state,
            {
                payload,
            }: PayloadAction<
                {
                    id: number
                    exists: boolean | null
                    proc: string | null
                    instanceType: string | null
                    gpus: number | null
                    vcpus: number | null
                    memoryGib: number | null
                    gpuMemoryGib: number | null
                    createdAt: string | null
                    connected: boolean | null
                    durationHours: number | null
                    runtimeType: RuntimeType
                    volumeSize: number | null
                }[]
            >,
        ) => {
            runtimes.upsertMany(
                state.runtimes,
                payload.map(
                    ({
                        id,
                        exists,
                        proc,
                        instanceType,
                        gpus,
                        vcpus,
                        memoryGib,
                        gpuMemoryGib,
                        createdAt,
                        connected,
                        durationHours,
                        runtimeType,
                        volumeSize,
                    }) => ({
                        id,
                        exists: exists ?? false,
                        proc,
                        instanceType,
                        gpus,
                        vcpus,
                        memoryGib,
                        gpuMemoryGib,
                        createdAt,
                        connected: connected ?? false,
                        durationHours: durationHours,
                        runtimeType,
                        loaded: true,
                        volumeSize,
                    }),
                ),
            )

            runtimes.updateMany(state.runtimes, [
                { id: RuntimeType.ComputeRuntime, changes: { loaded: true } },
                { id: RuntimeType.JupyterLabRuntime, changes: { loaded: true } },
                { id: RuntimeType.RStudioRuntime, changes: { loaded: true } },
            ])
        },
        updateRuntimeDuration: (
            state,
            {
                payload: { runtimeType, durationHours },
            }: PayloadAction<{
                runtimeType: RuntimeType
                durationHours: number
            }>,
        ) => {
            runtimes.updateOne(state.runtimes, {
                id: runtimeType,
                changes: {
                    durationHours: durationHours,
                },
            })
        },
    },
})

export const { setRuntime, setRuntimes, updateRuntimeDuration } = runtimeSlice.actions

export const {
    selectAll: selectAllRuntimes,
    selectById: selectRuntimeByType,
    selectIds: selectRuntimeTypes,
    selectTotal: selectTotalRuntimes,
    selectEntities: selectRuntimesEntities,
} = runtimes.getSelectors<RootState>((state) => state.runtime.runtimes)

export const selectRuntimeExists = createSelector([selectAllRuntimes], (runtimes) => runtimes.some((r) => r.exists))
export const selectRuntimeConnected = createSelector([selectAllRuntimes], (runtimes) =>
    runtimes.some((r) => r.connected),
)

export const selectComputeRuntimeConnected = createSelector([selectAllRuntimes], (runtimes) =>
    runtimes.some((r) => r.connected && r.runtimeType === RuntimeType.ComputeRuntime),
)

export default runtimeSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export type AssetEntry = {
    assetId: number | null
    fileSize: number
    fileName: string
    mimeType: string
    uploadStarted: boolean
    uploadProgress: number
    error: string | null
    done: boolean
}

type AssetUploadState = {
    assetEntryList: AssetEntry[]
    submitted: boolean
}

const initialState = { assetEntryList: [], submitted: false } as AssetUploadState

const assetUploadSlice = createSlice({
    name: 'assetUpload',
    initialState: initialState,
    reducers: {
        setAssetEntryList: (
            state,
            { payload: { assetEntryList } }: PayloadAction<{ assetEntryList: AssetEntry[] }>,
        ) => {
            state.assetEntryList = assetEntryList
            state.submitted = false
        },
        setAssetUploadProgress: (
            state,
            { payload: { currentSize, index } }: PayloadAction<{ currentSize: number; index: number }>,
        ) => {
            const progress = Math.ceil((currentSize / state.assetEntryList[index].fileSize) * 100)
            state.assetEntryList[index].uploadProgress = progress
            if (progress == 100) {
                state.assetEntryList[index].done = true
            }
        },
        setAssetUploadErr: (state, { payload: { err, index } }: PayloadAction<{ err: string; index: number }>) => {
            state.assetEntryList[index].error = err
            if (err) {
                state.assetEntryList[index].done = true
            }
        },
        setAssetUploadSubmitted: (state, { payload: { submitted } }: PayloadAction<{ submitted: boolean }>) => {
            state.submitted = submitted
        },
        setAssetId: (state, { payload: { assetId, index } }: PayloadAction<{ assetId: number; index: number }>) => {
            state.assetEntryList[index].assetId = assetId
        },
        setAssetUploadStarted: (
            state,
            { payload: { uploadStarted, index } }: PayloadAction<{ uploadStarted: boolean; index: number }>,
        ) => {
            state.assetEntryList[index].uploadStarted = uploadStarted
        },
        setAssetError: (state, { payload: { err, index } }: PayloadAction<{ err: string | null; index: number }>) => {
            state.assetEntryList[index].error = err
            if (err) {
                state.assetEntryList[index].done = true
            }
        },
    },
})

export const {
    setAssetEntryList,
    setAssetUploadProgress,
    setAssetUploadErr,
    setAssetUploadSubmitted,
    setAssetId,
    setAssetUploadStarted,
    setAssetError,
} = assetUploadSlice.actions

export const selectAssetEntryList = (state: RootState) => state.assetUpload.assetEntryList

export const selectAssetUploadSubmitted = (state: RootState) => state.assetUpload.submitted

export const selectAssetUploadDone = (state: RootState) => {
    if (state.assetUpload.assetEntryList.length > 0) {
        let isDone = true
        state.assetUpload.assetEntryList.forEach((s) => {
            isDone = isDone && s.done
        })
        return isDone
    }
    return false
}

export default assetUploadSlice.reducer

import { apiSlice } from '../../../app/apiSlice'
import { User } from '../../../model/model'

export type ListUsersResponse = {
    userList: User[]
}

export type ChangeUsersActivationStatusRequest = {
    userIdList: number[]
    active: boolean
}

export type ChangeUsersActivationStatusResponse = {
    userIdList: number[]
    active: boolean
}

export type CreateOrUpdateUserRequest = {
    id?: number
    firstName: string
    lastName: string
    email: string
    phone: string
    roleId: number
}

export type CreateOrUpdateUserResponse = {
    user: User
}

export const adminUserApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listUsers: builder.query<ListUsersResponse, void>({
            query: () => ({
                url: '/private/admin/user/list',
            }),
        }),
        changeUsersActivationStatus: builder.mutation<
            ChangeUsersActivationStatusResponse,
            ChangeUsersActivationStatusRequest
        >({
            query: (req) => ({
                url: '/private/admin/user/change-activation-status',
                method: 'POST',
                body: req,
            }),
        }),
        createOrUpdateUser: builder.mutation<CreateOrUpdateUserResponse, CreateOrUpdateUserRequest>({
            query: (req) => ({
                url: '/private/admin/user/create-or-update',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useListUsersQuery,
    useChangeUsersActivationStatusMutation,
    useCreateOrUpdateUserMutation,
    endpoints: { listUsers, changeUsersActivationStatus, createOrUpdateUser },
} = adminUserApiSlice

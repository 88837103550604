import { apiSlice } from '../../app/apiSlice'
import { OmicsBrowserQueryResponse } from '../omicsbrowser/queryApiSlice'

type GetSignaturesForCoPerturbedGenesRequest = {
    gene1: string
    gene2: string
    direction: string
    geneSignatureIds: number[]
    pValThreshold: number
    minAbsLogFoldChange: number
    raw: boolean
}

export const coperturbationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSignaturesForCoPerturbedGenes: builder.mutation<
            OmicsBrowserQueryResponse,
            GetSignaturesForCoPerturbedGenesRequest
        >({
            query: (req) => ({
                url: `/private/coperturbation/signatures`,
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const { useGetSignaturesForCoPerturbedGenesMutation } = coperturbationApiSlice

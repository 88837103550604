import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { ComputationResult, ExecCommand, ExecCommandAudit, ExecCommandStatus } from '../../../model/model'

type AnalysisDetailsState = {
    commandAuditList: EntityState<ExecCommandAudit, number>
    analysisId: number | null
}

const commandAuditList = createEntityAdapter<ExecCommandAudit, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.id < a.id ? 1 : -1),
})

const initialState = {
    commandAuditList: commandAuditList.getInitialState(),
    analysisId: null,
} as AnalysisDetailsState

const analysisDetailsSlice = createSlice({
    name: 'analysisDetailsHolder',
    initialState: initialState,
    reducers: {
        receivedCommand: (state, { payload: { cmd } }: PayloadAction<{ cmd: ExecCommand }>) => {
            // We might receive progress updates while navigating
            if (state.analysisId && cmd.analysisId === state.analysisId) {
                const commandAuditState = commandAuditList.getSelectors().selectEntities(state.commandAuditList)
                const audit = Object.values(commandAuditState).find((audit) => audit.execCommandId === cmd.id)
                if (audit) {
                    audit.status = cmd.status
                    audit.jobStartedAt = cmd.jobStartedAt
                    audit.jobEndedAt = cmd.jobEndedAt
                    commandAuditList.setOne(state.commandAuditList, audit)
                }
            }
        },
        receivedCommandAuditList: (
            state,
            { payload: { analysisId, cmdList } }: PayloadAction<{ analysisId: number; cmdList: ExecCommandAudit[] }>,
        ) => {
            state.analysisId = analysisId
            commandAuditList.setAll(state.commandAuditList, cmdList)
        },
    },
})

export const { receivedCommand, receivedCommandAuditList } = analysisDetailsSlice.actions

export const {
    selectAll: selectAllExecCommandAudits,
    selectIds: selectExecCommandAuditIds,
    selectById: selectExecCommandAuditById,
} = commandAuditList.getSelectors<RootState>((state) => state.analysisDetailsHolder.commandAuditList)

export type CommandAuditRow = {
    id: number
    execCommandId: number
    component: string
    status: ExecCommandStatus
    createdBy: string | undefined
    jobStartedAt: string | undefined
    jobEndedAt: string | undefined
    result?: ComputationResult
}

export const selectExecCommandAuditRows = createSelector([selectAllExecCommandAudits], (execCommands) => {
    return execCommands.map((command: ExecCommandAudit) => {
        return {
            id: command.id,
            execCommandId: command.execCommandId,
            component: command.component,
            status: command.status,
            createdBy: `${command?.user?.firstName} ${command?.user?.lastName}`,
            jobStartedAt: command?.jobStartedAt?.parseAndFormatDate(),
            jobEndedAt: command?.jobEndedAt?.parseAndFormatDate(),
            result: command?.computationResult,
        } as CommandAuditRow
    })
})

export default analysisDetailsSlice.reducer

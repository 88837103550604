import React, { useCallback, useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import { RuntimeStats, selectAllRuntimes } from './runtimeSlice'
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { DialogProps, displayRuntimeType, RuntimeType } from '../../model/model'
import CreateRuntimeDialog from './CreateRuntimeDialog'
import TerminateRuntimeDialog from './TerminateRuntimeDialog'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import dayjs from 'dayjs'
import DisplayRuntimeDialog from './DisplayRuntimeDialog'

export type RuntimeMenuProps = DialogProps & {
    anchorEl: HTMLElement | null
}

export default function RuntimeMenu(props: RuntimeMenuProps) {
    const { t } = useTranslation()
    const runtimes = useAppSelector(selectAllRuntimes)

    const [selectedRuntimeType, setSelectedRuntimeType] = useState<RuntimeType | undefined>()
    const [selectedRuntime, setSelectedRuntime] = useState<RuntimeStats | undefined>()

    const [openCreateDialog, setOpenCreateDialog] = useState(false)
    const [openDisplayDialog, setOpenDisplayDialog] = useState(false)
    const [openTerminateDialog, setOpenTerminateDialog] = useState(false)

    const makePrimaryText = useCallback((r: RuntimeStats) => {
        const rt = displayRuntimeType(r.runtimeType!)
        if (r.instanceType === null) {
            return rt
        }
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {rt}&nbsp;
                <Typography variant={'body2'} sx={{ fontStyle: 'italic' }}>
                    ({r.instanceType})
                </Typography>
            </Box>
        )
    }, [])

    const makeSecondaryText = useCallback((r: RuntimeStats) => {
        if (!r.exists || r.createdAt === null) {
            return null
        }
        return (
            <>
                Running for:&nbsp;
                <span style={{ fontStyle: 'italic' }}>{dayjs(r.createdAt.toNumber()).fromNow(true)}</span>
            </>
        )
    }, [])

    return (
        <>
            <Menu
                open={props.openDialog}
                onClose={() => {
                    setSelectedRuntimeType(undefined)
                    props.handleCloseDialog()
                }}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    top: 5,
                }}
                slotProps={{
                    paper: {
                        sx: { maxWidth: 500, minWidth: 300 },
                    },
                }}
            >
                {runtimes.map((r) => (
                    <MenuItem
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            cursor: 'default',
                        }}
                        key={r.runtimeType}
                        disableRipple
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <ListItemIcon>
                            {r.exists ? (
                                <Tooltip title={r.connected ? t('connected') : t('active')} placement='right-end'>
                                    <LinkIcon color={r.connected ? 'primary' : 'secondary'} />
                                </Tooltip>
                            ) : (
                                <Tooltip title={t('terminated')} placement='right-end'>
                                    <LinkOffIcon color='inherit' />
                                </Tooltip>
                            )}
                        </ListItemIcon>
                        <ListItemText
                            primary={makePrimaryText(r)}
                            secondary={makeSecondaryText(r)}
                            sx={{
                                mr: 2,
                                cursor: r.exists ? 'pointer' : 'default',
                            }}
                            onClick={(e) => {
                                if (!r.exists) {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    return
                                }

                                setSelectedRuntime(r)
                                setOpenDisplayDialog(true)
                            }}
                        />

                        {r.exists ? (
                            <Tooltip
                                title={
                                    !r.connected
                                        ? 'The runtime cannot be terminated while it is being provisioned.'
                                        : ''
                                }
                                arrow
                            >
                                <Stack direction='row' alignItems='center' gap={1}>
                                    <IconButton
                                        color='error'
                                        aria-label='terminate-runtime'
                                        onClick={() => {
                                            setSelectedRuntimeType(r.runtimeType)
                                            setOpenTerminateDialog(true)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            </Tooltip>
                        ) : (
                            <IconButton
                                color='primary'
                                aria-label='create-runtime'
                                onClick={() => {
                                    setSelectedRuntimeType(r.runtimeType)
                                    setOpenCreateDialog(true)
                                }}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        )}
                    </MenuItem>
                ))}
            </Menu>

            <CreateRuntimeDialog
                runtimeType={selectedRuntimeType!}
                openDialog={openCreateDialog}
                handleCloseDialog={() => {
                    setOpenCreateDialog(false)
                }}
            />

            <DisplayRuntimeDialog
                runtime={selectedRuntime}
                openDialog={openDisplayDialog}
                handleCloseDialog={() => {
                    setOpenDisplayDialog(false)
                }}
            />

            <TerminateRuntimeDialog
                runtimeType={selectedRuntimeType!}
                openDialog={openTerminateDialog}
                handleCloseDialog={() => {
                    setOpenTerminateDialog(false)
                }}
                onDone={() => {
                    setSelectedRuntimeType(undefined)
                }}
            />
        </>
    )
}

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    Link,
    OutlinedInput,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DialogProps, RStudioRuntimeDetails, RuntimeType } from '../../model/model'
import { getRuntimeTypeLogo } from './CreateRuntimeDialog'
import useStoredRuntimeUserData from './hooks/useStoredRuntimeUserData'
import { RuntimeStats, selectRuntimeByType } from './runtimeSlice'
import dayjs from 'dayjs'
import { useExtendRuntimeDurationMutation } from './runtimeApiSlice'
import { receivedAppMessage } from '../dashboard/appMessageSlice'

export type DisplayRuntimeDialogProps = DialogProps & {
    runtime?: RuntimeStats
}

export default function DisplayRuntimeDialog(props: DisplayRuntimeDialogProps) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const runtime = useAppSelector((state) =>
        selectRuntimeByType(state, props.runtime?.runtimeType ?? RuntimeType.ComputeRuntime),
    )
    const { runtimeUserData, loadRuntimeUserData } = useStoredRuntimeUserData()

    const [showPassword, setShowPassword] = useState(false)
    const [duration, setDuration] = useState('')
    const [durationError, setDurationError] = useState(false)

    const [extendRuntimeDuration, { isLoading }] = useExtendRuntimeDurationMutation()

    useEffect(() => {
        if (props.runtime) {
            loadRuntimeUserData(props.runtime.runtimeType)
        }
    }, [props.runtime])

    const provisioningTime = useMemo(() => {
        if (props.runtime?.runtimeType === RuntimeType.ComputeRuntime) {
            return 10
        }
        return 5
    }, [props.runtime?.runtimeType])

    const handleDurationChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDuration(event.target.value)
    }

    const submitExtend = async () => {
        if (!runtime) {
            return
        }
        const durationHours = parseInt(duration)
        if (isNaN(durationHours)) {
            setDurationError(true)
            return
        }
        await extendRuntimeDuration({
            runtimeId: runtime.id,
            durationHours: durationHours,
        }).unwrap()

        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'Session duration successfully extended.',
            }),
        )
    }

    const close = () => {
        props.handleCloseDialog()
        setDurationError(false)
    }

    return (
        <Dialog
            fullWidth
            open={props.openDialog}
            onClose={close}
            aria-labelledby='display-runtime-info-dialog-title'
            aria-describedby='display-runtime-info-dialog-description'
        >
            <DialogTitle
                id='display-runtime-info-dialog-title'
                sx={{ borderBottom: '1px solid #DDD', minWidth: '500px' }}
            >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant={'h6'}>{t('displayRuntimeInfoTitle')}</Typography>
                    {props.runtime && getRuntimeTypeLogo(props.runtime.runtimeType)}
                </Box>
            </DialogTitle>
            <DialogContent sx={{ my: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant={'h6'} sx={{ mr: 1 }}>
                        Instance:
                    </Typography>
                    <Typography>{props.runtime?.instanceType}</Typography>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', p: 1 }}>
                    <Box>
                        <Typography variant={'body2'}>vCPU</Typography>
                        <Typography variant={'h6'}>{props.runtime?.vcpus}</Typography>
                    </Box>
                    <Divider flexItem orientation={'vertical'} />
                    <Box>
                        <Typography variant={'body2'}>Memory</Typography>
                        <Typography variant={'h6'}>{props.runtime?.memoryGib} GB</Typography>
                    </Box>
                    <Divider flexItem orientation={'vertical'} />
                    <Box>
                        <Typography variant={'body2'}>GPUs</Typography>
                        <Typography variant={'h6'}>{props.runtime?.gpus}</Typography>
                    </Box>
                    <Divider flexItem orientation={'vertical'} />
                    <Box>
                        <Typography variant={'body2'}>GPU Mem</Typography>
                        <Typography variant={'h6'}>{props.runtime?.gpuMemoryGib} GB</Typography>
                    </Box>
                    <Divider flexItem orientation={'vertical'} />
                    <Box>
                        <Typography variant={'body2'}>Storage</Typography>
                        <Typography variant={'h6'}>{props.runtime?.volumeSize} GB</Typography>
                    </Box>
                </Box>
                {props.runtime && runtimeUserData && (
                    <>
                        <Divider flexItem sx={{ my: 2 }} />
                        {!runtime.connected && (
                            <Stack gap={0.5} flexDirection='column' justifyItems='center' alignItems='center'>
                                <LinearProgress sx={{ width: '100%' }} />
                                <Typography variant='subtitle1'>
                                    {t('runtimeIsWakingUp', { m: provisioningTime })}
                                </Typography>
                            </Stack>
                        )}
                        {runtime.connected && props.runtime.runtimeType === RuntimeType.JupyterLabRuntime && (
                            <Stack direction='column' gap={2}>
                                <Stack direction='column'>
                                    <FormLabel>JupyterLab link:</FormLabel>
                                    <Link
                                        href={`https://${runtimeUserData.url}`}
                                        underline='hover'
                                        target={'_blank'}
                                        sx={{ mt: 1 }}
                                    >
                                        {runtimeUserData.url}
                                    </Link>
                                </Stack>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel htmlFor='outlined-adornment-password'>{t('password')}</InputLabel>
                                    <OutlinedInput
                                        id='outlined-adornment-password'
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}
                                                    onMouseDown={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                    onMouseUp={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                    edge='end'
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password'
                                        readOnly
                                        value={runtimeUserData.password}
                                    />
                                </FormControl>
                            </Stack>
                        )}
                        {runtime.connected && props.runtime.runtimeType === RuntimeType.RStudioRuntime && (
                            <Stack direction='column' gap={2}>
                                <Stack direction='column'>
                                    <FormLabel>RStudio link:</FormLabel>
                                    <Link
                                        href={`https://${runtimeUserData.url}`}
                                        underline='hover'
                                        target={'_blank'}
                                        sx={{ mt: 1 }}
                                    >
                                        {runtimeUserData.url}
                                    </Link>
                                </Stack>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel htmlFor='outlined-adornment-password'>{t('username')}</InputLabel>
                                    <OutlinedInput
                                        id='outlined-username'
                                        type='text'
                                        label='Username'
                                        readOnly
                                        value={(runtimeUserData as RStudioRuntimeDetails).username}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel htmlFor='outlined-adornment-password'>{t('password')}</InputLabel>
                                    <OutlinedInput
                                        id='outlined-adornment-password'
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}
                                                    onMouseDown={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                    onMouseUp={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                    edge='end'
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password'
                                        readOnly
                                        value={runtimeUserData.password}
                                    />
                                </FormControl>
                            </Stack>
                        )}
                    </>
                )}
                {runtime && runtime.createdAt && runtime.durationHours && (
                    <>
                        <Divider flexItem sx={{ my: 2 }} />
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Stack sx={{ width: '50%' }}>
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Typography sx={{ mr: 0.5, fontWeight: 'bold' }}>Running for</Typography>
                                    <Typography sx={{ fontStyle: 'italic', mr: 0.5, fontWeight: 'bold' }}>
                                        {dayjs(runtime.createdAt.toNumber()).fromNow(true)}
                                    </Typography>
                                </Box>
                                <Typography sx={{ fontStyle: 'italic' }}>
                                    Session duration: {runtime.durationHours} hour
                                    {runtime.durationHours != 1 ? 's' : ''}
                                </Typography>
                            </Stack>
                            <Box sx={{ width: '50%' }}>
                                <Tooltip title={'Extend the duration of you runtime to up to 720h.'} placement={'top'}>
                                    <Typography
                                        sx={{ fontWeight: 'bold', mb: 1, alignItems: 'center', display: 'flex' }}
                                    >
                                        Need more time?
                                    </Typography>
                                </Tooltip>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        type={'number'}
                                        placeholder={'Extend duration (hours)'}
                                        size={'small'}
                                        sx={{ width: '200px', mr: 0.5 }}
                                        value={duration}
                                        onChange={handleDurationChange}
                                        error={durationError}
                                    />
                                    {isLoading ? (
                                        <Button variant={'outlined'}>
                                            <CircularProgress size={20} />
                                        </Button>
                                    ) : (
                                        <Button variant={'outlined'} onClick={submitExtend}>
                                            Extend
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}

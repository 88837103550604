import { Alert, Box, CircularProgress, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../app/hooks'
import JupyterLabLogo from '../../assets/images/JupyterLab_logo.png'
import RStudioLogo from '../../assets/images/RStudio_logo.png'
import StandardComputeLogo from '../../assets/images/StandardCompute_logo.png'
import '../../extensions/string.ext'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { DialogProps, MaxRuntimeDurationHours, RuntimeType } from '../../model/model'
import { numberInInterval } from '../../utils/misc'
import useStoredRuntimeUserData from './hooks/useStoredRuntimeUserData'
import { useCreateRuntimeMutation, useGetRuntimeOptionsQuery } from './runtimeApiSlice'
import { setRuntime } from './runtimeSlice'

export type CreateRuntimeDialogProps = DialogProps & {
    runtimeType: RuntimeType
}

export default function CreateRuntimeDialog(props: CreateRuntimeDialogProps) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { setRuntimeUserData } = useStoredRuntimeUserData()
    const { data: getRuntimeOptionsResponse } = useGetRuntimeOptionsQuery()
    const [instanceType, setInstanceType] = useState<number | null>(null)
    const [instanceTypeError, setInstanceTypeError] = useState(false)
    const [durationHours, setDurationHours] = useState('')
    const [durationHoursError, setDurationHoursError] = useState(false)
    const cpuRuntimeOptions = getRuntimeOptionsResponse?.filter((ro) => ro.proc == 'CPU')
    const gpuRuntimeOptions = getRuntimeOptionsResponse?.filter((ro) => ro.proc == 'GPU')
    const [createRuntime, { isLoading: isCreating }] = useCreateRuntimeMutation()
    const [arch, setArch] = useState('cpu')

    const handleArchChange = (event: React.MouseEvent<HTMLElement>, newArch: string) => {
        if (newArch) {
            setArch(newArch)
        }
    }

    const handleDurationHoursChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDurationHours(event.target.value)
    }

    const validate = () => {
        resetErrors()
        let valid = true
        if (!instanceType) {
            setInstanceTypeError(true)
            valid = false
        }
        if (!durationHours) {
            setDurationHoursError(true)
            valid = false
        }
        if (!numberInInterval(durationHours, 1, MaxRuntimeDurationHours)) {
            setDurationHoursError(true)
            valid = false
        }
        return valid
    }

    const resetErrors = () => {
        setInstanceTypeError(false)
        setDurationHoursError(false)
    }

    const submit = async () => {
        if (!validate()) {
            return
        }
        const resp = await createRuntime({
            runtimeOptionId: instanceType ?? 0,
            durationHours: parseInt(durationHours == '' ? '0' : durationHours),
            runtimeType: props.runtimeType,
        }).unwrap()

        setRuntimeUserData(resp.runtimeType, resp.userData ?? {})
        dispatch(
            setRuntime({
                id: resp.id,
                exists: resp.exists,
                proc: resp.proc,
                instanceType: resp.instanceType,
                gpus: resp.gpus,
                vcpus: resp.vcpus,
                memoryGib: resp.memoryGib,
                gpuMemoryGib: resp.gpuMemoryGib,
                createdAt: resp.createdAt ?? '',
                durationHours: resp.durationHours,
                connected: false,
                runtimeType: props.runtimeType,
                volumeSize: resp.volumeSize,
            }),
        )
        close()
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(() => {
        resetErrors()
        setInstanceType(null)
        setDurationHours('')
        props.handleCloseDialog()
    })

    return (
        <Dialog
            open={props.openDialog}
            onClose={close}
            aria-labelledby='create-runtime-alert-dialog-title'
            aria-describedby='create-runtime-alert-dialog-description'
        >
            <DialogTitle
                id='create-runtime-alert-dialog-title'
                sx={{ borderBottom: '1px solid #DDD', minWidth: '600px' }}
            >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant={'h6'}>{t('createRuntimeConfirmTitle')}</Typography>
                    {getRuntimeTypeLogo(props.runtimeType)}
                </Box>
            </DialogTitle>
            <DialogContent>
                {props.runtimeType === RuntimeType.ComputeRuntime && (
                    <Alert severity='info' sx={{ mt: 2 }}>
                        {t('createRuntimeSizeRecommendation')}
                    </Alert>
                )}
                <ToggleButtonGroup
                    color='primary'
                    value={arch}
                    exclusive
                    onChange={handleArchChange}
                    sx={{ width: '100%', mt: 2 }}
                >
                    <ToggleButton value='cpu' sx={{ width: '100%' }}>
                        Dedicated CPU instance
                    </ToggleButton>
                    <ToggleButton value='gpu' sx={{ width: '100%' }} disabled={gpuRuntimeOptions?.length == 0}>
                        Dedicated GPU instance
                    </ToggleButton>
                </ToggleButtonGroup>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    Available compute configurations (
                    {arch == 'cpu' ? cpuRuntimeOptions?.length : gpuRuntimeOptions?.length})
                </Typography>
                {instanceTypeError && (
                    <Alert severity={'error'} sx={{ mb: 1 }}>
                        Please select an instance type.
                    </Alert>
                )}
                {arch == 'cpu' && (
                    <Box sx={{ height: '300px', overflow: 'scroll' }}>
                        {cpuRuntimeOptions?.map((ro, idx) => {
                            return (
                                <Box
                                    onClick={() => {
                                        setInstanceType(ro.id)
                                    }}
                                    key={`runtime-cpu-${idx}`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        border: '1px solid #DDD',
                                        borderRadius: '5px',
                                        backgroundColor: instanceType === ro.id ? '#287473' : '#FAFAFA', // Apply hover background when selected
                                        p: 1.5,
                                        pr: 2,
                                        pl: 2,
                                        mb: 1,
                                        ':hover': {
                                            backgroundColor: '#2a9d8f',
                                            '& > .MuiTypography-root, & > .MuiBox-root > .MuiTypography-root': {
                                                color: '#FFF',
                                            },
                                            cursor: 'pointer',
                                        },
                                        '& > .MuiTypography-root, & > .MuiBox-root > .MuiTypography-root': {
                                            color: instanceType === ro.id ? '#FFF' : '#444', // Change text color when selected
                                        },
                                    }}
                                >
                                    <Box>
                                        <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
                                            {ro.instanceType}
                                        </Typography>
                                        <Typography variant={'body2'} sx={{ fontStyle: 'italic', mt: 0.5 }}>
                                            ${ro.pricePerHourUsd}/hour
                                        </Typography>
                                    </Box>
                                    <Typography variant={'body2'} fontStyle='italic'>
                                        {ro.vcpus} vCPU, {ro.memoryGib} GiB RAM, {ro.volumeSize} GB
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                )}
                {arch == 'gpu' && (
                    <>
                        {gpuRuntimeOptions?.map((ro, idx) => {
                            return (
                                <Box
                                    onClick={() => {
                                        setInstanceType(ro.id)
                                    }}
                                    key={`runtime-gpu-${idx}`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        border: '1px solid #DDD',
                                        borderRadius: '5px',
                                        backgroundColor: instanceType === ro.id ? '#287473' : '#FAFAFA', // Apply hover background when selected
                                        p: 1,
                                        pr: 2,
                                        pl: 2,
                                        mb: 1,
                                        ':hover': {
                                            backgroundColor: '#287473',
                                            '& > .MuiTypography-root, & > .MuiBox-root > .MuiTypography-root': {
                                                color: '#FFF',
                                            },
                                            cursor: 'pointer',
                                        },
                                        '& > .MuiTypography-root, & > .MuiBox-root > .MuiTypography-root': {
                                            color: instanceType === ro.id ? '#FFF' : '#444', // Change text color when selected
                                        },
                                    }}
                                >
                                    <Box>
                                        <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
                                            {ro.instanceType}
                                        </Typography>
                                        <Typography variant={'body2'} sx={{ fontStyle: 'italic', mt: 0.5 }}>
                                            ${ro.pricePerHourUsd}/hour
                                        </Typography>
                                    </Box>
                                    <Typography variant={'body2'} fontStyle='italic'>
                                        {ro.vcpus} vCPU, {ro.memoryGib} GiB, {ro.gpus} GPUs, {ro.gpuMemory} GiB,{' '}
                                        {ro.volumeSize} GB
                                    </Typography>
                                </Box>
                            )
                        })}
                    </>
                )}
                <>
                    <Divider sx={{ mt: 2 }} />
                    <TextField
                        label={'Session duration (hours)'}
                        type={'number'}
                        value={durationHours}
                        onChange={handleDurationHoursChange}
                        helperText={`Input a number between 1 and ${MaxRuntimeDurationHours}. The instance will be automatically terminated after the specified time has ellapsed. You can extend the session duration while the runtime is running.`}
                        error={durationHoursError}
                        sx={{ mt: 2 }}
                        required
                        fullWidth
                    />
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color='error'>
                    {t('close')}
                </Button>
                {!isCreating ? (
                    <Button onClick={submit} autoFocus>
                        {t('create')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export const getRuntimeTypeLogo = (runtimeType: RuntimeType) => {
    switch (runtimeType) {
        case RuntimeType.ComputeRuntime:
            return <Box component='img' src={StandardComputeLogo} sx={{ maxHeight: '24px', maxWidth: '300px' }} />
        case RuntimeType.JupyterLabRuntime:
            return <Box component='img' src={JupyterLabLogo} sx={{ maxHeight: '24px', maxWidth: '300px' }} />
        case RuntimeType.RStudioRuntime:
            return <Box component='img' src={RStudioLogo} sx={{ maxHeight: '24px', maxWidth: '300px' }} />
    }
}

import { Logout } from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CodeIcon from '@mui/icons-material/Code'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { SxProps } from '@mui/system'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import RestrictedComponent from '../auth/RestrictedComponent'
import { useLogout } from '../auth/authSlice'
import UserAvatar from '../user/UserAvatar'

interface AccountMenuParams {
    sx?: SxProps
}

function AccountMenu({ sx }: AccountMenuParams) {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', ...sx }}>
                <Tooltip title='Account settings' arrow>
                    <IconButton
                        onClick={handleClick}
                        size='small'
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <UserAvatar />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                    dense: true,
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <RestrictedComponent
                    requiredPermissions={{
                        manageUsers: true,
                        manageMetadata: true,
                        manageRoles: true,
                        manageOrgSettings: true,
                        manageGuests: true,
                    }}
                    any
                >
                    <MenuItem
                        onClick={() => {
                            navigate('/app/admin')
                        }}
                    >
                        <SettingsIcon fontSize='small' sx={{ mr: 2 }} /> {t('administration')}
                    </MenuItem>
                    <Divider />
                </RestrictedComponent>
                <RestrictedComponent
                    requiredPermissions={{
                        editProfile: true,
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            navigate('/account/info/user')
                        }}
                    >
                        <PersonIcon fontSize='small' sx={{ mr: 2 }} /> {t('myProfile')}
                    </MenuItem>
                </RestrictedComponent>
                <RestrictedComponent
                    requiredPermissions={{
                        viewBillingInfo: true,
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            navigate('/account/info/billing')
                        }}
                    >
                        <AttachMoneyIcon fontSize='small' sx={{ mr: 2 }} /> {t('billing')}
                    </MenuItem>
                </RestrictedComponent>
                <RestrictedComponent
                    requiredPermissions={{
                        manageApiKeys: true,
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            navigate('/account/info/apikey')
                        }}
                    >
                        <CodeIcon fontSize='small' sx={{ mr: 2 }} /> {t('apiKeys')}
                    </MenuItem>
                </RestrictedComponent>
                <RestrictedComponent
                    requiredPermissions={{
                        manageUsers: true,
                        manageMetadata: true,
                        manageRoles: true,
                        manageOrgSettings: true,
                        manageGuests: true,
                        editProfile: true,
                        viewBillingInfo: true,
                        manageApiKeys: true,
                    }}
                    any
                >
                    <Divider />
                </RestrictedComponent>
                <LogoutMenuItem />
            </Menu>
        </React.Fragment>
    )
}

function LogoutMenuItem() {
    const { t } = useTranslation()
    const logout = useLogout()
    return (
        <MenuItem onClick={logout}>
            <Logout fontSize='small' sx={{ mr: 2 }} />
            {t('logout')}
        </MenuItem>
    )
}

export default AccountMenu

import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { Analysis, Project, ProjectAccess, ProjectAccessType, ProjectVisibility } from '../../model/model'
import { selectOrganization, selectUserId, useUserPermitted } from '../auth/authSlice'

const analyses = createEntityAdapter<Analysis, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

const projectAccesses = createEntityAdapter<ProjectAccess, string>({
    selectId: (x) => `${x.projectId}-${x.user.id}`,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type WorkbenchState = {
    project: Project | null
    projectAccesses: EntityState<ProjectAccess, string>
    analyses: EntityState<Analysis, number>
    loading: boolean
}

const initialState = {
    project: null,
    projectAccesses: projectAccesses.getInitialState(),
    analyses: analyses.getInitialState(),
    loading: true,
} as WorkbenchState

const workbenchSlice = createSlice({
    name: 'workbenchStateHolder',
    initialState: initialState,
    reducers: {
        receivedWorkbenchProject: (state, { payload: { project } }: PayloadAction<{ project: Project }>) => {
            state.project = project
        },
        receivedWorkbench: (
            state,
            {
                payload: { project, projectAccessList, analysisList },
            }: PayloadAction<{
                project: Project
                projectAccessList: ProjectAccess[]
                analysisList: Analysis[]
            }>,
        ) => {
            state.project = project
            projectAccesses.setAll(state.projectAccesses, projectAccessList)
            analyses.setAll(state.analyses, analysisList)
            state.loading = false
        },
        receivedWorkbenchAnalyses: (
            state,
            { payload: { analysisList } }: PayloadAction<{ analysisList: Analysis[] }>,
        ) => {
            analyses.setAll(state.analyses, analysisList)
        },
        receivedNewAnalysis: (state, { payload: { analysis } }: PayloadAction<{ analysis: Analysis }>) => {
            analyses.addOne(state.analyses, analysis)
        },
        receivedDeletedAnalysesIds: (
            state,
            { payload: { analysisIdList } }: PayloadAction<{ analysisIdList: number[] }>,
        ) => {
            analyses.removeMany(state.analyses, analysisIdList)
        },
        receivedAnalysisStatusUpdate: (
            state,
            { payload: { analysisIdList, status } }: PayloadAction<{ analysisIdList: number[]; status: string }>,
        ) => {
            analyses.updateMany(
                state.analyses,
                analysisIdList.map((id) => {
                    return {
                        id: id,
                        changes: {
                            status: status,
                        },
                    }
                }),
            )
        },
        clearWorkbench: (state) => {
            Object.assign(state, initialState)
        },
        receivedUpdatedAnalysisTitle: (
            state,
            { payload: { title, analysisId } }: PayloadAction<{ title: string; analysisId: number }>,
        ) => {
            analyses.updateOne(state.analyses, {
                id: analysisId,
                changes: {
                    title: title,
                },
            })
        },
        receivedProjectAccess: (
            state,
            { payload: { projectAccess } }: PayloadAction<{ projectAccess: ProjectAccess }>,
        ) => {
            projectAccesses.setOne(state.projectAccesses, projectAccess)
        },
        receivedDeletedProjectAccess: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
            projectAccesses.removeOne(state.projectAccesses, id)
        },
    },
})

export const {
    receivedWorkbenchProject,
    receivedWorkbench,
    receivedWorkbenchAnalyses,
    receivedNewAnalysis,
    receivedDeletedAnalysesIds,
    receivedAnalysisStatusUpdate,
    clearWorkbench,
    receivedUpdatedAnalysisTitle,
    receivedProjectAccess,
    receivedDeletedProjectAccess,
} = workbenchSlice.actions

export const selectWorkbenchProject = (state: RootState) => state.workbenchStateHolder.project
export const selectWorkbenchProjectId = (state: RootState) => state.workbenchStateHolder.project?.id
export const selectWorkbenchLoading = (state: RootState) => state.workbenchStateHolder.loading

export const { selectAll: selectAllWorkbenchProjectAccesses, selectById: selectWorkbenchProjectAccessById } =
    projectAccesses.getSelectors<RootState>((state) => state.workbenchStateHolder.projectAccesses)

export const { selectAll: selectAllWorkbenchAnalyses, selectById: selectWorkbenchAnalysisById } =
    analyses.getSelectors<RootState>((state) => state.workbenchStateHolder.analyses)
export const selectIds = (state: RootState, ids: EntityId[]) => ids

export const selectWorkbenchAnalysesByIds = createSelector(
    [selectAllWorkbenchAnalyses, selectIds],
    (analysisList, ids) => {
        return analysisList.filter((a) => ids.includes(a.id))
    },
)

export const selectUserWorkbenchProjectAccess = (state: RootState) => {
    const user = selectUserId(state)
    return selectAllWorkbenchProjectAccesses(state).find((pa) => pa.user?.id === user)
}

export const useUserWorkbenchProjectAccess = (...any: ProjectAccessType[]): boolean => {
    // Project creator always allowed.
    const workbenchProject = useAppSelector(selectWorkbenchProject)
    const userAccess = useAppSelector(selectUserWorkbenchProjectAccess)
    const implicitAdmin = useUserPermitted({ implicitAdminOnProjects: true })
    if (workbenchProject?.user.id === userAccess?.user.id) {
        return true
    }

    // Implicit admin has access to all projects.
    if (implicitAdmin) {
        return true
    }

    // If project is public and if it's enough.
    if (
        workbenchProject?.organizationVisibility === ProjectVisibility.Public &&
        any.includes(ProjectAccessType.Viewer)
    ) {
        return true
    }

    for (const accessType of any) {
        if (userAccess?.accessType === accessType) {
            return true
        }
    }

    return false
}

export const useUserProjectSameOrg = (): boolean => {
    const workbenchProject = useAppSelector(selectWorkbenchProject)
    const userOrg = useAppSelector(selectOrganization)

    return workbenchProject?.organization?.id === userOrg?.id
}

export const selectWorkbenchAnalysisRows = createSelector([selectAllWorkbenchAnalyses], (analysisList) => {
    return analysisList.map((analysis: Analysis) => {
        return {
            id: analysis.id,
            title: analysis.title,
            workflow: analysis.analysisWorkflow,
            status: analysis.status,
            createdAt: analysis.createdAt.parseAndFormatDate(),
            createdBy: `${analysis.user.firstName} ${analysis.user.lastName}`,
            published: analysis.published,
            collection: analysis.collection?.name,
            exportedAsCellAtlas: analysis.exportedAsCellAtlas,
        }
    })
})

export const selectWorkbenchProjectTitle = (state: RootState) => {
    return state.workbenchStateHolder.project?.title
}

export default workbenchSlice.reducer

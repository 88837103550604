import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useMemo } from 'react'
import { ReadmeMentionNotificationProps } from '../../../model/model'
import NotificationContent, { NotificationParams } from './NotificationContent'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { Box, Typography } from '@mui/material'

export default function ReadmeMention({ notification, markAsRead }: NotificationParams) {
    const navigate = useNavigate()

    const properties = useMemo((): ReadmeMentionNotificationProps => {
        return notification.properties as unknown as ReadmeMentionNotificationProps
    }, [notification])

    return (
        <NotificationContent
            notification={notification}
            onClick={() => {
                if (!notification.read) {
                    markAsRead(notification.id)
                }
                navigate(`/app/workbench/${properties.projectId}/readme`)
            }}
            icon={<AlternateEmailIcon />}
            title={<>New Mention</>}
        >
            <Box>
                <Typography variant={'body2'}>
                    You were mentioned in project <b>{properties.projectTitle}</b>
                </Typography>
            </Box>
        </NotificationContent>
    )
}

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Analysis, GeneExpressionTile, LightExecCommand } from '../../../model/model'

const tiles = createEntityAdapter<GeneExpressionTile, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type AnalysisState = {
    analysis: Analysis | null
    runningComponents: LightExecCommand[]
    tiles: EntityState<GeneExpressionTile, number>
}

const initialState = {
    analysis: null,
    runningComponents: [],
    tiles: tiles.getInitialState(),
} as AnalysisState

const analysisSlice = createSlice({
    name: 'analysisHolder',
    initialState: initialState,
    reducers: {
        receivedAnalysis: (state, { payload: { analysis } }: PayloadAction<{ analysis: Analysis }>) => {
            state.analysis = analysis
        },
        receivedAddRunningCommand: (
            state,
            { payload: { lightExecCommand } }: PayloadAction<{ lightExecCommand: LightExecCommand }>,
        ) => {
            if (state.analysis?.id == lightExecCommand.analysisId) {
                state.runningComponents.push(lightExecCommand)
            }
        },
        receivedRemoveRunningCommand: (
            state,
            { payload: { analysisId, execCommandId } }: PayloadAction<{ analysisId: number; execCommandId: number }>,
        ) => {
            if (state.analysis?.id == analysisId) {
                state.runningComponents = state.runningComponents.filter((c) => c.id != execCommandId)
            }
        },
        receivedAddRunningCommandList: (
            state,
            {
                payload: { analysisId, components },
            }: PayloadAction<{ analysisId: number; components: LightExecCommand[] }>,
        ) => {
            if (state.analysis?.id == analysisId) {
                state.runningComponents = components
            }
        },
        receivedTileList: (state, { payload: { tileList } }: PayloadAction<{ tileList: GeneExpressionTile[] }>) => {
            tiles.setAll(state.tiles, tileList)
        },
        receivedTile: (state, { payload: { tile } }: PayloadAction<{ tile: GeneExpressionTile }>) => {
            tiles.setOne(state.tiles, tile)
        },
        receivedDeleteGeneExpressionTile: (state, { payload: { tileId } }: PayloadAction<{ tileId: number }>) => {
            tiles.removeOne(state.tiles, tileId)
        },
    },
})

export const {
    receivedAnalysis,
    receivedAddRunningCommand,
    receivedRemoveRunningCommand,
    receivedAddRunningCommandList,
    receivedTileList,
    receivedTile,
    receivedDeleteGeneExpressionTile,
} = analysisSlice.actions

export const selectAnalysisId = (state: RootState) => state.analysisHolder.analysis?.id
export const selectAnalysisTitle = (state: RootState) => state.analysisHolder.analysis?.title

export const selectAnalysisWorkflow = (state: RootState) => state.analysisHolder.analysis?.analysisWorkflow
export const selectAnalysisSource = (state: RootState) => state.analysisHolder.analysis?.source

export const selectAnalysisCollection = (state: RootState) => state.analysisHolder.analysis?.collection

export const selectAnalysisAnyCommandRunning = (state: RootState) => {
    return Object.keys(state.analysisHolder.runningComponents).length > 0
}

export const selectRunningCommands = (state: RootState) => {
    return state.analysisHolder.runningComponents
}

export const selectAnalysisNormalizedSamples = (state: RootState) => {
    return state.analysisHolder.analysis?.normalizedSamples
}

export const { selectAll: selectAllTiles, selectById: selectTileById } = tiles.getSelectors<RootState>(
    (state) => state.analysisHolder.tiles,
)

export default analysisSlice.reducer

import { ResponseMessage } from '../model/webSocketCommands'
import { AppDispatch } from './store'

export type Selector<T> = (message: ResponseMessage) => T | undefined | null

export type Handler<T> = (payload: T, dispatch: AppDispatch) => void

export interface IWSSlice {
    injectHandlers(handlers: Handler<ResponseMessage>[]): void
    injectSelectHandlers<T>(select: Selector<T>, handlers: Handler<T>[]): void
}

export type Triggerer = (message: ResponseMessage, dispatch: AppDispatch) => void

export interface IInternalWSSlice extends IWSSlice {
    getTriggerer(): Triggerer
}

class WSSlice implements IInternalWSSlice {
    private handlers: Handler<ResponseMessage>[] = []

    injectHandlers(handlers: Handler<ResponseMessage>[]): void {
        this.handlers.push(...handlers)
    }

    // Transform selector handlers into simple handlers.
    injectSelectHandlers<T>(select: Selector<T>, handlers: Handler<T>[]): void {
        this.handlers.push(
            ...handlers.map(
                (h) =>
                    ((message, dispatch) => {
                        const payload = select(message)
                        if (payload) {
                            h(payload, dispatch)
                        }
                    }) as Handler<ResponseMessage>,
            ),
        )
    }

    getTriggerer(): Triggerer {
        return (message: ResponseMessage, dispatch: AppDispatch) => this.handlers.forEach((h) => h(message, dispatch))
    }
}

export const wsSlice: IWSSlice = new WSSlice()

import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../app/hooks'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { DialogProps, RuntimeType, runtimeTypeHasData } from '../../model/model'
import { getRuntimeTypeLogo } from './CreateRuntimeDialog'
import useStoredRuntimeUserData from './hooks/useStoredRuntimeUserData'
import { useCanTerminateRuntimeMutation, useTerminateRuntimeMutation } from './runtimeApiSlice'
import { setRuntime } from './runtimeSlice'

export type TerminateRuntimeDialogProps = DialogProps & {
    runtimeType: RuntimeType
}

export default function TerminateRuntimeDialog(props: TerminateRuntimeDialogProps) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [terminateRuntime, { isLoading: isTerminating }] = useTerminateRuntimeMutation()
    const [canTerminateRuntime, { isLoading: isCheckingTermination }] = useCanTerminateRuntimeMutation()

    const { clearRuntimeUserData } = useStoredRuntimeUserData()

    const [canTerminate, setCanTerminate] = useState(props.runtimeType === RuntimeType.ComputeRuntime)

    const submit = () => {
        clearRuntimeUserData(props.runtimeType)
        terminateRuntime({ runtimeType: props.runtimeType })
            .unwrap()
            .then(() => {
                dispatch(
                    setRuntime({
                        id: 0,
                        exists: false,
                        proc: null,
                        instanceType: null,
                        gpus: null,
                        vcpus: null,
                        memoryGib: null,
                        gpuMemoryGib: null,
                        createdAt: null,
                        connected: false,
                        durationHours: null,
                        runtimeType: props.runtimeType,
                        volumeSize: null,
                    }),
                )
                props.handleCloseDialog()
            })
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(props.handleCloseDialog)

    useEffect(() => {
        if (props.openDialog && props.runtimeType !== RuntimeType.ComputeRuntime) {
            canTerminateRuntime({ runtimeType: props.runtimeType })
                .unwrap()
                .then((result) => {
                    setCanTerminate(result)
                })
        } else {
            setCanTerminate(true)
        }
    }, [props.openDialog, props.runtimeType])

    return (
        <Dialog
            open={props.openDialog}
            onClose={close}
            aria-labelledby='terminate-runtime-alert-dialog-title'
            aria-describedby='terminate-runtime-alert-dialog-description'
        >
            <DialogTitle
                id='terminate-runtime-alert-dialog-title'
                sx={{ borderBottom: '1px solid #DDD', minWidth: '500px' }}
            >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant={'h6'}>{t('terminateRuntimeConfirmTitle')}</Typography>
                    {getRuntimeTypeLogo(props.runtimeType)}
                </Box>
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                {runtimeTypeHasData(props.runtimeType) && !canTerminate ? (
                    <Alert severity='warning' sx={{ mb: 2 }}>
                        {t('terminateRuntimeWithData')}
                    </Alert>
                ) : (
                    <DialogContentText id='terminate-runtime-alert-dialog-description'>
                        {t('terminateRuntimeConfirmContent')}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color='error'>
                    {t('cancel')}
                </Button>
                {!isTerminating && !isCheckingTermination ? (
                    <Button onClick={submit} autoFocus disabled={!canTerminate}>
                        {t('terminate')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

import { DialogProps } from '../../../model/model'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Alert, Box, CircularProgress, Link, TextField, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import React, { ChangeEvent, useState } from 'react'
import { useCreateOntologyMutation } from '../../common-api/ontologyApiSlice'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { useTranslation } from 'react-i18next'
import FileUpload from '../../common/FileUpload'
import { useAppDispatch } from '../../../app/hooks'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import { Accept } from 'react-dropzone'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { receivedNewOntology } from './adminOntologySlice'
import '../../../extensions/number.ext'

export default function CreateOntologyDialog({ openDialog, handleCloseDialog }: DialogProps) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [description, setDescription] = useState('')
    const [file, setFile] = useState<File | null>(null)
    const [fileError, setFileError] = useState(false)
    const [createOntologyApi, { isLoading: isCreating }] = useCreateOntologyMutation()

    const accept: Accept = {
        'application/csv': ['.csv'],
    }

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    function handleFileUploadChange(newFiles: File[]) {
        if (newFiles.length != 1) {
            dispatch(
                receivedAppMessage({
                    type: 'error',
                    message: 'Please select only 1 CSV file.',
                }),
            )
        }
        setFile(newFiles[0])
    }

    const resetErrors = () => {
        setNameError(false)
        setFileError(false)
    }

    const resetValues = () => {
        setName('')
        setDescription('')
        setFile(null)
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!name) {
            setNameError(true)
            valid = false
        }
        if (!file) {
            setFileError(true)
            valid = false
        }
        return valid
    }

    const submit = async () => {
        if (!validateForm() || !file) {
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        formData.append('name', name)
        formData.append('description', description)
        const ontology = await createOntologyApi(formData).unwrap()
        dispatch(
            receivedNewOntology({
                ontology: ontology,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The ontology was successfully created.',
            }),
        )
        close()
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        resetErrors()
        resetValues()
    })

    return (
        <Dialog open={openDialog} onClose={close} maxWidth={'md'}>
            <DialogTitle>Create Ontology</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <Alert severity={'info'}>
                            The ontology file must have 3 columns and no header. The columns are ID, Label, Synonyms.
                            The synonyms should be separated by the pipe symbol.
                            <br />
                            <br />
                            MYONT_000001,My Label,Syn Label|Another Syn Label|3rd Syn Label
                            <br />
                            MYONT_000002,Another Label,Second Syn Label
                        </Alert>
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            slotProps={{
                                htmlInput: {
                                    autoComplete: 'off', // disable autocomplete and autofill
                                },
                            }}
                            required
                            fullWidth
                        />
                        <TextField
                            onChange={handleDescriptionChange}
                            value={description}
                            label={t('description')}
                            fullWidth
                            multiline
                            rows={3}
                        />
                        {file ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                            >
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold' }}>{file.name}</Typography>
                                    <Typography sx={{ fontStyle: 'italic' }}>{file.size.formatBytes()}</Typography>
                                </Box>
                                <Link
                                    onClick={() => {
                                        setFile(null)
                                    }}
                                    color={'error'}
                                    sx={{ fontSize: '0.9em' }}
                                >
                                    Remove
                                </Link>
                            </Box>
                        ) : (
                            <FileUpload
                                sx={{ mt: 2 }}
                                onChange={handleFileUploadChange}
                                accept={accept}
                                title='Upload ontology file (.csv)'
                                buttonText='Select file'
                                error={fileError}
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isCreating ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

import React from 'react'
import { PermissionSet } from '../../model/model'
import { useUserPermitted } from './authSlice'

type Props = {
    requiredPermissions: Partial<PermissionSet>
    any?: boolean
    children: React.ReactNode
}

function RestrictedComponent({ children, requiredPermissions, any }: Props) {
    const userPermitted = useUserPermitted(requiredPermissions, any)

    if (!userPermitted) {
        return null
    }
    return children
}

export default RestrictedComponent

export const GSEA_GENE_SET_LIBRARIES = [
    'ARCHS4_Cell-lines',
    'ARCHS4_IDG_Coexp',
    'ARCHS4_Kinases_Coexp',
    'ARCHS4_TFs_Coexp',
    'ARCHS4_Tissues',
    'Achilles_fitness_decrease',
    'Achilles_fitness_increase',
    'Aging_Perturbations_from_GEO_down',
    'Aging_Perturbations_from_GEO_up',
    'Allen_Brain_Atlas_10x_scRNA_2021',
    'Allen_Brain_Atlas_down',
    'Allen_Brain_Atlas_up',
    'Azimuth_2023',
    'Azimuth_Cell_Types_2021',
    'BioPlanet_2019',
    'BioPlex_2017',
    'CCLE_Proteomics_2020',
    'CORUM',
    'COVID-19_Related_Gene_Sets_2021',
    'Cancer_Cell_Line_Encyclopedia',
    'CellMarker_2024',
    'CellMarker_Augmented_2021',
    'ChEA_2022',
    'Chromosome_Location',
    'Chromosome_Location_hg19',
    'ClinVar_2019',
    'DSigDB',
    'Data_Acquisition_Method_Most_Popular_Genes',
    'DepMap_CRISPR_GeneDependency_CellLines_2023',
    'DepMap_WG_CRISPR_Screens_Broad_CellLines_2019',
    'DepMap_WG_CRISPR_Screens_Sanger_CellLines_2019',
    'Descartes_Cell_Types_and_Tissue_2021',
    'Diabetes_Perturbations_GEO_2022',
    'DisGeNET',
    'Disease_Perturbations_from_GEO_down',
    'Disease_Perturbations_from_GEO_up',
    'Disease_Signatures_from_GEO_down_2014',
    'Disease_Signatures_from_GEO_up_2014',
    'DrugMatrix',
    'Drug_Perturbations_from_GEO_2014',
    'Drug_Perturbations_from_GEO_down',
    'Drug_Perturbations_from_GEO_up',
    'ENCODE_Histone_Modifications_2013',
    'ENCODE_Histone_Modifications_2015',
    'ENCODE_TF_ChIP-seq_2014',
    'ENCODE_TF_ChIP-seq_2015',
    'ENCODE_and_ChEA_Consensus_TFs_from_ChIP-X',
    'ESCAPE',
    'Elsevier_Pathway_Collection',
    'Enrichr_Libraries_Most_Popular_Genes',
    'Enrichr_Submissions_TF-Gene_Coocurrence',
    'Enrichr_Users_Contributed_Lists_2020',
    'Epigenomics_Roadmap_HM_ChIP-seq',
    'FANTOM6_lncRNA_KD_DEGs',
    'GO_Biological_Process_2023',
    'GO_Cellular_Component_2023',
    'GO_Molecular_Function_2023',
    'GTEx_Aging_Signatures_2021',
    'GTEx_Tissue_Expression_Down',
    'GTEx_Tissue_Expression_Up',
    'GTEx_Tissues_V8_2023',
    'GWAS_Catalog_2019',
    'GWAS_Catalog_2023',
    'GeDiPNet_2023',
    'GeneSigDB',
    'Gene_Perturbations_from_GEO_down',
    'Gene_Perturbations_from_GEO_up',
    'Genes_Associated_with_NIH_Grants',
    'Genome_Browser_PWMs',
    'GlyGen_Glycosylated_Proteins_2022',
    'HDSigDB_Human_2021',
    'HDSigDB_Mouse_2021',
    'HMDB_Metabolites',
    'HMS_LINCS_KinomeScan',
    'HomoloGene',
    'HuBMAP_ASCT_plus_B_augmented_w_RNAseq_Coexpression',
    'HuBMAP_ASCTplusB_augmented_2022',
    'HumanCyc_2016',
    'Human_Gene_Atlas',
    'Human_Phenotype_Ontology',
    'IDG_Drug_Targets_2022',
    'InterPro_Domains_2019',
    'Jensen_COMPARTMENTS',
    'Jensen_DISEASES',
    'Jensen_TISSUES',
    'KEA_2015',
    'KEGG_2021_Human',
    'KEGG_2021_Mouse',
    'KOMP2',
    'KOMP2_Mouse_Phenotypes_2022',
    'Kinase_Perturbations_from_GEO_down',
    'Kinase_Perturbations_from_GEO_up',
    'L1000_Kinase_and_GPCR_Perturbations_down',
    'L1000_Kinase_and_GPCR_Perturbations_up',
    'LINCS_L1000_CRISPR_KO_Consensus_Sigs',
    'LINCS_L1000_Chem_Pert_Consensus_Sigs',
    'LINCS_L1000_Chem_Pert_down',
    'LINCS_L1000_Chem_Pert_up',
    'LINCS_L1000_Ligand_Perturbations_down',
    'LINCS_L1000_Ligand_Perturbations_up',
    'Ligand_Perturbations_from_GEO_down',
    'Ligand_Perturbations_from_GEO_up',
    'MAGMA_Drugs_and_Diseases',
    'MAGNET_2023',
    'MCF7_Perturbations_from_GEO_down',
    'MCF7_Perturbations_from_GEO_up',
    'MGI_Mammalian_Phenotype_2017',
    'MGI_Mammalian_Phenotype_Level_3',
    'MGI_Mammalian_Phenotype_Level_4_2021',
    'MSigDB_Computational',
    'MSigDB_Hallmark_2020',
    'MSigDB_Oncogenic_Signatures',
    'Metabolomics_Workbench',
    'Metabolomics_Workbench_Metabolites',
    'Metabolomics_Workbench_Metabolites_2022',
    'Microbe_Perturbations_from_GEO_down',
    'Microbe_Perturbations_from_GEO_up',
    'MoTrPAC_2023',
    'Mouse_Gene_Atlas',
    'NCI-60_Cancer_Cell_Lines',
    'NCI-Nature_2016',
    'NIH_Funded_PIs_2017_AutoRIF_ARCHS4_Predictions',
    'NIH_Funded_PIs_2017_GeneRIF_ARCHS4_Predictions',
    'NIH_Funded_PIs_2017_Human_AutoRIF',
    'NIH_Funded_PIs_2017_Human_GeneRIF',
    'NURSA_Human_Endogenous_Complexome',
    'OMIM_Disease',
    'OMIM_Expanded',
    'Old_CMAP_down',
    'Old_CMAP_up',
    'Orphanet_Augmented_2021',
    'PFOCR_Pathways',
    'PFOCR_Pathways_2023',
    'PPI_Hub_Proteins',
    'PanglaoDB_Augmented_2021',
    'Panther_2016',
    'Pfam_Domains_2019',
    'Pfam_InterPro_Domains',
    'PheWeb_2019',
    'PhenGenI_Association_2021',
    'Phosphatase_Substrates_from_DEPOD',
    'ProteomicsDB_2020',
    'Proteomics_Drug_Atlas_2023',
    'RNA-Seq_Disease_Gene_and_Drug_Signatures_from_GEO',
    'RNAseq_Automatic_GEO_Signatures_Human_Down',
    'RNAseq_Automatic_GEO_Signatures_Human_Up',
    'RNAseq_Automatic_GEO_Signatures_Mouse_Down',
    'RNAseq_Automatic_GEO_Signatures_Mouse_Up',
    'Rare_Diseases_AutoRIF_ARCHS4_Predictions',
    'Rare_Diseases_AutoRIF_Gene_Lists',
    'Rare_Diseases_GeneRIF_ARCHS4_Predictions',
    'Rare_Diseases_GeneRIF_Gene_Lists',
    'Reactome_2022',
    'Rummagene_kinases',
    'Rummagene_signatures',
    'Rummagene_transcription_factors',
    'SILAC_Phosphoproteomics',
    'SubCell_BarCode',
    'SynGO_2024',
    'SysMyo_Muscle_Gene_Sets',
    'TF-LOF_Expression_from_GEO',
    'TF_Perturbations_Followed_by_Expression',
    'TG_GATES_2020',
    'TRANSFAC_and_JASPAR_PWMs',
    'TRRUST_Transcription_Factors_2019',
    'Table_Mining_of_CRISPR_Studies',
    'Tabula_Muris',
    'Tabula_Sapiens',
    'TargetScan_microRNA_2017',
    'The_Kinase_Library_2023',
    'Tissue_Protein_Expression_from_Human_Proteome_Map',
    'Tissue_Protein_Expression_from_ProteomicsDB',
    'Transcription_Factor_PPIs',
    'UK_Biobank_GWAS',
    'Virus-Host_PPI_P-HIPSTer_2020',
    'VirusMINT',
    'Virus_Perturbations_from_GEO_down',
    'Virus_Perturbations_from_GEO_up',
    'WikiPathway_2021_Mouse',
    'WikiPathway_2023_Human',
    'dbGaP',
    'huMAP',
    'lncHUB_lncRNA_Co-Expression',
    'miRTarBase_2017',
]

export const DEFAULT_PVALUE_CUTOFF = 0.05
export const DEFAULT_MIN_ABS_LOG_FOLD_CHANGE = 1
export const DEFAULT_MIN_ABS_LOG_FOLD_CHANGE_PATHWAY_ACTIVITY = 0.5

export const COUNTRY_LIST = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua & Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Rep',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo (Democratic Rep)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland (Republic)',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
]

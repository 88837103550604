import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { Alert, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { DialogProps } from '../../../model/model'
import { useDeleteOntologiesMutation } from '../../common-api/ontologyApiSlice'
import { useAppDispatch } from '../../../app/hooks'
import { receivedDeletedOntologies } from './adminOntologySlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

interface DeleteOntologiesParams extends DialogProps {
    selectionModel: GridRowSelectionModel
}

export default function DeleteOntologiesDialog(props: DeleteOntologiesParams) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [deleteOntologiesApi, { isLoading: isDeleting }] = useDeleteOntologiesMutation()

    const submit = async () => {
        if (props.selectionModel.length == 0) {
            return
        }
        const resp = await deleteOntologiesApi({
            ontologyIdList: props.selectionModel as number[],
        }).unwrap()
        dispatch(
            receivedDeletedOntologies({
                ontologyIdList: resp.ontologyIdList,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The selected ontologies were successfully deleted.',
            }),
        )
        if (props.onDone) {
            props.onDone()
        }
        close()
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(props.handleCloseDialog)

    return (
        <Dialog open={props.openDialog} onClose={close}>
            <DialogTitle>Delete Ontologies</DialogTitle>
            <DialogContent>
                <Alert severity={'info'}>Only unused and non-standard ontologies can be deleted.</Alert>
                <Typography sx={{ mt: 2 }}>Are you sure you want to delete the selected ontologies?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isDeleting ? (
                    <Button onClick={submit} autoFocus>
                        {t('delete')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)

declare global {
    interface String {
        truncate(n: number): string

        parseAndFormatDate(): string

        parseAndFormatDateWithoutTime(): string

        toNumber(): number

        isValidVarName(): boolean
    }
}

String.prototype.truncate = function (this: string, n: number) {
    const s = this.toString()
    if (s.length > n) {
        return s.slice(0, n) + '...'
    }
    return s
}

const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language
const f = new Intl.DateTimeFormat(userLocale, { dateStyle: 'short', timeStyle: 'short' })
const f2 = new Intl.DateTimeFormat(userLocale, { dateStyle: 'short' })

String.prototype.parseAndFormatDate = function (this: string) {
    return f.format(this.toNumber())
}

String.prototype.parseAndFormatDateWithoutTime = function (this: string) {
    return f2.format(this.toNumber())
}

String.prototype.toNumber = function (this: string) {
    return dayjs(this).unix() * 1000
}

String.prototype.isValidVarName = function (this: string) {
    return /^[a-zA-Z][a-z0-9_]+$/gi.test(this)
}

export {}

import { useEffect } from 'react'

export const useSubmitOnEnter = (handleSubmit: () => void, open: boolean) => {
    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSubmit()
        }
    }

    useEffect(() => {
        if (open) {
            window.addEventListener('keypress', handleKeyPress)
        }

        return () => {
            window.removeEventListener('keypress', handleKeyPress)
        }
    }, [open, handleSubmit])
}

import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import '../../extensions/string.ext'
import i18n from '../../i18n'
import { Project, ProjectAccess } from '../../model/model'

const projects = createEntityAdapter<Project, number>({
    selectId: (x) => x.id,
})

const projectAcceses = createEntityAdapter<ProjectAccess, number>({
    selectId: (x) => x.projectId, // We assume backend only respons with access list for current user.
})

type ProjectListState = {
    projects: EntityState<Project, number>
    projectAcceses: EntityState<ProjectAccess, number>
    totalCount: number
    state: 'idle' | 'loading' | 'finished'
}

const initialState = {
    projects: projects.getInitialState(),
    projectAcceses: projectAcceses.getInitialState(),
    state: 'idle',
    totalCount: 0,
} as ProjectListState

const projectListSlice = createSlice({
    name: 'projectListHolder',
    initialState: initialState,
    reducers: {
        receivedProjectsList: (
            state,
            {
                payload: { projectList, projectAccesesList, totalCount },
            }: PayloadAction<{ projectList: Project[]; projectAccesesList: ProjectAccess[]; totalCount: number }>,
        ) => {
            projects.setAll(state.projects, projectList)
            projectAcceses.setAll(state.projectAcceses, projectAccesesList)
            state.state = 'finished'
            state.totalCount = totalCount
        },
        receivedDeletedProjectIds: (
            state,
            { payload: { projectIdList } }: PayloadAction<{ projectIdList: number[] }>,
        ) => {
            projects.removeMany(state.projects, projectIdList)
        },
        setLoading: (state) => {
            state.state = 'loading'
        },
    },
})

export const { receivedProjectsList, receivedDeletedProjectIds, setLoading } = projectListSlice.actions

export const {
    selectAll: selectAllProjects,
    selectById: selectProjectById,
    selectIds: selectProjectIds,
    selectTotal: selectTotalProjects,
    selectEntities: selectProjectEntities,
} = projects.getSelectors<RootState>((state) => state.projectListHolder.projects)

const { selectAll: selectAllProjectAccesses } = projectAcceses.getSelectors<RootState>(
    (state) => state.projectListHolder.projectAcceses,
)

export const selectProjectRows = createSelector([selectAllProjects, selectAllProjectAccesses], (projects, accesses) => {
    return projects.map((project: Project) => {
        const access = accesses.find((a) => a.projectId === project.id)?.accessType
        return {
            id: project.id,
            external_id: project.externalId,
            title: project.title,
            createdAt: project.createdAt.parseAndFormatDate(),
            createdBy: `${project.user.firstName} ${project.user.lastName}`,
            access: access ? i18n.t(access) : i18n.t(project.organizationVisibility),
            tags: project.tags,
        }
    })
})

export const selectTotalCount = (state: RootState) => state.projectListHolder.totalCount

export default projectListSlice.reducer

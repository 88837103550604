import { apiSlice } from '../../app/apiSlice'
import { FileObjectStructure, RuntimeDetails, RuntimeOption, RuntimeType } from '../../model/model'
import { RuntimeStatusInformation } from '../../model/webSocketCommands'

export type CreateRuntimeRequest = {
    runtimeOptionId: number
    durationHours: number
    runtimeType: RuntimeType
}

export type RuntimeByTypeRequest = {
    runtimeType: RuntimeType
}

export type GetActiveRuntimeResponse = RuntimeStatusInformation

export type CreateRuntimeResponse = RuntimeDetails

type ListRuntimeRequest = {
    runtimeId: number
    prefix: string
}

type ExtendRuntimeDurationRequest = {
    runtimeId: number
    durationHours: number
}

export const runtimeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRuntimeOptions: builder.query<RuntimeOption[], void>({
            query: () => ({
                url: '/private/runtime/get-options',
            }),
        }),
        getActiveRuntimeInfo: builder.query<GetActiveRuntimeResponse[], void>({
            query: () => ({
                url: '/private/runtime/get-info',
            }),
        }),
        getActiveRuntimeInfoByType: builder.query<GetActiveRuntimeResponse, RuntimeByTypeRequest>({
            query: () => ({
                url: '/private/runtime/get-type-info',
            }),
        }),
        createRuntime: builder.mutation<CreateRuntimeResponse, CreateRuntimeRequest>({
            query: (req) => ({
                url: '/private/runtime/create',
                method: 'POST',
                body: req,
            }),
        }),
        extendRuntimeDuration: builder.mutation<void, ExtendRuntimeDurationRequest>({
            query: (req) => ({
                url: '/private/runtime/extend',
                method: 'PUT',
                body: req,
            }),
        }),
        canTerminateRuntime: builder.mutation<boolean, RuntimeByTypeRequest>({
            query: (req) => ({
                url: '/private/runtime/can-terminate',
                method: 'POST',
                body: req,
            }),
        }),
        terminateRuntime: builder.mutation<void, RuntimeByTypeRequest>({
            query: (req) => ({
                url: '/private/runtime/terminate',
                method: 'POST',
                body: req,
            }),
        }),
        listRuntimeFiles: builder.query<FileObjectStructure, ListRuntimeRequest>({
            query: (req) => ({
                url: `/private/runtime/${req.runtimeId}/mount/file-structure`,
                method: 'GET',
                params: {
                    prefix: req.prefix,
                },
            }),
        }),
    }),
})

export const {
    useGetRuntimeOptionsQuery,
    useLazyGetActiveRuntimeInfoQuery,
    useCreateRuntimeMutation,
    useCanTerminateRuntimeMutation,
    useTerminateRuntimeMutation,
    useLazyListRuntimeFilesQuery,
    useExtendRuntimeDurationMutation,
    endpoints: { getRuntimeOptions, getActiveRuntimeInfo, createRuntime, terminateRuntime },
} = runtimeApiSlice

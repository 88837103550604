import { Notification } from '../../../model/model'
import * as React from 'react'
import { Box, Typography } from '@mui/material'

export interface NotificationParams {
    notification: Notification
    markAsRead: (id: number) => void
}

interface NotificationContentParams {
    notification: Notification
    onClick: () => void
    icon: JSX.Element
    title: React.ReactElement
    children: React.ReactNode
}

export default function NotificationContent({
    notification,
    onClick,
    icon,
    title,
    children,
}: NotificationContentParams) {
    return (
        <Box
            onClick={onClick}
            sx={{ cursor: 'pointer', pt: 1, pb: 1, width: '450px', textWrap: 'wrap', display: 'flex' }}
        >
            <Box sx={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{icon}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '450px', pl: 1, pr: 1 }}>
                <Box sx={{ mt: 0.5, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', mb: 0.25, alignItems: 'center' }}>
                        {title}
                    </Typography>
                    <Typography variant='body2' sx={{ fontSize: '0.8em' }}>
                        {notification.createdAt.parseAndFormatDate()}
                    </Typography>
                </Box>
                {children}
            </Box>
        </Box>
    )
}

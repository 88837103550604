import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { Box, CircularProgress } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { displayRuntimeType } from '../../model/model'
import { useUserPermitted } from '../auth/authSlice'
import { useLazyGetActiveRuntimeInfoQuery } from './runtimeApiSlice'
import RuntimeMenu from './RuntimeMenu'
import { selectAllRuntimes, selectRuntimeConnected, selectRuntimeExists, setRuntimes } from './runtimeSlice'

export default function RuntimeControl() {
    const canUserRunAnalysis = useUserPermitted({ runAnalyses: true })

    return canUserRunAnalysis && <InnerRuntimeControl />
}

function InnerRuntimeControl() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const runtimes = useAppSelector(selectAllRuntimes)
    const runtimeExists = useAppSelector(selectRuntimeExists)
    const runtimeConnected = useAppSelector(selectRuntimeConnected)

    const [getActiveRuntimeInfo, { isFetching }] = useLazyGetActiveRuntimeInfoQuery()

    const [openRuntimeListDialog, setOpenRuntimeListDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const displayText = useCallback(() => {
        if (!runtimeExists) {
            return t('noRuntimes')
        }

        if (!runtimeConnected) {
            return t('noConnectedRuntimes')
        }

        const connectedRuntimes = runtimes.filter((r) => r.connected)
        if (connectedRuntimes.length === 1) {
            return [displayRuntimeType(connectedRuntimes[0].runtimeType!), t('connected')].join(' ')
        }

        return [connectedRuntimes.length, t('runtime'), t('connected')].join(' ')
    }, [runtimes, runtimeConnected])

    useEffect(() => {
        if (runtimes.some((r) => !r.loaded)) {
            getActiveRuntimeInfo()
                .unwrap()
                .then((runtimeInfos) => {
                    dispatch(
                        setRuntimes(
                            runtimeInfos.map((runtimeResponse) => ({
                                id: runtimeResponse.id,
                                exists: runtimeResponse.exists,
                                proc: runtimeResponse.proc,
                                instanceType: runtimeResponse.instanceType,
                                gpus: runtimeResponse.gpus,
                                vcpus: runtimeResponse.vcpus,
                                memoryGib: runtimeResponse.memoryGib,
                                gpuMemoryGib: runtimeResponse.gpuMemoryGib,
                                createdAt: runtimeResponse.createdAt ?? '',
                                connected: runtimeResponse.online,
                                durationHours: runtimeResponse.durationHours,
                                runtimeType: runtimeResponse.runtimeType,
                                volumeSize: runtimeResponse.volumeSize,
                            })),
                        ),
                    )
                })
        }
    }, [runtimes])

    return (
        <Box>
            <Paper
                sx={{
                    display: 'flex',
                    height: '32px',
                    alignItems: 'center',
                    p: '0 2px',
                    minWidth: 200,
                }}
            >
                {runtimeExists ? (
                    <LinkIcon sx={{ m: '8px' }} color={runtimeConnected ? 'primary' : 'secondary'} />
                ) : (
                    <LinkOffIcon sx={{ m: '8px' }} color={isFetching ? 'disabled' : 'error'} />
                )}

                <Divider sx={{ height: 28 }} orientation='vertical' />
                <Typography
                    sx={{
                        flexGrow: 1,
                        ml: 2,
                        mr: 2,
                        fontSize: '0.9em',
                    }}
                >
                    {isFetching ? t('startupLoading') : displayText()}
                </Typography>
                <Divider sx={{ height: 28 }} orientation='vertical' />

                {isFetching ? (
                    <CircularProgress size={20} sx={{ m: 1 }} />
                ) : (
                    <IconButton
                        sx={{ p: '5px' }}
                        aria-label='directions'
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setOpenRuntimeListDialog(true)
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                )}
            </Paper>

            <RuntimeMenu
                anchorEl={anchorEl}
                openDialog={openRuntimeListDialog}
                handleCloseDialog={() => {
                    setOpenRuntimeListDialog(false)
                }}
            />
        </Box>
    )
}

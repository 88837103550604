import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { isUUID } from '../../utils/misc'
import { useConfirmMutation } from './signupApiSlice'
import { Box, Button, CircularProgress, Link, Stack, Typography } from '@mui/material'
import Logo from '../../assets/images/logo-panomics.png'
import Paper from '@mui/material/Paper'
import Background from '../../assets/images/bkg-element.svg'
import ErrorIcon from '@mui/icons-material/Error'
import SuccessIcon from '../../assets/images/success-icon.svg'
import LockIcon from '@mui/icons-material/Lock'
import ArticleIcon from '@mui/icons-material/Article'
import SupportIcon from '@mui/icons-material/Support'

export default function EmailConfirmation() {
    const { code } = useParams()

    const [confirm] = useConfirmMutation()
    const [confirmSuccess, setConfirmSuccess] = useState(false)
    const [confirmError, setConfirmError] = useState(false)
    const [reqSent, setReqSent] = useState(false)

    const executeConfirm = async () => {
        // Local flag to ensure this function runs only once
        if (!reqSent && code && isUUID(code)) {
            setReqSent(true) // Immediately set to prevent any repeated calls
            try {
                await confirm(code).unwrap()
                setConfirmError(false)
                setConfirmSuccess(true)
            } catch {
                setConfirmSuccess(false)
                setConfirmError(true)
            }
        }
    }

    useEffect(() => {
        void executeConfirm()
    }, [])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', width: '500px', position: 'relative', pt: '100px' }}>
                <Box
                    component='img'
                    src={Background}
                    alt='Background'
                    sx={{
                        position: 'absolute',
                        top: '50px',
                        transform: 'rotate(30deg)',
                        width: '80%',
                        zIndex: 1,
                    }}
                />
                <Paper elevation={3} sx={{ p: 5, zIndex: 2 }}>
                    <Stack
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 4,
                            textAlign: 'center',
                        }}
                    >
                        <Box component={'img'} src={Logo} sx={{ width: '150px' }}></Box>
                        {confirmSuccess && (
                            <>
                                <Typography sx={{ fontSize: '1em' }}>
                                    Your organization and user account have been successfully provisioned.
                                </Typography>
                                <Box component={'img'} src={SuccessIcon} sx={{ width: '80px' }} />
                                <Typography sx={{ fontSize: '1em' }}>
                                    Go to the login page and use the &quot;Forgot Password&quot; option to set your
                                    password.
                                </Typography>
                                <Box sx={{ display: 'flex', width: '100%', gap: 2, justifyContent: 'center' }}>
                                    <Button variant={'outlined'} startIcon={<LockIcon />} href={'/'}>
                                        Login
                                    </Button>
                                    <Button
                                        variant={'outlined'}
                                        startIcon={<ArticleIcon />}
                                        href={'https://documentation.panomics.bio'}
                                        target={'_blank'}
                                    >
                                        Documentation
                                    </Button>
                                    <Button
                                        variant={'outlined'}
                                        startIcon={<SupportIcon />}
                                        href={'mailto:support@panomics.bio'}
                                        target={'_blank'}
                                    >
                                        Support
                                    </Button>
                                </Box>
                            </>
                        )}
                        {confirmError && (
                            <>
                                <Typography sx={{ fontSize: '1em' }}>
                                    The organization and user account provisioning failed. Please contact{' '}
                                    <Link href={'mailto:support@panomics.bio'}>support@panomics.bio</Link>.
                                </Typography>
                                <ErrorIcon sx={{ fontSize: '4em' }} color={'error'} />
                            </>
                        )}
                        {!confirmSuccess && !confirmError && (
                            <>
                                <Typography sx={{ fontSize: '1em' }}>
                                    Your organization and user account are being provisioned. This may take a few
                                    minutes.
                                </Typography>
                                <CircularProgress size={30} />
                                <Typography>Please do not close this page.</Typography>
                            </>
                        )}
                    </Stack>
                </Paper>
            </Box>
        </Box>
    )
}

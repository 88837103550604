import { apiSlice } from '../../../app/apiSlice'
import { PermissionSet, Role, RoleWithUserCount } from '../../../model/model'

type GetRoleListResponse = {
    roleList: Role[]
}

type CreateRoleRequest = {
    name: string
} & PermissionSet

type UpdateRoleRequest = CreateRoleRequest & {
    id: number
}

export const roleApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRoleList: builder.query<GetRoleListResponse, void>({
            query: () => ({
                url: '/private/role/list',
            }),
            providesTags: ['Role'],
        }),
        listRolesWithUserCount: builder.query<RoleWithUserCount[], void>({
            query: () => ({
                url: '/private/admin/role/list',
            }),
            providesTags: ['Role'],
        }),
        createRole: builder.mutation<RoleWithUserCount, CreateRoleRequest>({
            query: (req) => ({
                url: '/private/admin/role/create',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: ['Role'],
        }),
        updateRole: builder.mutation<RoleWithUserCount, UpdateRoleRequest>({
            query: (req) => ({
                url: '/private/admin/role/update',
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: ['Role'],
        }),
        deleteRoles: builder.mutation<number[], number[]>({
            query: (req) => ({
                url: '/private/admin/role/delete',
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: ['Role'],
        }),
    }),
})

export const {
    useGetRoleListQuery,
    useLazyListRolesWithUserCountQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRolesMutation,
    endpoints: { getRoleList },
} = roleApiSlice

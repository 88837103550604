import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Collection, CollectionWrapper } from '../../../model/model'

const collections = createEntityAdapter<CollectionWrapper, number>({
    selectId: (x) => x.collection.id,
    sortComparer: (a, b) => (b.collection.createdAt.toNumber() > a.collection.createdAt.toNumber() ? 1 : -1),
})

type CollectionsState = {
    collections: EntityState<CollectionWrapper, number>
}

const initialState = {
    collections: collections.getInitialState(),
} as CollectionsState

const collectionListSlice = createSlice({
    name: 'collectionListHolder',
    initialState: initialState,
    reducers: {
        receivedCollectionList: (
            state,
            { payload: { collectionList } }: PayloadAction<{ collectionList: CollectionWrapper[] }>,
        ) => {
            collections.setAll(state.collections, collectionList)
        },
        receivedNewCollection: (state, { payload: { collection } }: PayloadAction<{ collection: Collection }>) => {
            collections.setOne(state.collections, { collection, sampleCount: 0, sampleTypes: '' })
        },
        receivedUpdatedCollection: (
            state,
            { payload: { collection } }: PayloadAction<{ collection: CollectionWrapper }>,
        ) => {
            collections.setOne(state.collections, collection)
        },
        receivedDeletedCollections: (
            state,
            { payload: { collectionIdList } }: PayloadAction<{ collectionIdList: number[] }>,
        ) => {
            collections.removeMany(state.collections, collectionIdList)
        },
    },
})

export const { receivedCollectionList, receivedDeletedCollections, receivedNewCollection, receivedUpdatedCollection } =
    collectionListSlice.actions

export const {
    selectAll: selectAllCollections,
    selectById: selectCollectionById,
    selectIds: selectCollectionIds,
    selectTotal: selectTotalCollections,
    selectEntities: selectCollectionEntities,
} = collections.getSelectors<RootState>((state) => state.collectionListHolder.collections)

export const selectCollectionRows = createSelector([selectAllCollections], (collections) => {
    return collections.map((collection: CollectionWrapper) => {
        return {
            id: collection.collection.id,
            name: collection.collection.name,
            groupings: collection.collection.groupings,
            createdAt: collection.collection.createdAt.parseAndFormatDate(),
            updatedAt: collection.collection.updatedAt.parseAndFormatDate(),
            sampleCount: collection.sampleCount,
            sampleTypes: collection.sampleTypes,
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export default collectionListSlice.reducer

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import '../../extensions/string.ext'
import { Notification } from '../../model/model'

const notifications = createEntityAdapter<Notification, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type NotificationsState = {
    notifications: EntityState<Notification, number>
    totalCount: number
    totalUnreadCount: number
}

const initialState = {
    notifications: notifications.getInitialState(),
    totalCount: 0,
    totalUnreadCount: 0,
} as NotificationsState

const notificationsSlice = createSlice({
    name: 'notificationsHolder',
    initialState: initialState,
    reducers: {
        receivedNotifications: (
            state,
            {
                payload: { notificationList, totalCount, totalUnreadCount },
            }: PayloadAction<{ notificationList: Notification[]; totalCount: number; totalUnreadCount: number }>,
        ) => {
            notifications.setAll(state.notifications, notificationList)
            state.totalCount = totalCount
            state.totalUnreadCount = totalUnreadCount
        },
        receivedNotification: (state, { payload: { notification } }: PayloadAction<{ notification: Notification }>) => {
            notifications.addOne(state.notifications, notification)
            state.totalCount++
            state.totalUnreadCount++
        },
        receivedNewNotifications: (
            state,
            {
                payload: { notificationList, totalCount, totalUnreadCount },
            }: PayloadAction<{ notificationList: Notification[]; totalCount: number; totalUnreadCount: number }>,
        ) => {
            notifications.addMany(state.notifications, notificationList)
            state.totalCount = totalCount
            state.totalUnreadCount = totalUnreadCount
        },
        receivedNotificationRead: (
            state,
            { payload: { notificationId } }: PayloadAction<{ notificationId: number }>,
        ) => {
            notifications.updateOne(state.notifications, {
                id: notificationId,
                changes: {
                    read: true,
                },
            })
            state.totalUnreadCount--
        },
        receivedAllNotificationsRead: (state) => {
            state.notifications.ids.forEach((id) => {
                notifications.updateOne(state.notifications, {
                    id: id,
                    changes: {
                        read: true,
                    },
                })
            })
            state.totalUnreadCount = 0
        },
    },
})

export const {
    receivedNotifications,
    receivedNotification,
    receivedNewNotifications,
    receivedNotificationRead,
    receivedAllNotificationsRead,
} = notificationsSlice.actions

export const {
    selectAll: selectAllNotifications,
    selectById: selectGeneListById,
    selectIds: selectGeneListIds,
    selectTotal: selectTotalGeneLists,
    selectEntities: selectNotificationEntities,
} = notifications.getSelectors<RootState>((state) => state.notificationsHolder.notifications)

export const selectTotalCount = (state: RootState) => state.notificationsHolder.totalCount
export const selectTotalUnreadCount = (state: RootState) => state.notificationsHolder.totalUnreadCount

export default notificationsSlice.reducer

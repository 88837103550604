import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, CircularProgress, IconButton, MenuItem, Select, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import InlineEditableProp from '../../components/InlineEditableProp'
import HorizontalPageBar from '../common/HorizontalPageBar'
import CreateDashboardDialog from './CreateDashboardDialog'
import DeleteDashboardDialog from './DeleteDashboardDialog'
import { useListDashboardsQuery, useUpdateDashboardNameMutation } from './dashboardApiSlice'
import {
    receivedDashboardsList,
    receivedSelectDashboard,
    receivedUpdatedDashboardName,
    selectAllDashboards,
    selectSelectedDashboard,
} from './dashboardSlice'
import DashboardFilterArea from './filters/DashboardFilterArea'
import SignatureExplorer from './gene_signature/SignatureExplorer'

export default function Explorer() {
    const dispatch = useAppDispatch()
    const dashboardList = useAppSelector(selectAllDashboards)
    const [dashboardCount, setDashboardCount] = useState(0)
    const selectedDashboard = useAppSelector(selectSelectedDashboard)
    const [openCreateDashboardDialog, setOpenCreateDashboardDialog] = useState(false)
    const [openDeleteDashboardDialog, setOpenDeleteDashboardDialog] = useState(false)
    const { data: resp, isLoading: isLoading } = useListDashboardsQuery(undefined, { refetchOnMountOrArgChange: true })
    const [updateDashboardName] = useUpdateDashboardNameMutation()

    const handleSelectedDashboardChange = (event: SelectChangeEvent) => {
        dispatch(
            receivedSelectDashboard({
                id: parseInt(event.target.value),
            }),
        )
    }

    useEffect(() => {
        if (!resp) {
            return
        }
        dispatch(
            receivedDashboardsList({
                dashboardList: resp.dashboardList,
            }),
        )
    }, [resp])

    useEffect(() => {
        if (!dashboardList || dashboardList.length == 0) {
            setDashboardCount(0)
            return
        }
        if (dashboardList && dashboardList.length > 0) {
            if (dashboardList.length != dashboardCount) {
                setDashboardCount(dashboardList.length)
                dispatch(
                    receivedSelectDashboard({
                        id: dashboardList[0].id,
                    }),
                )
                return
            }
        }
    }, [dashboardList])

    const updateName = (name: string) => {
        if (!selectedDashboard) {
            return
        }
        updateDashboardName({
            id: selectedDashboard.id,
            name: name,
        })
            .unwrap()
            .then(() => {
                dispatch(
                    receivedUpdatedDashboardName({
                        id: selectedDashboard.id,
                        name: name,
                    }),
                )
            })
    }

    return (
        <>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'calc(100vh - 60px)',
                    }}
                >
                    <Stack
                        sx={{
                            '& .MuiBox-root': {
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                            },
                            '& .MuiBox-root .MuiTypography-root': {
                                ml: 1,
                            },
                        }}
                    >
                        <Box>
                            <CircularProgress size={20} />
                            <Typography>Loading data</Typography>
                        </Box>
                    </Stack>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: 'calc(100vh - 65px)',
                        overflow: 'auto',
                    }}
                >
                    <HorizontalPageBar
                        content={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {selectedDashboard ? (
                                    <Typography variant='h6'>
                                        <InlineEditableProp value={selectedDashboard.name} save={updateName} notEmpty />
                                        <IconButton
                                            onClick={() => {
                                                setOpenDeleteDashboardDialog(true)
                                            }}
                                        >
                                            <DeleteIcon sx={{ fontSize: '0.85em' }} />
                                        </IconButton>
                                    </Typography>
                                ) : (
                                    <Typography variant='h6'>No dashboard selected</Typography>
                                )}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {dashboardList && selectedDashboard && (
                                        <Select
                                            size='small'
                                            value={`${selectedDashboard?.id}`}
                                            onChange={handleSelectedDashboardChange}
                                            sx={{ width: '200px', mr: 1 }}
                                        >
                                            {dashboardList.map((d, idx) => {
                                                return (
                                                    <MenuItem value={d.id} key={`dashboard-${idx}`}>
                                                        {d.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    )}
                                    {selectedDashboard && (
                                        <Button
                                            sx={{ mr: 1 }}
                                            variant='outlined'
                                            color='primary'
                                            startIcon={<AddCircleOutlineIcon />}
                                            onClick={() => {
                                                setOpenCreateDashboardDialog(true)
                                            }}
                                        >
                                            Create Dashboard
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        }
                    />
                    {selectedDashboard ? (
                        <Box
                            sx={{
                                width: '100%',
                                flexDirection: 'column',
                            }}
                        >
                            <DashboardFilterArea />
                            <Box
                                sx={{
                                    pt: 2,
                                    pl: 3,
                                    pr: 3,
                                    pb: 6,
                                }}
                            >
                                <SignatureExplorer />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: 'calc(100vh - 120px)',
                                flexDirection: 'column',
                                backgroundColor: '#F4F4F4',
                            }}
                        >
                            <Button
                                variant='contained'
                                startIcon={<AddCircleOutlineIcon />}
                                sx={{ p: 2, pl: 3, pr: 3, fontSize: '1.1em' }}
                                onClick={() => {
                                    setOpenCreateDashboardDialog(true)
                                }}
                            >
                                Create a new dashboard
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
            <CreateDashboardDialog
                openDialog={openCreateDashboardDialog}
                handleCloseDialog={() => {
                    setOpenCreateDashboardDialog(false)
                }}
            />
            {selectedDashboard ? (
                <>
                    <DeleteDashboardDialog
                        openDialog={openDeleteDashboardDialog}
                        handleCloseDialog={() => {
                            setOpenDeleteDashboardDialog(false)
                        }}
                        id={selectedDashboard.id}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    )
}

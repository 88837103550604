import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ExternalApiKey } from '../../model/model'

const externalApiKeys = createEntityAdapter<ExternalApiKey, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type ExternalApiKeyListState = {
    externalApiKeys: EntityState<ExternalApiKey, number>
}

const initialState = {
    externalApiKeys: externalApiKeys.getInitialState(),
    state: 'idle',
} as ExternalApiKeyListState

const externalApiKeyListSlice = createSlice({
    name: 'externalApiKeyListHolder',
    initialState: initialState,
    reducers: {
        receivedExternalApiKeyList: (
            state,
            { payload: { externalApiKeyList } }: PayloadAction<{ externalApiKeyList: ExternalApiKey[] }>,
        ) => {
            externalApiKeys.setAll(state.externalApiKeys, externalApiKeyList)
        },
        receivedNewExternalApiKey: (
            state,
            { payload: { externalApiKey } }: PayloadAction<{ externalApiKey: ExternalApiKey }>,
        ) => {
            externalApiKeys.addOne(state.externalApiKeys, externalApiKey)
        },
        receivedDeletedExternalApiKeys: (state, { payload: { idList } }: PayloadAction<{ idList: number[] }>) => {
            externalApiKeys.removeMany(state.externalApiKeys, idList)
        },
    },
})

export const { receivedExternalApiKeyList, receivedNewExternalApiKey, receivedDeletedExternalApiKeys } =
    externalApiKeyListSlice.actions

export const {
    selectAll: selectAllExternalApiKeys,
    selectById: selectExternalApiKeyById,
    selectIds: selectExternalApiKeyIds,
    selectTotal: selectTotalExternalApiKeys,
    selectEntities: selectExternalApiKeyEntities,
} = externalApiKeys.getSelectors<RootState>((state) => state.externalApiKeyListHolder.externalApiKeys)

export const selectExternalApiKeyRows = createSelector([selectAllExternalApiKeys], (externalApiKeys) => {
    return externalApiKeys.map((externalApiKey: ExternalApiKey) => {
        return {
            id: externalApiKey.id,
            label: externalApiKey.label,
            createdAt: externalApiKey.createdAt.parseAndFormatDate(),
            expiresAt: externalApiKey.expiresAt.parseAndFormatDate(),
            provider: externalApiKey.provider,
        }
    })
})

export const selectExistingProviders = createSelector([selectAllExternalApiKeys], (externalApiKeys) => {
    return externalApiKeys.map((externalApiKey) => externalApiKey.provider)
})

export default externalApiKeyListSlice.reducer

import React from 'react'
import { Box, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useDeliberateDialogClose } from '../../../../../../hooks/useDeliberateDialogClose'
import { DialogProps } from '../../../../../../model/model'
import { SlideUpTransition } from '../../../../../../utils/transitions'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import DifferentialExpressionResultSimilarity from './DifferentialExpressionResultSimilarity'
import useWindowSize from '../../../../../../hooks/useWindowResize'

export interface GeneSignatureMostSimilarDialogProps extends DialogProps {
    geneSignatureId: number
    name: string | undefined
}

export default function GeneSignatureMostSimilarDialog({
    geneSignatureId,
    name,
    openDialog,
    handleCloseDialog,
}: GeneSignatureMostSimilarDialogProps) {
    const { width = 0 } = useWindowSize()

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
    })

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            Most similar gene signatures
                        </Typography>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            {name}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    p: 2,
                    height: 'calc(100vh - 50px)',
                    display: 'flex',
                    flexDirection: 'column',
                    '& > :last-child': {
                        flexGrow: 1,
                    },
                }}
            >
                <DifferentialExpressionResultSimilarity geneSignatureId={geneSignatureId} width={width - 20} />
            </Box>
        </Dialog>
    )
}

import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useLazyGetOntologyOptionsQuery } from '../common-api/ontologyApiSlice'
import { debounce } from 'lodash'

export interface OntologyFormParams {
    ontologyId: number
    operator: string
    value?: string | undefined
    setValue: (value: string) => void
}

export default function OntologyForm(params: OntologyFormParams) {
    const { t } = useTranslation()

    const formType = useMemo(() => {
        switch (params.operator) {
            case 'is':
            case 'is not':
                return 'complete'
            case 'in':
                return 'multi_complete'
            default:
                return 'text'
        }
    }, [params.operator])

    return (
        <>
            {formType === 'complete' && <OntologyFormComplete {...params} />}
            {formType === 'multi_complete' && <OntologyFormMultiComplete {...params} />}
            {formType === 'text' && (
                <TextField
                    label={t('value')}
                    sx={{ mt: 2 }}
                    type='text'
                    value={params.value}
                    onChange={(event) => params.setValue(event.target.value)}
                    fullWidth
                    required
                />
            )}
        </>
    )
}

function OntologyFormComplete({
    ontologyId,
    value,
    setValue,
}: {
    ontologyId: number
    value?: string | undefined
    setValue: (value: string) => void
}) {
    const { t } = useTranslation()
    const [input, setInput] = useState(value ?? '')
    const [innerGetOntologyOptions, response] = useLazyGetOntologyOptionsQuery()
    const getOntologyOptions = useCallback(
        debounce((input: string) => innerGetOntologyOptions({ keyword: input, ontologyId: ontologyId }, true), 300, {
            maxWait: 1000,
            trailing: true,
        }),
        [innerGetOntologyOptions, ontologyId],
    )

    const expandedOptions = useMemo(() => {
        if (!response.isSuccess) {
            return []
        }

        return response.data?.map((opt) => opt.value.label)
    }, [response, value])
    return (
        <Autocomplete
            sx={{ width: 1, paddingLeft: 1, marginLeft: 0 }}
            loading={response.isLoading}
            options={expandedOptions}
            freeSolo
            includeInputInList
            filterOptions={(x) => x}
            value={value}
            inputValue={input}
            onChange={(_: unknown, newValue: string | null) => {
                setValue(newValue ?? '')
            }}
            onInputChange={(event, newInputValue) => {
                setInput(newInputValue)
                getOntologyOptions(newInputValue)
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField {...params} label={t('value')} sx={{ mt: 2, ml: -1 }} type='text' fullWidth required />
            )}
        />
    )
}

function OntologyFormMultiComplete({
    ontologyId,
    value,
    setValue,
}: {
    ontologyId: number
    value?: string | undefined
    setValue: (value: string) => void
}) {
    const { t } = useTranslation()
    const [input, setInput] = useState('')
    const [innerGetOntologyOptions, response] = useLazyGetOntologyOptionsQuery()
    const getOntologyOptions = useCallback(
        debounce((input: string) => innerGetOntologyOptions({ keyword: input, ontologyId: ontologyId }, true), 300, {
            maxWait: 1000,
            trailing: true,
        }),
        [innerGetOntologyOptions, ontologyId],
    )
    const [valueList, setValueList] = useState<string[]>([])

    useEffect(() => {
        if (value === undefined) {
            return
        }
        if (value) {
            setValueList(value.split(','))
        } else {
            setValueList([])
        }
    }, [value])

    const expandedOptions = useMemo(() => {
        if (!response.isSuccess) {
            return []
        }

        return response.data?.map((opt) => opt.value.label)
    }, [response, value])

    return (
        <Autocomplete
            sx={{ width: 1, paddingLeft: 1, marginLeft: 0 }}
            loading={response.isLoading}
            options={expandedOptions}
            freeSolo
            multiple
            includeInputInList
            filterOptions={(x) => x}
            value={valueList}
            inputValue={input}
            onChange={(_: unknown, newValue: string[] | null) => {
                if (newValue) {
                    setValue(newValue.join(','))
                } else {
                    setValue('')
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInput(newInputValue)
                getOntologyOptions(newInputValue)
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField {...params} label={t('value')} sx={{ mt: 2, ml: -1 }} type='text' fullWidth required />
            )}
        />
    )
}

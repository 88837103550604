export class LRUSet<K> {
    private limit: number
    private cache: Set<K>
    private order: K[]

    constructor(limit: number = 10) {
        this.limit = limit
        this.cache = new Set<K>()
        this.order = []
    }

    setLimit(limit: number): void {
        this.limit = limit
    }

    touch(key: K): boolean {
        if (!this.cache.has(key)) {
            return false
        }

        // Move the accessed key to the end to show that it was recently used.
        this.order = this.order.filter((k) => k !== key)
        this.order.push(key)
        return true
    }

    put(key: K): K | undefined {
        // If the key is already in the cache, delete it so we can re-insert.
        if (this.cache.has(key)) {
            this.order = this.order.filter((k) => k !== key)
        }
        this.order.push(key)
        this.cache.add(key)

        // If the cache exceeds the limit, delete the oldest entry and return it.
        if (this.cache.size >= this.limit) {
            const oldestKey = this.order.shift()
            if (oldestKey !== undefined) {
                this.cache.delete(oldestKey)
            }

            return oldestKey
        }

        return undefined
    }
}

import Box from '@mui/material/Box'
import * as React from 'react'

export default function HorizontalPageBar({ content }: { content: JSX.Element }) {
    return (
        <Box
            sx={{
                p: 1,
                pr: 3,
                pl: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px #DDD solid',
                '& .MuiTypography-root': {
                    alignItems: 'center',
                    display: 'flex',
                },
            }}
        >
            {content}
        </Box>
    )
}

import { DialogProps } from '../../../model/model'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { receivedUpdatedOntologyDescription, selectOntologyById } from './adminOntologySlice'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Box, CircularProgress, TextField } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateOntologyMutation } from '../../common-api/ontologyApiSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'

interface EditOntologyParams extends DialogProps {
    ontologyId: number
}

export default function EditOntologyDialog({ openDialog, handleCloseDialog, ontologyId }: EditOntologyParams) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [description, setDescription] = useState('')
    const ontology = useAppSelector((state) => selectOntologyById(state, ontologyId))
    const [updateOntologyApi, { isLoading: isUpdating }] = useUpdateOntologyMutation()

    useEffect(() => {
        if (!ontology) {
            return
        }
        setDescription(ontology.description)
    }, [ontology])

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
    })

    const submit = async () => {
        await updateOntologyApi({
            id: ontologyId,
            description: description,
        }).unwrap()
        dispatch(
            receivedUpdatedOntologyDescription({
                ontologyId: ontologyId,
                description: description,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The ontology was successfully updated.',
            }),
        )
        close()
    }

    return (
        <Dialog open={openDialog} onClose={close} maxWidth={'md'}>
            <DialogTitle>Update Ontology</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField value={ontology?.name} label={t('name')} fullWidth disabled />
                        <TextField
                            onChange={handleDescriptionChange}
                            value={description}
                            label={t('description')}
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isUpdating ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

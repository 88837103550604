import { wsSlice } from '../../app/wsSlice'
import { receivedNotification } from './notificationSlice'

wsSlice.injectSelectHandlers(
    (m) => m.userNotification,
    [
        (p, dispatch) => {
            dispatch(
                receivedNotification({
                    notification: p,
                }),
            )
        },
    ],
)

import { apiSlice } from '../../app/apiSlice'

export enum OrganizationStatus {
    Individual = 'Individual',
    Organization = 'Organization',
}

export enum RoleInOrganization {
    Researcher = 'Researcher',
    DataScientist = 'Data Scientist',
    BioinformaticsSpecialist = 'Bioinformatics Specialist',
    LabManager = 'Lab Manager',
    Executive = 'Executive',
}

export enum OrganizationType {
    Academic = 'Academic',
    BiotechStartup = 'Biotech Startup',
    Pharmaceutical = 'Pharmaceutical',
    BioBank = 'Bio Bank',
    CRO = 'CRO',
    HealthcareSystem = 'Healthcare System',
    Other = 'Other',
}

export type SignUpRequest = {
    firstName: string
    lastName: string
    email: string
    country: string
    // org data
    organizationStatus: OrganizationStatus
    roleInOrganization: RoleInOrganization | null
    organizationName: string
    organizationType: OrganizationType | null
    receiveMarketingCommunications: boolean
    token: string
}

export const signupApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        signup: builder.mutation<void, SignUpRequest>({
            query: (req) => ({
                url: '/signup/send',
                method: 'POST',
                body: req,
            }),
        }),
        confirm: builder.mutation<void, string>({
            query: (code) => ({
                url: '/signup/confirm',
                method: 'POST',
                params: {
                    code: code,
                },
            }),
        }),
    }),
})

export const { useSignupMutation, useConfirmMutation } = signupApiSlice

import BarChartIcon from '@mui/icons-material/BarChart'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import {
    Alert,
    Autocomplete,
    Box,
    FormControlLabel,
    Link,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material'
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
} from '@mui/x-data-grid-premium'
import { Data } from 'plotly.js'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Plot from 'react-plotly.js'
import useStoredColumnModel from '../../../../../../hooks/useStoredColumnModel'
import useStoredFilterModel from '../../../../../../hooks/useStoredFilterModel'
import {
    Collection,
    MetadataFieldTarget,
    MicroarrayWorkflow,
    RnaSeqWorkflow,
    ScRnaSeqWorkflow,
    SimilarityMetricType,
} from '../../../../../../model/model'
import { useGetCollectionsQuery } from '../../../../../common-api/collectionApiSlice'
import { useGetMetadataMapQuery } from '../../../../../common-api/metadataFieldApiSlice'
import { GeneSignatureSimilarityDto } from '../../../../../sample/import/model'
import { useLazyListSimilaritiesQuery } from './geneSignatureApiSlice'
import GeneSignatureModuleDialog from './GeneSignatureModuleDialog'
import GeneSignatureOverlapDialog from './GeneSignatureOverlapDialog'
import GeneSignatureSimilarityGridToolbar from './GeneSignatureSimilarityGridToolbar'
import GeneSignatureSimilarityMatrixDialog from './GeneSignatureSimilarityMatrixDialog'

interface DifferentialExpressionResultSimilarity {
    geneSignatureId: number
    width: number
    plotHeight?: number
}

export default function DifferentialExpressionResultSimilarity({
    geneSignatureId,
    width,
    plotHeight = 250,
}: DifferentialExpressionResultSimilarity) {
    const { t } = useTranslation()
    const [listSimilaritiesApi, { isFetching }] = useLazyListSimilaritiesQuery()
    const [geneSignatureSimilarityDtoList, setGeneSignatureSimilarityDtoList] = useState<GeneSignatureSimilarityDto[]>(
        [],
    )
    const { data } = useGetCollectionsQuery()
    const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [openGeneSignatureOverlapDialog, setOpenGeneSignatureOverlapDialog] = useState(false)
    const [openGeneSignatureSimilarityMatrixDialog, setOpenGeneSignatureSimilarityMatrixDialog] = useState(false)
    const [openGeneSignatureModuleDialog, setOpenGeneSignatureModuleDialog] = useState(false)
    const [metric, setMetric] = useState<SimilarityMetricType | null>('cosine')
    const [raw, setRaw] = useState<boolean>(false)
    const [plotType, setPlotType] = useState<'histogram' | 'scatter'>('scatter')

    const { lookup: metadataMap } = useGetMetadataMapQuery(MetadataFieldTarget.Sample)

    const collectionList = useMemo(() => {
        return data ? [{ name: 'All', id: -1 } as Collection, ...data] : []
    }, [data])

    const getSelectedCollectionIds = () => {
        if (selectedCollection && data) {
            return selectedCollection.id !== -1 ? [selectedCollection.id] : data.map((x) => x.id)
        }

        return []
    }

    useEffect(() => {
        if (selectedCollection && data) {
            const ids = getSelectedCollectionIds()

            setSelectionModel([])
            listSimilaritiesApi({ geneSignatureId: geneSignatureId, collectionIds: ids })
                .unwrap()
                .then((res) => {
                    setGeneSignatureSimilarityDtoList(res)
                })
        }
    }, [selectedCollection, geneSignatureId])

    useEffect(() => {
        if (!selectedCollection && collectionList && collectionList.length > 0) {
            setSelectedCollection(collectionList[0])
        }
    }, [collectionList])

    const renderComputationResultLink = (params: GridRenderCellParams) => {
        let workflowPath = ''
        switch (params.row['workflow']) {
            case MicroarrayWorkflow:
                workflowPath = 'microarray'
                break
            case RnaSeqWorkflow:
                workflowPath = 'rnaseq'
                break
            case ScRnaSeqWorkflow:
                workflowPath = 'scrnaseq'
                break
        }
        return (
            <>
                {params.row['analysisId'] ? (
                    <Link
                        href={`/analysis/${workflowPath}/${params.row['analysisId']}/deg?key=${params.row['computationResultKey']}`}
                        target={'_blank'}
                    >
                        {params.value}
                    </Link>
                ) : (
                    <>{params.value}</>
                )}
            </>
        )
    }

    const columns = useMemo(() => {
        const uniqueColumnNames = new Set(
            geneSignatureSimilarityDtoList.flatMap((gss) => {
                return Object.keys(gss.fields)
            }),
        )
        const variableColumns = Array.from(uniqueColumnNames).map((c) => {
            return {
                field: c,
                headerName: c,
                groupable: false,
                width: 150,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                valueGetter: (value: never, row: any) => {
                    if (row && row['fields']) {
                        return row['fields'][c]
                    }
                    return value
                },
            }
        })
        variableColumns.sort((a, b) => (metadataMap.get(a.field)?.index ?? 0) - (metadataMap.get(b.field)?.index ?? 0))

        return [
            {
                field: 'cosineSimilarityScore',
                headerName: 'Cosine',
                type: 'number',
                groupable: false,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    return params.value.toFixed(2)
                },
            },
            {
                field: 'pearsonCorrelationCoef',
                headerName: 'Pearson',
                type: 'number',
                groupable: false,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    return params.value.toFixed(2)
                },
            },
            {
                field: 'overlapCount',
                headerName: 'Overlap',
                type: 'number',
                groupable: false,
                width: 100,
            },
            {
                field: 'cosineSimilarityScoreRaw',
                headerName: 'Cosine (Unadjusted)',
                type: 'number',
                groupable: false,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    return params.value.toFixed(2)
                },
            },
            {
                field: 'pearsonCorrelationCoefRaw',
                headerName: 'Pearson (Unadjusted)',
                type: 'number',
                groupable: false,
                width: 100,
                renderCell: (params: GridRenderCellParams) => {
                    return params.value.toFixed(2)
                },
            },
            {
                field: 'overlapCountRaw',
                headerName: 'Overlap (Unadjusted)',
                type: 'number',
                groupable: false,
                width: 100,
            },
            {
                field: 'name',
                headerName: t('name'),
                groupable: false,
                width: 200,
                renderCell: renderComputationResultLink,
            },
            {
                field: 'case',
                headerName: t('case'),
                groupable: false,
                width: 200,
                renderCell: renderComputationResultLink,
            },
            {
                field: 'control',
                headerName: t('control'),
                groupable: false,
                width: 200,
                renderCell: renderComputationResultLink,
            },
            {
                field: 'algorithm',
                headerName: t('algorithm'),
                groupable: false,
                width: 100,
            },
            ...variableColumns,
        ] as GridColDef[]
    }, [geneSignatureSimilarityDtoList, metadataMap])

    const defaultHiddenColumns = useMemo(() => {
        return columns.map((c) => c.field).filter((c) => metadataMap.get(c)?.visibleByDefault === false)
    }, [columns, metadataMap])

    const [columnModel, setColumnModel] = useStoredColumnModel('deg_gene_signature_similarity', defaultHiddenColumns)
    const [filterModel, setFilterModel] = useStoredFilterModel('deg_gene_signature_similarity', {
        items: [
            {
                field: 'overlapCount',
                operator: '>',
                value: 100,
            },
        ],
    })

    const disableOverlapButton = useMemo(() => {
        return selectionModel.length == 0 || selectionModel.length > 100
    }, [selectionModel])

    const disableGeneSignatureSimilarityMatrix = useMemo(() => {
        return selectionModel.length == 0 || selectionModel.length > 100
    }, [selectionModel])

    const disableDifferentialExpressionMatrix = useMemo(() => {
        return selectionModel.length < 2 || selectionModel.length > 100
    }, [selectionModel])

    const rows = useMemo(() => {
        return geneSignatureSimilarityDtoList.filter((gss) => {
            if (!raw) {
                return gss.overlapCount >= 2
            }
            return true
        })
    }, [geneSignatureSimilarityDtoList, raw])

    const handleChangePlotType = (event: React.MouseEvent<HTMLElement>, newPlotType: 'histogram' | 'scatter') => {
        setPlotType(newPlotType)
    }

    return (
        <>
            <Alert severity={'info'} sx={{ mb: 2 }}>
                By default, the cosine similarity and Pearson correlation coefficient are calculated using overlapping
                genes with a maximum FDR-adjusted p-value of 0.05. If you enable the &quot;use unadjusted p-value&quot;
                option, the calculations will instead use overlapping genes with a maximum unadjusted p-value of 0.05.
            </Alert>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        sx={{ width: '250px', mr: 1 }}
                        options={collectionList ?? []}
                        getOptionLabel={(o) => o.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Collection'
                                size='small'
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    },
                                }}
                                required
                            />
                        )}
                        value={selectedCollection}
                        onChange={(_, newValue: Collection | null) => {
                            setSelectedCollection(newValue)
                        }}
                    />
                    <Autocomplete
                        sx={{ width: '220px', mr: 1 }}
                        options={['cosine' as SimilarityMetricType, 'pearson' as SimilarityMetricType]}
                        getOptionLabel={(o) => t(o)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Metric'
                                size='small'
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    },
                                }}
                                required
                            />
                        )}
                        value={metric}
                        onChange={(_, newValue: SimilarityMetricType | null) => {
                            setMetric(newValue)
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={raw}
                                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                    setRaw(checked)
                                }}
                            />
                        }
                        label='Use unadjusted p-value'
                    />
                </Box>
                <ToggleButtonGroup
                    value={plotType}
                    exclusive
                    onChange={handleChangePlotType}
                    aria-label='plot type'
                    size={'small'}
                >
                    <ToggleButton value='histogram' aria-label='histogram'>
                        <BarChartIcon />
                    </ToggleButton>
                    <ToggleButton value='scatter' aria-label='scatter'>
                        <ScatterPlotIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {plotType && metric && (
                <Box sx={{ pt: 1 }}>
                    {plotType == 'histogram' ? (
                        <ScoreDistributionPlot
                            geneSignatureSimilarityDtoList={geneSignatureSimilarityDtoList}
                            metric={metric}
                            raw={raw}
                            width={width}
                            height={plotHeight}
                        />
                    ) : (
                        <TornadoPlot
                            geneSignatureSimilarityDtoList={geneSignatureSimilarityDtoList}
                            metric={metric}
                            raw={raw}
                            width={width}
                            height={plotHeight}
                        />
                    )}
                </Box>
            )}
            <Box sx={{ height: `${Math.max(window.innerHeight - 320 - plotHeight, 300)}px` }}>
                <DataGridPremium
                    sx={{
                        border: 0,
                        borderRadius: 0,
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#EEE',
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            pt: 1,
                            pb: 1,
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderRadius: 0,
                        },
                    }}
                    rows={rows}
                    rowHeight={32}
                    columns={columns}
                    disableAggregation
                    loading={isFetching}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel)
                    }}
                    checkboxSelection
                    keepNonExistentRowsSelected
                    disableRowSelectionOnClick
                    pageSizeOptions={[10, 25, 50, 100]}
                    pagination={true}
                    disableMultipleColumnsSorting
                    columnVisibilityModel={columnModel}
                    onColumnVisibilityModelChange={setColumnModel}
                    filterModel={filterModel}
                    onFilterModelChange={setFilterModel}
                    initialState={{
                        pinnedColumns: {
                            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                        },
                    }}
                    slots={{
                        toolbar: GeneSignatureSimilarityGridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            overlapCallback: () => {
                                setOpenGeneSignatureOverlapDialog(true)
                            },
                            disableOverlap: disableOverlapButton,
                            similarityCallback: () => {
                                setOpenGeneSignatureSimilarityMatrixDialog(true)
                            },
                            disableSimilarity: disableGeneSignatureSimilarityMatrix,
                            geneModulesCallback: () => {
                                setOpenGeneSignatureModuleDialog(true)
                            },
                            disableGeneModules: disableDifferentialExpressionMatrix,
                        },
                    }}
                />
                <GeneSignatureOverlapDialog
                    geneSignatureIds={[geneSignatureId, ...(selectionModel as number[])]}
                    raw={raw}
                    openDialog={openGeneSignatureOverlapDialog}
                    handleCloseDialog={() => {
                        setOpenGeneSignatureOverlapDialog(false)
                    }}
                />
                <GeneSignatureSimilarityMatrixDialog
                    geneSignatureIds={[geneSignatureId, ...(selectionModel as number[])]}
                    raw={raw}
                    openDialog={openGeneSignatureSimilarityMatrixDialog}
                    handleCloseDialog={() => {
                        setOpenGeneSignatureSimilarityMatrixDialog(false)
                    }}
                />
                <GeneSignatureModuleDialog
                    geneSignatureIds={[geneSignatureId, ...(selectionModel as number[])]}
                    openDialog={openGeneSignatureModuleDialog}
                    handleCloseDialog={() => {
                        setOpenGeneSignatureModuleDialog(false)
                    }}
                />
            </Box>
        </>
    )
}

interface ScoreDistributionPlotParams {
    geneSignatureSimilarityDtoList: GeneSignatureSimilarityDto[]
    metric: SimilarityMetricType
    raw: boolean
    width: number
    height: number
}

const ScoreDistributionPlot = ({
    geneSignatureSimilarityDtoList,
    metric,
    raw,
    width,
    height,
}: ScoreDistributionPlotParams) => {
    const plotData = useMemo(() => {
        if (!metric) {
            return [] as Data[]
        }
        const data = geneSignatureSimilarityDtoList
            .filter((gss) => {
                if (!raw) {
                    return gss.overlapCount >= 2
                }
                return true
            })
            .map((gss) => {
                if (metric == 'cosine' && raw) {
                    return gss.cosineSimilarityScoreRaw
                }
                if (metric == 'cosine' && !raw) {
                    return gss.cosineSimilarityScore
                }
                if (metric == 'pearson' && raw) {
                    return gss.pearsonCorrelationCoefRaw
                }
                if (metric == 'pearson' && !raw) {
                    return gss.pearsonCorrelationCoef
                }
            })
        return [
            {
                x: data,
                type: 'histogram',
                histnorm: '',
                name: 'Density',
                xbins: {
                    start: -1,
                    end: 1,
                    size: 0.01,
                },
            } as Data,
        ]
    }, [geneSignatureSimilarityDtoList, metric, raw])

    return (
        <Plot
            data={plotData}
            layout={{
                title: {
                    text: 'Similarity Score Distribution',
                    font: {
                        size: 14,
                    },
                },
                barmode: 'overlay',
                xaxis: { title: 'Similarity Score' },
                yaxis: { title: 'Count' },
                width: width,
                height: height,
                margin: {
                    t: 40,
                    r: 20,
                    b: 40,
                    l: 50,
                },
            }}
            config={{
                scrollZoom: false,
                displaylogo: false,
                displayModeBar: true,
                modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d'],
                toImageButtonOptions: {
                    format: 'svg',
                    filename: 'Similarity Histogram',
                    width: 1024,
                    height: 648,
                    scale: 2,
                },
            }}
        />
    )
}

interface TornadoPlotParams {
    geneSignatureSimilarityDtoList: GeneSignatureSimilarityDto[]
    metric: SimilarityMetricType
    raw: boolean
    width: number
    height: number
}

type TornadoPlotEntry = {
    name: string
    value: number
    rank: number
}

function TornadoPlot({ geneSignatureSimilarityDtoList, metric, raw, width, height }: TornadoPlotParams) {
    const prop = useMemo(() => {
        if (metric == 'pearson') {
            if (raw) {
                return 'pearsonCorrelationCoefRaw'
            } else {
                return 'pearsonCorrelationCoef'
            }
        } else {
            if (raw) {
                return 'cosineSimilarityScoreRaw'
            } else {
                return 'cosineSimilarityScore'
            }
        }
    }, [metric, raw])

    const plotData = useMemo(() => {
        const positiveGss = [] as GeneSignatureSimilarityDto[]
        const negativeGss = [] as GeneSignatureSimilarityDto[]
        for (let i = 0; i < geneSignatureSimilarityDtoList.length; i++) {
            if (geneSignatureSimilarityDtoList[i][prop] < 0) {
                negativeGss.push(geneSignatureSimilarityDtoList[i])
            } else {
                positiveGss.push(geneSignatureSimilarityDtoList[i])
            }
        }
        const positives = positiveGss
            .sort((gss1, gss2) => {
                return gss1[prop] < gss2[prop] ? 1 : -1
            })
            .map((gss, i) => {
                return {
                    name: gss['name'],
                    value: gss[prop],
                    rank: i,
                } as TornadoPlotEntry
            })
        const negatives = negativeGss
            .sort((gss1, gss2) => {
                return gss1[prop] < gss2[prop] ? -1 : 1
            })
            .map((gss, i) => {
                return {
                    name: gss['name'],
                    value: gss[prop],
                    rank: i,
                } as TornadoPlotEntry
            })
        return [
            {
                type: 'scattergl',
                mode: 'markers',
                x: positives.map((e) => e.rank),
                y: positives.map((e) => e.value),
                marker: {
                    size: 8,
                },
                text: positives.map((e) => e.name),
                name: 'Correlated',
            },
            {
                type: 'scattergl',
                mode: 'markers',
                x: negatives.map((e) => e.rank),
                y: negatives.map((e) => e.value),
                marker: {
                    size: 8,
                },
                text: negatives.map((e) => e.name),
                name: 'Anticorrelated',
            },
        ] as Data[]
    }, [geneSignatureSimilarityDtoList, metric, raw])

    return (
        <Plot
            data={plotData}
            layout={{
                title: {
                    font: {
                        size: 12,
                    },
                },
                width: width,
                height: height,
                xaxis: {
                    title: {
                        text: 'Rank',
                        font: {
                            size: 12,
                        },
                    },
                },
                yaxis: {
                    title: {
                        text: 'Score',
                        font: {
                            size: 12,
                        },
                    },
                },
                margin: {
                    t: 35,
                    r: 10,
                    b: 40,
                },
            }}
            config={{
                scrollZoom: false,
                displaylogo: false,
                modeBarButtonsToRemove: ['lasso2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
            }}
        />
    )
}

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { receivedEnableGuestAccessChange, receivedOrganizationDomains, selectOrganization } from '../../auth/authSlice'
import StartupLoading from '../../auth/StartupLoading'
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import CopyToClipboardButton from '../../../components/CopyToClipboardButton'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useSetDomainsMutation, useSetGuestAccessMutation } from './organizationApiSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import config from '../../../app/config'

export default function OrganizationSettings() {
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectOrganization)
    const [setGuestAccessApi] = useSetGuestAccessMutation()
    const [setDomainsApi, { isLoading: isSetDomainsLoading }] = useSetDomainsMutation()

    const [domains, setDomains] = useState<string[] | null>(null)
    const [domainsError, setDomainsError] = useState(false)

    useEffect(() => {
        if (!organization) {
            return
        }
        setDomains(organization.domains)
    }, [organization])

    const guestAccessURL = useMemo(() => {
        if (!organization) {
            return ''
        }
        return `https://${organization.code}-${organization.secret}.panomics.bio`
    }, [organization])

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!organization) {
            return
        }
        dispatch(receivedEnableGuestAccessChange({ enableGuestAccess: event.target.checked }))
        try {
            await setGuestAccessApi({
                organizationId: organization.id,
                enableGuestAccess: event.target.checked,
            }).unwrap()
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: `Successfully ${event.target.checked ? 'enabled' : 'disabled'} guest access`,
                }),
            )
        } catch (e) {
            dispatch(receivedEnableGuestAccessChange({ enableGuestAccess: !event.target.checked }))
            throw e
        }
    }

    const validateForm = () => {
        setDomainsError(false)
        let valid = true
        if (!domains || !domains.length) {
            setDomainsError(true)
            valid = false
        }
        return valid
    }

    const saveDomains = async () => {
        if (!validateForm()) {
            return
        }
        await setDomainsApi({
            domains: domains ?? [],
        }).unwrap()
        dispatch(
            receivedOrganizationDomains({
                domains: domains ?? [],
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The organization domains were successfully set.',
            }),
        )
    }

    return (
        <>
            {organization ? (
                <Box sx={{ p: 3, width: '100%' }}>
                    <Typography variant={'h6'} sx={{ mb: 2 }}>
                        Organization Settings
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {config.guestSystemEnabled && (
                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <FormControlLabel
                                control={<Switch checked={organization.enableGuestAccess} onChange={handleChange} />}
                                label='Enable guest access'
                                sx={{ mr: 6 }}
                            />
                            {import.meta.env.VITE_PLATFORM !== 'dnanexus' && (
                                <>
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontStyle: 'italic',
                                            color: organization.enableGuestAccess ? '#264653' : '#848484',
                                        }}
                                    >
                                        {guestAccessURL}
                                    </Typography>
                                    <CopyToClipboardButton
                                        getText={() => guestAccessURL}
                                        disabled={!organization.enableGuestAccess}
                                    />
                                </>
                            )}
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                        <Tooltip title={'Type and hit Enter to add a new domain in the list.'}>
                            <Autocomplete
                                sx={{ width: '500px', mr: 1 }}
                                options={[]}
                                freeSolo
                                multiple
                                includeInputInList
                                value={domains ?? []}
                                onChange={(_: unknown, newValue: string[]) => {
                                    setDomains(newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={'Domains'}
                                        type='text'
                                        fullWidth
                                        required
                                        error={domainsError}
                                    />
                                )}
                            />
                        </Tooltip>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                void saveDomains()
                            }}
                            disabled={isSetDomainsLoading}
                        >
                            {isSetDomainsLoading ? <CircularProgress size={20} /> : <>Save</>}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <StartupLoading />
            )}
        </>
    )
}

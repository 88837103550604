import { Action } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { BaseAnalysisResultData } from '../../../../../../model/analysisCommands'
import {
    receivedObsColumnMapping,
    receivedObsFields,
    receivedReloadObs,
    receivedUmapColors,
} from '../../../results/analysisResultsSlice'

export const reloadObsDataFrame = (
    analysisId: number,
    metadata: BaseAnalysisResultData | undefined,
    dispatch: Dispatch<Action>,
) => {
    dispatch(
        receivedReloadObs({
            analysisId: analysisId,
            reload: true,
        }),
    )
    dispatch(
        receivedUmapColors({
            analysisId: analysisId,
            umapColors: metadata?.umapColors,
        }),
    )
    dispatch(
        receivedObsColumnMapping({
            analysisId: analysisId,
            obsColumnMapping: metadata?.obsColumnMapping,
        }),
    )
    dispatch(
        receivedObsFields({
            analysisId: analysisId,
            obsFields: metadata?.obsFields,
        }),
    )
}

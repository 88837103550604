import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getStoredRegion } from '../hooks/useStoredRegion'
import { getBaseHttpURL } from '../utils/platform'
import { RootState } from './store'

const dynamicBaseQuery = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: { [key: string]: unknown },
) => {
    const region = getStoredRegion()
    const baseUrl = getBaseHttpURL(region)

    const updatedArgs = typeof args === 'string' ? { url: baseUrl + args } : { ...args, url: baseUrl + args.url }

    // Ensure `headers` is properly cast to HeadersInit
    const headers = updatedArgs.headers ? new Headers(updatedArgs.headers as HeadersInit) : new Headers()

    const token = (api.getState() as RootState).auth.token
    if (token) {
        headers.set('Authorization', `Bearer ${token}`)
    }
    updatedArgs.headers = headers

    return fetchBaseQuery({ baseUrl })(updatedArgs, api, extraOptions)
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: dynamicBaseQuery,
    tagTypes: ['Role', 'MetadataField'],
    endpoints: () => ({}),
})

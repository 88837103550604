import React, { useState } from 'react'
import { Link, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { Variant } from '@mui/material/styles/createTypography'

interface TruncatedTextProps {
    text: string
    maxLength?: number
    sx?: SxProps
    variant?: Variant
}

const ExpandableText: React.FC<TruncatedTextProps> = ({ text, maxLength = 100, variant = 'body2', sx }) => {
    const [isTruncated, setIsTruncated] = useState(true)

    const handleToggle = (event: React.MouseEvent) => {
        event.stopPropagation()
        setIsTruncated(!isTruncated)
    }

    const displayText = isTruncated && text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
    const linkText = isTruncated ? 'see more' : 'collapse'

    return (
        <Typography variant={variant} sx={sx}>
            {displayText}{' '}
            {text.length > maxLength && (
                <Link component='button' variant='body2' onClick={handleToggle}>
                    {linkText}
                </Link>
            )}
        </Typography>
    )
}

export default ExpandableText

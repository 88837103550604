import { Alert, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../app/hooks'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { DialogProps } from '../../../model/model'
import { useDeleteCollectionsMutation } from '../../common-api/collectionApiSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import { receivedDeletedCollections } from './adminCollectionSlice'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

interface DeleteMetadataFieldsParams extends DialogProps {
    selectionModel: GridRowSelectionModel
}

export default function DeleteCollectionsDialog(props: DeleteMetadataFieldsParams) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [deleteCollectionsApi, { isLoading: isDeleting }] = useDeleteCollectionsMutation()

    const submit = async () => {
        if (props.selectionModel.length == 0) {
            return
        }
        const resp = await deleteCollectionsApi(props.selectionModel as number[]).unwrap()
        dispatch(
            receivedDeletedCollections({
                collectionIdList: resp,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The selected collections were successfully deleted.',
            }),
        )
        if (props.onDone) {
            props.onDone()
        }
        close()
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(props.handleCloseDialog)

    return (
        <Dialog open={props.openDialog} onClose={close}>
            <DialogTitle>Delete collections</DialogTitle>
            <DialogContent>
                <Alert severity={'info'}>Only unused collections can be deleted.</Alert>
                <Typography sx={{ mt: 2 }}>Are you sure you want to delete the selected collections?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isDeleting ? (
                    <Button onClick={submit} autoFocus>
                        {t('delete')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

import { apiSlice } from '../../../app/apiSlice'
import { Command } from '../../../model/analysisCommands'
import { Component } from '../../../model/analysisComponents'
import { Analysis, CommandResult, ExecCommand, ExecCommandAudit, LightExecCommand } from '../../../model/model'

type PublishAnalysisRequest = {
    analysisIdList: number[]
    collectionId: number
}

type UnpublishAnalysisRequest = {
    analysisIdList: number[]
}

export const workbenchAnalysisApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAnalysis: builder.query<Analysis, number>({
            query: (analysisId) => ({
                url: '/private/analysis',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        submitCommand: builder.mutation<LightExecCommand, Command<Component>>({
            query: (data) => ({
                url: '/private/analysis/command',
                method: 'POST',
                body: data,
            }),
        }),
        cancelCommand: builder.mutation<void, number>({
            query: (id) => ({
                url: '/private/analysis/command/cancel',
                method: 'POST',
                params: {
                    commandId: id,
                },
            }),
        }),
        getCommand: builder.query<ExecCommand, number>({
            query: (commandId) => ({
                url: '/private/analysis/command',
                method: 'GET',
                params: {
                    commandId: commandId,
                },
            }),
        }),
        getCommandResults: builder.query<CommandResult, number>({
            query: (commandId) => ({
                url: '/private/analysis/command/results',
                method: 'GET',
                params: {
                    commandId: commandId,
                },
            }),
        }),
        getRunningComponents: builder.query<LightExecCommand[], number>({
            query: (analysisId) => ({
                url: '/private/analysis/command/running',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        listCommands: builder.query<ExecCommand[], number>({
            query: (analysisId) => ({
                url: '/private/analysis/command/list',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        publishAnalysis: builder.mutation<Analysis, PublishAnalysisRequest>({
            query: (data) => ({
                url: '/private/analysis/publish',
                method: 'POST',
                body: data,
            }),
        }),
        unpublishAnalysis: builder.mutation<Analysis, UnpublishAnalysisRequest>({
            query: (data) => ({
                url: '/private/analysis/unpublish',
                method: 'POST',
                body: data,
            }),
        }),
        listCommandAudits: builder.query<ExecCommandAudit[], number>({
            query: (analysisId) => ({
                url: '/private/analysis/command-audit/list',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getCommandAudit: builder.query<ExecCommandAudit, number>({
            query: (commandAuditId) => ({
                url: '/private/analysis/command-audit',
                method: 'GET',
                params: {
                    commandAuditId,
                },
            }),
        }),
        getCommandAuditByCommandId: builder.query<ExecCommandAudit, number>({
            query: (commandId) => ({
                url: '/private/analysis/command-audit/command',
                method: 'GET',
                params: {
                    commandId,
                },
            }),
        }),
    }),
})

export const {
    useGetAnalysisQuery,
    useSubmitCommandMutation,
    useListCommandsQuery,
    useLazyGetCommandResultsQuery,
    useLazyGetRunningComponentsQuery,
    usePublishAnalysisMutation,
    useCancelCommandMutation,
    useUnpublishAnalysisMutation,
    useListCommandAuditsQuery,
    useLazyGetCommandAuditQuery,
    useLazyGetCommandAuditByCommandIdQuery,
    endpoints: { submitCommand },
} = workbenchAnalysisApiSlice

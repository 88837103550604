import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'

function DnaIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d='M 13.671875 17.671875 L 10.328125 17.671875 C 9.992188 18.117188 9.695312 18.582031 9.441406 19.078125 L 14.558594 19.078125 C 14.304688 18.582031 14.007812 18.117188 13.671875 17.671875 Z M 13.671875 17.671875 ' />
            <path d='M 8.695312 21.1875 C 8.601562 21.628906 8.535156 22.097656 8.507812 22.59375 L 15.492188 22.59375 C 15.464844 22.097656 15.398438 21.628906 15.304688 21.1875 Z M 8.695312 21.1875 ' />
            <path d='M 10.328125 6.328125 L 13.671875 6.328125 C 14.007812 5.882812 14.304688 5.417969 14.558594 4.921875 L 9.441406 4.921875 C 9.695312 5.417969 9.992188 5.882812 10.328125 6.328125 Z M 10.328125 6.328125 ' />
            <path d='M 15.304688 2.8125 C 15.398438 2.371094 15.464844 1.902344 15.492188 1.40625 L 8.507812 1.40625 C 8.535156 1.902344 8.601562 2.371094 8.695312 2.8125 Z M 15.304688 2.8125 ' />
            <path d='M 10.972656 9.144531 C 8.742188 6.96875 7.078125 4.632812 7.078125 0.703125 L 7.078125 0 L 4.265625 0 L 4.265625 0.703125 C 4.265625 3.527344 4.992188 5.976562 6.480469 8.195312 C 7.1875 9.246094 8.011719 10.167969 8.878906 11.027344 C 9.292969 10.644531 9.710938 10.273438 10.128906 9.902344 C 10.414062 9.644531 10.695312 9.394531 10.972656 9.144531 Z M 10.972656 9.144531 ' />
            <path d='M 13.027344 14.855469 C 15.257812 17.03125 16.921875 19.367188 16.921875 23.296875 L 16.921875 24 L 19.734375 24 L 19.734375 23.296875 C 19.734375 20.472656 19.007812 18.023438 17.519531 15.804688 C 16.8125 14.753906 15.988281 13.832031 15.121094 12.972656 C 14.707031 13.355469 14.289062 13.726562 13.871094 14.097656 C 13.585938 14.355469 13.304688 14.605469 13.027344 14.855469 Z M 13.027344 14.855469 ' />
            <path d='M 17.519531 8.195312 C 19.007812 5.976562 19.734375 3.527344 19.734375 0.703125 L 19.734375 0 L 16.921875 0 L 16.921875 0.703125 C 16.921875 5.722656 14.207031 8.144531 11.0625 10.949219 C 9.441406 12.398438 7.765625 13.894531 6.480469 15.804688 C 4.992188 18.023438 4.265625 20.472656 4.265625 23.296875 L 4.265625 24 L 7.078125 24 L 7.078125 23.296875 C 7.078125 18.277344 9.792969 15.855469 12.9375 13.050781 C 14.558594 11.601562 16.234375 10.105469 17.519531 8.195312 Z M 17.519531 8.195312 ' />
        </SvgIcon>
    )
}

export default DnaIcon

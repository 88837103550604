import { apiSlice } from '../../app/apiSlice'

type GetResultUrlResponse = {
    url: string
}

type GetCoPerturbedGenesRequest = {
    targetGene: string
    geneSignatureIds: number[]
    pValThreshold: number
    minAbsLogFoldChange: number
    raw: boolean
}

export const asyncJobApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getResultUrl: builder.query<GetResultUrlResponse, string>({
            query: (name: string) => ({
                url: '/private/asyncjob/result-url',
                params: { name: name },
            }),
        }),
        getCoPerturbedGenes: builder.mutation<void, GetCoPerturbedGenesRequest>({
            query: (req) => ({
                url: `/private/asyncjob/coperturbation`,
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const { useLazyGetResultUrlQuery, useGetCoPerturbedGenesMutation } = asyncJobApiSlice

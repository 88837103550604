import NotificationContent, { NotificationParams } from './NotificationContent'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { GeneCoPerturbationResultNotificationProps } from '../../../model/model'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography } from '@mui/material'
import GeneCoPerturbationResultDialog from '../../omicsbrowser/coperturbation/GeneCoPerturbationResultDialog'

export default function GeneCoPerturbationResult({ notification, markAsRead }: NotificationParams) {
    const properties = useMemo((): GeneCoPerturbationResultNotificationProps => {
        return notification.properties as unknown as GeneCoPerturbationResultNotificationProps
    }, [notification])

    const [openGeneCoPerturbationResultDialog, setOpenGeneCoPerturbationResultDialog] = useState(false)
    const icon = useMemo(() => {
        if (properties.error) {
            return <ErrorOutlineIcon color={'error'} />
        }
        return <CheckIcon color={'primary'} />
    }, [properties])

    return (
        <>
            <NotificationContent
                notification={notification}
                onClick={() => {
                    if (!notification.read) {
                        markAsRead(notification.id)
                    }
                    if (!properties.error) {
                        setOpenGeneCoPerturbationResultDialog(true)
                    }
                }}
                icon={icon}
                title={
                    <>
                        {properties.error ? (
                            <>Gene Co-Perturbation Computation Failed</>
                        ) : (
                            <>Gene Co-Perturbation Computation Done</>
                        )}
                    </>
                }
            >
                {properties.error ? (
                    <Box>
                        <Typography variant={'body2'}>
                            An error occurred while determining co-perturbed genes for <b>{properties.targetGene}</b>.
                            Please try again or contact support if the issue persists.
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant={'body2'}>
                            The co-perturbed genes for <b>{properties.targetGene}</b> have been successfully determined.
                            Click to view the report.
                        </Typography>
                    </Box>
                )}
            </NotificationContent>
            <GeneCoPerturbationResultDialog
                openDialog={openGeneCoPerturbationResultDialog}
                handleCloseDialog={() => {
                    setOpenGeneCoPerturbationResultDialog(false)
                }}
                result={properties}
            />
        </>
    )
}

import { useCallback, useState } from 'react'
import { RuntimeType, RuntimeUserData } from '../../../model/model'

export type StoredRuntimeUserDataHook = {
    runtimeUserData: RuntimeUserData
    loadRuntimeUserData: (type: RuntimeType) => void
    setRuntimeUserData: (type: RuntimeType, data: RuntimeUserData) => void
    clearRuntimeUserData: (type: RuntimeType) => void
}

export default function useStoredRuntimeUserData(): StoredRuntimeUserDataHook {
    const [userData, setUserData] = useState<RuntimeUserData>({})

    const getRuntimeUserData = useCallback((type: RuntimeType) => {
        const storageKey = `${type}_runtime_user_data`
        const storedUserData = localStorage.getItem(storageKey)
        if (storedUserData === null) {
            return undefined
        }

        try {
            return JSON.parse(storedUserData)
        } catch {
            localStorage.setItem(storageKey, '')
            return undefined
        }
    }, [])

    const loadRuntimeUserData = useCallback((type: RuntimeType) => {
        setUserData(getRuntimeUserData(type))
    }, [])

    const setRuntimeUserData = useCallback((type: RuntimeType, data: RuntimeUserData) => {
        localStorage.setItem(`${type}_runtime_user_data`, data ? JSON.stringify(data) : '')
        setUserData(data)
    }, [])

    const clearRuntimeUserData = useCallback((type: RuntimeType) => setRuntimeUserData(type, {}), [])

    return {
        runtimeUserData: userData,
        loadRuntimeUserData,
        setRuntimeUserData,
        clearRuntimeUserData,
    }
}

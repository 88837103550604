import { apiSlice } from '../../app/apiSlice'
import { GetWorkbenchResponse } from '../../model/model'

export const workbenchApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getWorkbench: builder.query<GetWorkbenchResponse, number>({
            query: (projectId) => ({
                url: '/private/workbench',
                method: 'GET',
                params: {
                    projectId: projectId,
                },
            }),
        }),
    }),
})

export const { useGetWorkbenchQuery, useLazyGetWorkbenchQuery } = workbenchApiSlice

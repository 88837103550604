import { Box, CircularProgress, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import MultiAutocompleteWithCheckboxes from '../../../components/MultiAutocompleteWithCheckboxes'
import '../../../extensions/number.ext'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { CollectionWrapper, DialogProps, MetadataField, MetadataFieldTarget } from '../../../model/model'
import { useSaveCollectionMutation } from '../../common-api/collectionApiSlice'
import { useGetMetadataFieldsQuery } from '../../common-api/metadataFieldApiSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import {
    receivedNewCollection,
    receivedUpdatedCollection,
    selectAllCollections,
    selectCollectionById,
} from './adminCollectionSlice'

export interface CreateOrUpdateMetadataFieldParams extends DialogProps {
    collectionId?: number | null
}

export default function CreateOrUpdateCollectionDialog({
    openDialog,
    handleCloseDialog,
    collectionId,
}: CreateOrUpdateMetadataFieldParams) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [groupings, setGroupings] = useState<MetadataField[]>([])
    const [groupingsError, setGroupingsError] = useState(false)

    const { data: metadataFields } = useGetMetadataFieldsQuery(MetadataFieldTarget.Sample)

    const allCollections = useAppSelector(selectAllCollections)
    const collectionWrapper = useAppSelector((state) => selectCollectionById(state, collectionId ?? parseInt('')))
    const collection = collectionWrapper?.collection
    const [saveCollectionApi, { isLoading }] = useSaveCollectionMutation()

    useEffect(() => {
        if (openDialog) {
            resetFormValues()
        }
        if (!collection) {
            return
        }
        setName(collection.name)
        setGroupings(collection.groupings ?? [])
    }, [collection, openDialog])

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleGroupingsChange = (fields: MetadataField[]) => {
        setGroupings(fields)
    }

    const resetErrors = () => {
        setNameError(false)
        setGroupingsError(false)
    }

    const resetFormValues = () => {
        setName('')
        setGroupings([])
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!name) {
            setNameError(true)
            valid = false
        } else {
            const nonUniqueName = !!allCollections.find(
                (x) => x.collection.id !== collection?.id && x.collection.name === name,
            )
            if (nonUniqueName) {
                setNameError(true)
                valid = false
            }
        }
        return valid
    }

    const submit = async () => {
        if (!validateForm()) {
            return
        }
        if (collection && collectionId) {
            const resp = await saveCollectionApi({
                id: collectionId,
                name: name,
                groupings: groupings,
            }).unwrap()
            dispatch(
                receivedUpdatedCollection({
                    collection: {
                        collection: resp,
                        sampleCount: collectionWrapper.sampleCount,
                        sampleTypes: collectionWrapper.sampleTypes,
                    } as CollectionWrapper,
                }),
            )
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: 'The collection was successfully updated.',
                }),
            )
        } else {
            const resp = await saveCollectionApi({
                name: name,
                groupings: groupings,
            }).unwrap()
            dispatch(
                receivedNewCollection({
                    collection: resp,
                }),
            )
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: 'The collection was successfully created.',
                }),
            )
        }

        close()
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        resetErrors()
    })

    return (
        <Dialog open={openDialog} onClose={close} maxWidth={'md'}>
            <DialogTitle>{!collectionId ? 'Create a collection ' : 'Edit the collection '}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            slotProps={{
                                htmlInput: {
                                    autoComplete: 'off', // disable autocomplete and autofill
                                },
                            }}
                            required
                            fullWidth
                        />
                    </Box>
                    {metadataFields && (
                        <MultiAutocompleteWithCheckboxes
                            sx={{ mt: 2 }}
                            label='Select Groupings'
                            options={metadataFields}
                            onChange={handleGroupingsChange}
                            value={groupings}
                            error={groupingsError}
                            getOptionLabel={(x) => x.name}
                            required={true}
                            isOptionEqualToValue={(a, b) => a.id === b.id}
                            limitTags={4}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isLoading ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

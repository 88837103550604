import { Alert, Box, CircularProgress, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { GridRowId } from '@mui/x-data-grid'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { DialogProps, GeneList } from '../../model/model'
import { idCast } from '../../utils/misc'
import { useCreateOrUpdateGeneListMutation } from './geneListApiSlice'
import { selectGeneListById } from './geneListSlice'

function CreateOrUpdateGeneListDialog(
    props: DialogProps & {
        geneListId?: GridRowId
        externalGenes?: string[]
        callback?: () => void
        description?: string
    },
) {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [description, setDescription] = useState('')
    const [genes, setGenes] = useState<string>('')
    const [genesError, setGenesError] = useState(false)
    const [createOrUpdate, { isLoading }] = useCreateOrUpdateGeneListMutation()

    const geneList = useAppSelector<GeneList | undefined>((state) => {
        if (props.geneListId) {
            return selectGeneListById(state, idCast(props.geneListId))
        }
        return undefined
    })

    useEffect(() => {
        if (geneList) {
            setName(geneList.name)
            setDescription(geneList.description)
            setGenes(geneList.symbols.join(', '))
        } else {
            setName('')
            if (props.description) {
                setDescription(props.description)
            } else {
                setDescription('')
            }
            if (props.externalGenes) {
                setGenes(props.externalGenes.join(', '))
            } else {
                setGenes('')
            }
        }
    }, [geneList, props.externalGenes, props.description])

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!name) {
            setNameError(true)
            valid = false
        }
        if (!genes) {
            setGenesError(true)
            valid = false
        }
        return valid
    }

    const resetErrors = () => {
        setNameError(false)
        setGenesError(false)
    }

    const submit = async () => {
        if (!validateForm()) {
            return
        }

        const parsedGeneList = genes
            .split(/[,\n]/)
            .map((g) => {
                return g.trim()
            })
            .filter((g) => {
                return g != '' && g != ','
            })

        await createOrUpdate({
            id: geneList?.id,
            name: name,
            description: description,
            genes: parsedGeneList,
        }).unwrap()

        if (props.callback) {
            props.callback()
        }
        close()
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(() => {
        props.handleCloseDialog()
        resetErrors()
    })

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    const handleGenesChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setGenes(event.target.value)
    }

    return (
        <Dialog open={props.openDialog} onClose={close} maxWidth={'sm'}>
            <DialogTitle>{props.geneListId ? t('editGeneList') : t('createGeneList')}</DialogTitle>
            <DialogContent sx={{ width: '600px' }}>
                <Alert severity={'info'}>
                    Gene lists are public across the organization and can be used by anyone.
                </Alert>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            required
                            fullWidth
                        />
                        <TextField
                            onChange={handleDescriptionChange}
                            value={description}
                            multiline
                            rows={3}
                            label={t('description')}
                            size='small'
                            fullWidth
                        />
                        <TextField
                            onChange={handleGenesChange}
                            value={genes}
                            multiline
                            rows={5}
                            label={t('genes')}
                            size='small'
                            error={genesError}
                            fullWidth
                            helperText={'Genes can be separated by commas or new lines. Input approved symbols.'}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isLoading ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default CreateOrUpdateGeneListDialog

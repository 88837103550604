import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { PermissionSet } from '../../model/model'
import StartupLoading from './StartupLoading'
import {
    selectAcceptedEULA,
    selectIsAuthenticated,
    selectIsAuthenticating,
    selectUserId,
    useUserPermitted,
} from './authSlice'

type props = {
    requiredPermissions?: Partial<PermissionSet>
}

function RestrictedRoute({ requiredPermissions }: props) {
    const location = useLocation()
    const isAuthenticated = useAppSelector(selectIsAuthenticated)
    const isAuthenticating = useAppSelector(selectIsAuthenticating)

    const userId = useAppSelector(selectUserId)
    const userPermitted = useUserPermitted(requiredPermissions)
    const eulaAccepted = useAppSelector(selectAcceptedEULA)

    const userLoaded = !!userId

    if (isAuthenticating) {
        return <StartupLoading />
    }

    if (!isAuthenticated) {
        return <Navigate to='/' replace state={{ from: location }} />
    }

    // If the user info is loaded, but the EULA has not been accepted, redirect to the EULA page.
    if (userLoaded && !eulaAccepted && location.pathname !== '/eula') {
        return <Navigate to='/eula' replace />
    }

    // If this is a restricted route, check if the user has the required roles.
    if (!userPermitted) {
        return <Navigate to='/error/forbidden' replace />
    }

    return <Outlet />
}

export default RestrictedRoute

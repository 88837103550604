declare global {
    interface Number {
        formatBytes(): string
    }
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

Number.prototype.formatBytes = function (this: number) {
    let l = 0,
        n = parseInt(String(this), 10) || 0
    while (n >= 1024 && ++l) {
        n = n / 1024
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
}

export {}

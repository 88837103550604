import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { Ontology } from '../../../model/model'

const ontologies = createEntityAdapter<Ontology, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type OntologyListState = {
    ontologies: EntityState<Ontology, number>
}

const initialState = {
    ontologies: ontologies.getInitialState(),
} as OntologyListState

const ontologyListSlice = createSlice({
    name: 'ontologyListHolder',
    initialState: initialState,
    reducers: {
        receivedOntologiesList: (state, { payload: { ontologyList } }: PayloadAction<{ ontologyList: Ontology[] }>) => {
            ontologies.setAll(state.ontologies, ontologyList)
        },
        receivedNewOntology: (state, { payload: { ontology } }: PayloadAction<{ ontology: Ontology }>) => {
            ontologies.setOne(state.ontologies, ontology)
        },
        receivedUpdatedOntologyDescription: (
            state,
            { payload: { ontologyId, description } }: PayloadAction<{ ontologyId: number; description: string }>,
        ) => {
            ontologies.updateOne(state.ontologies, {
                id: ontologyId,
                changes: {
                    description: description,
                },
            })
        },
        receivedDeletedOntologies: (
            state,
            { payload: { ontologyIdList } }: PayloadAction<{ ontologyIdList: number[] }>,
        ) => {
            ontologies.removeMany(state.ontologies, ontologyIdList)
        },
    },
})

export const {
    receivedOntologiesList,
    receivedNewOntology,
    receivedUpdatedOntologyDescription,
    receivedDeletedOntologies,
} = ontologyListSlice.actions

export const {
    selectAll: selectAllOntologies,
    selectById: selectOntologyById,
    selectIds: selectOntologyIds,
    selectTotal: selectTotalOntologies,
    selectEntities: selectOntologyEntities,
} = ontologies.getSelectors<RootState>((state) => state.ontologyListHolder.ontologies)

export const selectOntologyRows = createSelector([selectAllOntologies], (ontologies) => {
    return ontologies.map((ontology: Ontology) => {
        return {
            id: ontology.id,
            name: ontology.name,
            description: ontology.description,
            fields: ontology.metadataFields ? ontology.metadataFields.map((mf) => mf.name).join(', ') : '',
            standard: ontology.standard ? 'Yes' : 'No',
            createdBy: `${ontology.user.firstName} ${ontology.user.lastName}`,
            createdAt: ontology.createdAt.parseAndFormatDate(),
            updatedAt: ontology.updatedAt.parseAndFormatDate(),
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export const selectOntologiesByIds = createSelector([selectAllOntologies, selectIds], (ontologies, ids) => {
    return ontologies.filter((ontology: Ontology) => {
        return ids.includes(ontology.id)
    })
})

export default ontologyListSlice.reducer

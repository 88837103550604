import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { SxProps, Theme } from '@mui/system'
import React from 'react'

interface MultiAutocompleteWithCheckboxesParams<T> {
    options: T[]
    getOptionLabel?: (option: T) => string
    renderOption?: (option: T) => React.ReactNode
    isOptionEqualToValue?: (option: T, value: T) => boolean
    size?: 'small' | 'medium'
    value: T[]
    onChange: (value: T[]) => void
    error?: boolean
    helperText?: string
    sx?: SxProps<Theme>
    limitTags?: number
    label: string
    required?: boolean
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export default function MultiAutocompleteWithCheckboxes<T>({
    options,
    getOptionLabel,
    renderOption,
    isOptionEqualToValue,
    size,
    value,
    onChange,
    error,
    helperText,
    sx,
    limitTags,
    label,
    required,
}: MultiAutocompleteWithCheckboxesParams<T>) {
    return (
        <Autocomplete
            multiple
            options={options}
            getOptionLabel={getOptionLabel}
            disableCloseOnSelect
            {...(isOptionEqualToValue ? { isOptionEqualToValue: isOptionEqualToValue } : {})}
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            renderOption={(props, option, { selected }) => {
                // eslint-disable-next-line react/prop-types
                const { key, ...rest } = props
                return (
                    <li key={key} {...rest}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ mr: 2 }} checked={selected} />
                        {renderOption
                            ? renderOption(option)
                            : getOptionLabel
                              ? getOptionLabel(option)
                              : (option as string)}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={label}
                    size={size}
                    error={error}
                    required={required}
                    helperText={helperText}
                />
            )}
            size={size ?? 'medium'}
            sx={sx}
            limitTags={limitTags ?? 2}
        />
    )
}

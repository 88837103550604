import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SampleMetadataUploadErrorsNotificationProps } from '../../../model/model'
import NotificationContent, { NotificationParams } from './NotificationContent'

export default function SampleMetadataUploadErrors({ notification, markAsRead }: NotificationParams) {
    const navigate = useNavigate()

    const properties = useMemo((): SampleMetadataUploadErrorsNotificationProps => {
        return notification.properties as unknown as SampleMetadataUploadErrorsNotificationProps
    }, [notification])

    const downloadErrorsAsFile = () => {
        const errorText = properties.errors.join('\n')
        const blob = new Blob([errorText], { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `sample_metadata_upload_errors_${properties.projectId}_${notification.createdAt.parseAndFormatDateWithoutTime().replaceAll('/', '-')}.txt`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }

    return (
        <NotificationContent
            notification={notification}
            onClick={() => {
                if (!notification.read) {
                    markAsRead(notification.id)
                }
                navigate(`/app/workbench/${properties.projectId}/samples`)
                downloadErrorsAsFile()
            }}
            icon={<ErrorOutlineIcon color={'error'} />}
            title={<>Error uploading sample metadata</>}
        >
            <Box>
                <Typography variant={'body2'}>
                    There were errors while uploading metadata for samples in project: {properties.projectId}
                </Typography>
                <ul>
                    {properties.errors.slice(0, 10).map((error, index) => (
                        <li key={index}>
                            <Typography variant={'body2'}>{error}</Typography>
                        </li>
                    ))}
                    {properties.errors.length > 10 && (
                        <li>
                            <Typography variant={'body2'}>...</Typography>
                        </li>
                    )}
                </ul>
            </Box>
        </NotificationContent>
    )
}

import {
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import { SelectChangeEvent } from '@mui/material/Select'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import usePersistState from '../../../hooks/usePersistState'
import { DashboardFilter, GeneListBase } from '../../../model/model'
import { useGetCollectionsQuery } from '../../common-api/collectionApiSlice'
import { MenuProps } from '../../common/MenuProps'
import GeneListSelector from '../../workbench/analysis/common/GeneListSelector'
import { useSetSignatureGenesMutation, useSetSignatureTabStateMutation } from '../dashboardApiSlice'
import {
    receivedNewDashboardSignatureGene,
    receivedSignatureTabState,
    selectSelectedDashboard,
} from '../dashboardSlice'
import SignatureTable from './SignatureTable'

type SignatureState = {
    initialised: boolean
    collections: string[]
    maxPValue: string
    logFcValue: string
    logFcOperator: string
}

export default function SignatureExplorer() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const dashboard = useAppSelector(selectSelectedDashboard)

    const { data: collectionList } = useGetCollectionsQuery()
    const [collectionMap, setCollectionMap] = useState<Map<string, string>>(new Map<string, string>())

    const [setSignatureGenesApi, { isLoading: isSetting }] = useSetSignatureGenesMutation()
    const [setSignatureStateApi] = useSetSignatureTabStateMutation()

    const [state, setState] = usePersistState<SignatureState>(
        {
            initialised: false,
            collections: [],
            maxPValue: '0.05',
            logFcValue: '',
            logFcOperator: '<',
        },
        dashboard?.signatureTabState as SignatureState | null,
        async (state) => {
            if (!dashboard) {
                return
            }

            await setSignatureStateApi({ dashboardId: dashboard.id, state: state }).unwrap()
            dispatch(receivedSignatureTabState({ dashboardId: dashboard.id, tabState: state }))
        },
    )

    useEffect(() => {
        if (!collectionList) {
            return
        }
        const newCollectionMap = new Map<string, string>()
        collectionList.forEach((c) => {
            newCollectionMap.set(c.id.toString(), c.name)
        })
        setCollectionMap(newCollectionMap)

        // Select all collections on first run.
        // This can be extended to a selection map, or unselected collection array.
        if (!state.initialised) {
            setState((state) => ({
                ...state,
                initialised: true,
                collections: collectionList.map((x) => x.id.toString()),
            }))
        }
    }, [collectionList])

    const handleCollectionsChange = (event: SelectChangeEvent<typeof state.collections>) => {
        const {
            target: { value },
        } = event
        setState((state) => ({
            ...state,
            // On autofill, we get a stringified value.
            collections: typeof value === 'string' ? value.split(',') : value,
        }))
    }

    const setSignatureGenes = async (genes: string[], geneList: GeneListBase | undefined) => {
        if (!dashboard) {
            return
        }
        await setSignatureGenesApi({
            dashboardId: dashboard.id,
            genes: genes,
            geneListId: geneList?.id,
        }).unwrap()

        dispatch(
            receivedNewDashboardSignatureGene({
                dashboardId: dashboard.id,
                genes: genes,
                geneList: geneList,
            }),
        )
    }

    const logFCFilter = useMemo(() => {
        if (state.logFcValue === '') {
            return undefined
        }

        return {
            field: 'logFC',
            operator: state.logFcOperator,
            value: state.logFcValue,
        } as DashboardFilter
    }, [state.logFcOperator, state.logFcValue])

    return collectionList && collectionMap ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Stack sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', gap: '10px', width: '100%', alignItems: 'center' }}>
                    <FormControl sx={{ background: '#FFF', width: '300px' }}>
                        <InputLabel>Collection(s)</InputLabel>
                        <Select
                            multiple
                            value={state.collections}
                            onChange={handleCollectionsChange}
                            input={<OutlinedInput label='Collection(s)' />}
                            renderValue={(selected) =>
                                selected
                                    .map((s) => {
                                        return collectionMap.get(s)
                                    })
                                    .join(', ')
                            }
                            MenuProps={MenuProps}
                            variant={'outlined'}
                        >
                            {collectionList?.map((c, idx) => (
                                <MenuItem key={`cw-${idx}`} value={c.id.toString()}>
                                    <Checkbox checked={state.collections.indexOf(c.id.toString()) > -1} />
                                    <ListItemText primary={c.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <GeneListSelector
                        sx={{ width: 'calc(100vw - 710px)' }}
                        genes={dashboard?.signatureGenes}
                        geneList={dashboard?.signatureGeneList}
                        labelManual={'Search for genes using the approved symbols'}
                        labelGenelist={'Search for a gene list'}
                        handleGenesAndListChange={setSignatureGenes}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <CircularProgress size={20} sx={{ display: isSetting ? 'block' : 'none' }} />
                                </InputAdornment>
                            ),
                            style: {
                                background: '#FFF',
                            },
                        }}
                    />
                    <TextField
                        sx={{ width: '100px' }}
                        variant='outlined'
                        type='number'
                        label={t('maxPValue')}
                        value={state.maxPValue}
                        required
                        onChange={(event) => {
                            if (!isNaN(Number(event.target.value))) {
                                setState((state) => ({
                                    ...state,
                                    maxPValue: event.target.value,
                                }))
                            }
                        }}
                        slotProps={{
                            htmlInput: {
                                step: 0.001,
                            },
                        }}
                    />
                    <Typography sx={{ ml: 1 }} alignContent='center'>
                        {t('logFoldChange')}
                    </Typography>
                    <Select
                        label={t('operator')}
                        value={state.logFcOperator}
                        onChange={(event) => setState((state) => ({ ...state, logFcOperator: event.target.value }))}
                        required
                        variant={'outlined'}
                    >
                        <MenuItem key={'field-0'} value='='>
                            =
                        </MenuItem>
                        <MenuItem key={'field-1'} value='!='>
                            !=
                        </MenuItem>
                        <MenuItem key={'field-2'} value='>'>
                            &gt;
                        </MenuItem>
                        <MenuItem key={'field-3'} value='>='>
                            &gt;=
                        </MenuItem>
                        <MenuItem key={'field-4'} value='<'>
                            &lt;
                        </MenuItem>
                        <MenuItem key={'field-5'} value='<='>
                            &lt;=
                        </MenuItem>
                    </Select>
                    <TextField
                        sx={{ width: '100px' }}
                        variant='outlined'
                        type='number'
                        value={state.logFcValue}
                        onChange={(event) => {
                            if (!isNaN(Number(event.target.value))) {
                                setState((state) => ({
                                    ...state,
                                    logFcValue: event.target.value,
                                }))
                            }
                        }}
                        slotProps={{
                            htmlInput: {
                                step: 0.001,
                            },
                        }}
                    />
                </Box>
                <SignatureTable
                    collections={state.collections}
                    maxPValue={Number(state.maxPValue)}
                    logFCFilter={logFCFilter}
                />
            </Stack>
        </Box>
    ) : (
        <></>
    )
}

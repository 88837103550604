import { apiSlice } from '../../../app/apiSlice'
import { DashboardFilter, LogicalOperator, MetadataField } from '../../../model/model'

type GetDashboardFilterFieldsResponse = {
    fields: MetadataField[]
}

type DashboardFilterNoId = DashboardFilter & { id: number | null }

type SaveDashboardFilterRequest = {
    dashboardId: number
    filter: DashboardFilterNoId
}

type SaveDashboardFilterResponse = {
    dashboardId: number
    filter: DashboardFilter
}

type UpdateDashboardLogicalOperatorRequest = {
    dashboardId: number
    logicalOperator: LogicalOperator
}

export type ClearAllDashboardFiltersRequest = {
    dashboardId: number
}

export type DeleteDashboardFilterRequest = {
    filterId: number
    dashboardId: number
}

export const dashboardFiltersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardFilterField: builder.query<GetDashboardFilterFieldsResponse, void>({
            query: () => '/private/dashboard/fields',
        }),
        saveDashboardFilter: builder.mutation<SaveDashboardFilterResponse, SaveDashboardFilterRequest>({
            query: (req) => ({
                url: '/private/dashboard/filter',
                method: 'POST',
                body: req,
            }),
        }),
        deleteDashboardFilter: builder.mutation<void, DeleteDashboardFilterRequest>({
            query: (req) => ({
                url: '/private/dashboard/delete-filter',
                method: 'POST',
                body: req,
            }),
        }),
        updateDashboardLogicalOperator: builder.mutation<void, UpdateDashboardLogicalOperatorRequest>({
            query: (req) => ({
                url: '/private/dashboard/logical-operator',
                method: 'POST',
                body: req,
            }),
        }),
        clearAllDashboardFilters: builder.mutation<void, ClearAllDashboardFiltersRequest>({
            query: (req) => ({
                url: '/private/dashboard/clear-filters',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useGetDashboardFilterFieldQuery,
    useSaveDashboardFilterMutation,
    useUpdateDashboardLogicalOperatorMutation,
    useClearAllDashboardFiltersMutation,
    useDeleteDashboardFilterMutation,
    endpoints: {
        getDashboardFilterField,
        saveDashboardFilter,
        updateDashboardLogicalOperator,
        clearAllDashboardFilters,
        deleteDashboardFilter,
    },
} = dashboardFiltersApiSlice

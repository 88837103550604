import { GridFilterModel } from '@mui/x-data-grid-premium'
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { DataSlice } from '../../model/model'

const dataSlices = createEntityAdapter<DataSlice, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type DataSlicesState = {
    dataSlices: EntityState<DataSlice, number>
}

const initialState = {
    dataSlices: dataSlices.getInitialState(),
} as DataSlicesState

const dataSliceListSlice = createSlice({
    name: 'geneSetListHolder',
    initialState: initialState,
    reducers: {
        receivedDataSliceList: (
            state,
            { payload: { dataSliceList } }: PayloadAction<{ dataSliceList: DataSlice[] }>,
        ) => {
            dataSlices.setAll(state.dataSlices, dataSliceList)
        },
        receivedNewDataSlice: (state, { payload: { dataSlice } }: PayloadAction<{ dataSlice: DataSlice }>) => {
            dataSlices.setOne(state.dataSlices, dataSlice)
        },
        receivedDeletedDataSlices: (
            state,
            { payload: { dataSliceIdList } }: PayloadAction<{ dataSliceIdList: number[] }>,
        ) => {
            dataSlices.removeMany(state.dataSlices, dataSliceIdList)
        },
    },
})

export const { receivedDataSliceList, receivedDeletedDataSlices, receivedNewDataSlice } = dataSliceListSlice.actions

export const {
    selectAll: selectAllDataSlices,
    selectById: selectDataSliceById,
    selectIds: selectDataSliceIds,
    selectTotal: selectTotalDataSlices,
    selectEntities: selectDataSliceEntities,
} = dataSlices.getSelectors<RootState>((state) => state.dataSliceListHolder.dataSlices)

const dataSliceFilterToString = (filter: GridFilterModel) => {
    const parts: string[] = []
    filter.items.map((i) => {
        parts.push(`${i.field} ${i.operator} ${i.value}`)
    })
    return parts.join(' | ')
}

export const selectDataSliceRows = createSelector([selectAllDataSlices], (dataSlices) => {
    return dataSlices.map((dataSlice: DataSlice) => {
        return {
            id: dataSlice.id,
            name: dataSlice.name,
            createdAt: dataSlice.createdAt.parseAndFormatDate(),
            filter: dataSliceFilterToString(dataSlice.filter),
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export default dataSliceListSlice.reducer
